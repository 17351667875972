import { Component } from "react";
import { getDOY, isLeapYear } from "../../../Utils/utils";
import "./ConferenceRow.scss";
import "./DateCell.scss";

import DeadlineIcon from "../../Icons/DeadlineIcon";
import StartConferenceIcon from "../../Icons/StartConferenceIcon";
import NotificationIcon from "../../Icons/NotificationIcon";
import { Row } from "./Types";
import { checkValidityOfDate } from "../Date/DateUtils";
import Tooltip from "../../Tooltip/Tooltip";

type Props = {
	row: Row;
	hoveredDate: undefined | Date;
	setHoveredDate: (date: Date | undefined) => void;
};

type State = {};

export default class DateCell extends Component<Props, State> {
	state = {};
	firstMonthDate = new Date();

	constructor(props: Props) {
		super(props);
		this.firstMonthDate.setDate(1);
	}

	dayOfTheMonth() {
		return new Date().getDate();
	}

	todayDate() {
		return new Date().toDateString();
	}

	getOffsetDate(date: Date) {
		let days: number = isLeapYear(this.firstMonthDate) || isLeapYear(date) ? 366 : 365;
		return (((getDOY(date) - getDOY(this.firstMonthDate)) % days) + days) % days;
	}

	renderDeadlineIcons() {
		let deadline_q = this.props.row.deadline?.split(";");
		if (!deadline_q) {
			return;
		}
		var li = [];
		for (let i = 0; i < deadline_q.length; i++) {
			let elem: string = deadline_q[i];
			li.push(this.renderDeadlineIcon(elem));
		}

		return li;
	}

	// deadline_par == this.props.row.deadline[#]
	renderDeadlineIcon(deadline_param: any) {
		var isValidDate: boolean = checkValidityOfDate(deadline_param);
		if (!isValidDate) {
			return;
		}
		var deadline = new Date(deadline_param!);
		return (
			<div
				className={
					this.greyOut(deadline_param) + "position-absolute py-2 offset-day-" + this.getOffsetDate(deadline)
				}
			>
				<Tooltip
					styleOuter={"left-12"}
					styleInner={""}
					content={
						<div>
							<h6>Deadline Date:</h6>
							<span>{deadline.toDateString()}</span>
						</div>
					}
				>
					<div
						onMouseOver={() => this.props.setHoveredDate(new Date(deadline_param!))}
						onMouseOut={() => this.props.setHoveredDate(undefined)}
					>
						<DeadlineIcon outOfDate={new Date(deadline_param!) < new Date()}></DeadlineIcon>
					</div>
				</Tooltip>
			</div>
		);
	}

	renderStartIcon() {

		var isValidDate: boolean = checkValidityOfDate(this.props.row.start);
		if (!isValidDate) {
			return;
		}
		var start = new Date(this.props.row.start!);

		return (
			<div
				className={
					this.greyOut(this.props.row.start!) +
					"position-absolute py-2 offset-day-" +
					this.getOffsetDate(start!)
				}
			>
				<Tooltip
					styleOuter={"left-12"}
					styleInner={""}
					content={
						<div>
							<h6>Start Date:</h6>
							<span>{start.toDateString()}</span>
						</div>
					}
				>
					<div
						onMouseOver={() => this.props.setHoveredDate(new Date(this.props.row.start!))}
						onMouseOut={() => this.props.setHoveredDate(undefined)}
					>
						<StartConferenceIcon
							outOfDate={new Date(this.props.row.start!) < new Date()}
						></StartConferenceIcon>
					</div>
				</Tooltip>
			</div>
		);
	}

	renderNotificationIcons() {
		let notification_q = this.props.row.notification?.split(";");
		if (!notification_q) {
			return;
		}
		var li = [];
		for (let i = 0; i < notification_q.length; i++) {
			let elem: string = notification_q[i];
			li.push(this.renderNotificationIcon(elem));
		}

		return li;
	}

	renderNotificationIcon(notification_param: any) {
		var isValidDate: boolean = checkValidityOfDate(notification_param);
		if (!isValidDate) {
			return;
		}
		var notification = new Date(notification_param!);

		return (
			<div
				className={
					this.greyOut(notification_param) +
					"position-absolute py-2 offset-day-" +
					this.getOffsetDate(notification!)
				}
			>
				<Tooltip
					styleOuter={"left-12"}
					styleInner={""}
					content={
						<div>
							<h6>Notification Date:</h6>
							<span>{notification.toDateString()}</span>
						</div>
					}
				>
					<div
						onMouseOver={() => this.props.setHoveredDate(new Date(notification_param!))}
						onMouseOut={() => this.props.setHoveredDate(undefined)}
					>
						<NotificationIcon outOfDate={new Date(notification_param!) < new Date()}></NotificationIcon>
					</div>
				</Tooltip>
			</div>
		);
	}

	greyOut(date_str: string) {
		if (
			this.props.hoveredDate &&
			checkValidityOfDate(date_str) &&
			new Date(date_str) < new Date(this.props.hoveredDate)
		) {
			return "grey-out ";
		} else {
			return "";
		}
	}

	render() {
		return (
			<div className="w-100 h-100" key={"date-cell-" + this.props.row.id_pk}>
				{/*  */}
				<div className="position-absolute w-100 h-100">
					{/* Render the following by increasing DOY. --> To prevent issues with hover-box */}
					{this.renderDeadlineIcons()}
					{this.renderStartIcon()}
					{this.renderNotificationIcons()}

					{/*  */}

					{/* Today marker */}
					<Tooltip
						styleOuter={"current-time-marker position-absolute offset-day-" + this.dayOfTheMonth()}
						content={
							<div>
								<h6>Today</h6>
								<span>{this.todayDate()}</span>
							</div>
						}
					>
						<div className={"current-time-marker"}></div>
					</Tooltip>
				</div>
			</div>
		);
	}
}
