import { Component, ReactElement } from "react";
import { arrayIsEmpty, getDOY, updateURLParameter } from "../../Utils/utils";
import ConferenceRow from "./ConferenceRow/ConferenceRow";
import { Row } from "./ConferenceRow/Types";
import { checkValidityOfDate } from "./Date/DateUtils";
import Month from "./Date/Month";
import "../Buttons/Button.scss";
import "./Legend.scss";
import "./ConferenceRow/ConferenceRow.scss";
import { Navigate } from "react-router";

type Props = {
	rows: Row[];
	sortby: any;
};

type State = {
	rows: Row[];
	sorted_by: number;
	is_sorted: boolean;
	legendEnabled: boolean;
	hoveredDate: undefined | Date;
	hidden: undefined | ReactElement;
};

function resolve_sortby(sortby: null | string): number {
	if (!sortby) {
		return 0;
	}
	if (sortby === "1" || sortby?.toLowerCase() === "rank") {
		return 1;
	} else if (sortby === "2" || sortby?.toLowerCase() === "start") {
		return 2;
	} else if (sortby === "3" || sortby?.toLowerCase() === "deadline") {
		return 3;
	} else if (sortby === "4" || sortby?.toLowerCase() === "notification") {
		return 4;
	}
	return 0;
}

export default class ConferenceTable extends Component<Props, State> {
	// state: State = {
	//   rows: [],
	//   sorted_by: 0,
	//   is_sorted: true,
	//   legendEnabled: false,
	// };
	sorted_by_options: { [k: string]: number } = {
		match: 0,
		rank: 1,
		start: 2,
		deadline: 3,
		notification: 4,
	};
	months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	next_months: string[];
	nr_selected: number = 0;

	constructor(props: Props) {
		super(props);
		const today: Date = new Date();
		this.next_months = ConferenceTable.reindexArray(today.getMonth(), this.months);
		this.state = {
			rows: this.props.rows.map((x) => x),
			sorted_by: resolve_sortby(this.props.sortby),
			is_sorted: false,
			legendEnabled: false,
			hoveredDate: undefined,
			hidden: undefined,
		};
		this.nr_selected = 0;
	}

	static reindexArray(i: number, array: any[]) {
		var temp_array: any[] = [];
		for (let j = i; j < array.length + i; j++) {
			temp_array.push(array[j % array.length]);
		}
		return temp_array;
	}

	static orderDates(
		dateA: string | undefined,
		dateB: string | undefined,
		query_weightA: string,
		query_weightB: string
	) {
		let isValidDateA: boolean = checkValidityOfDate(dateA);
		let isValidDateB: boolean = checkValidityOfDate(dateB);
		if (!isValidDateA) {
			if (isValidDateB) {
				return -1;
			} else if (!isValidDateB) {
				return parseFloat(query_weightA) - parseFloat(query_weightB);
			}
		} else if (!isValidDateB) {
			return 1;
		}
		let valueA: number = (((getDOY(new Date(dateA!)) - getDOY(new Date())) % 366) + 366) % 366;
		let valueB: number = (((getDOY(new Date(dateB!)) - getDOY(new Date())) % 366) + 366) % 366;
		return valueA - valueB === 0 ? parseFloat(query_weightA) - parseFloat(query_weightB) : -(valueA - valueB);
	}

	static getMinimumDate(dates: string[] | undefined) {
		if (dates === undefined || arrayIsEmpty(dates)) {
			return undefined;
		} else {
			let lowest_date = dates[0];
			for (var i = 0; i < dates.length; i++) {
				if (!checkValidityOfDate(lowest_date)) {
					lowest_date = dates[i];
				} else if (checkValidityOfDate(dates[i])) {
					let valueA: number = (((getDOY(new Date(dates[i]!)) - getDOY(new Date())) % 366) + 366) % 366;
					let valueB: number = (((getDOY(new Date(lowest_date!)) - getDOY(new Date())) % 366) + 366) % 366;
					if (valueA < valueB) {
						lowest_date = dates[i];
					}
				}
			}
			return lowest_date;
		}
	}

	/**
	 *
	 * outputs high values if a should appear before b.
	 * if the sorted by value is ambiguous, e.g. the outputted value would correspond to 0, then we order according to match.
	 */
	static sortHelper(sort_by: number, rowA: Row, rowB: Row): number {
		if (sort_by === 0) {
			// match
			return parseFloat(rowA.query_weight!) - parseFloat(rowB.query_weight!);
		} else if (sort_by === 1) {
			// rank
			let ranks: string[] = ["A*", "A", "B", "C"];

			// handle undefined
			if (rowA.rank === undefined) {
				if (rowB.rank !== undefined) {
					return -1;
				} else if (rowB.rank === undefined) {
					return parseFloat(rowA.query_weight!) - parseFloat(rowB.query_weight!);
				}
			} else if (rowB.rank === undefined) {
				return 1;
			}
			//
			let a: number = ranks.indexOf(rowA.rank!) === -1 ? 4 : ranks.indexOf(rowA.rank!); //  lowest is best
			let b: number = ranks.indexOf(rowB.rank!) === -1 ? 4 : ranks.indexOf(rowB.rank!);
			return -a - -b === 0 ? -parseFloat(rowA.query_weight!) - parseFloat(rowB.query_weight!) : -a - -b;
		} else if (sort_by === 2) {
			return ConferenceTable.orderDates(rowA.start, rowB.start, rowA.query_weight!, rowB.query_weight!);
		} else if (sort_by === 3) {
			// deadline A < B means A should appear after B
			return ConferenceTable.orderDates(
				ConferenceTable.getMinimumDate(rowA.deadline?.split(";")),
				ConferenceTable.getMinimumDate(rowB.deadline?.split(";")),
				rowA.query_weight!,
				rowB.query_weight!
			);
		} else if (sort_by === 4) {
			// notification
			return ConferenceTable.orderDates(
				ConferenceTable.getMinimumDate(rowA.notification?.split(";")),
				ConferenceTable.getMinimumDate(rowB.notification?.split(";")),
				rowA.query_weight!,
				rowB.query_weight!
			);
		}
		return 1;
	}

	onClickSort(sort_by: string) {
		window.history.pushState({}, "", updateURLParameter(window.location.href, "sortby", sort_by));
		this.setState({ is_sorted: false, sorted_by: this.sorted_by_options[sort_by] }, () => this.sort());
	}

	sort(): void {
		console.log("Sort");
		if (this.state.rows.length === 0 || this.state.is_sorted) {
			return;
		} else {
			var copy_rows: Row[] = this.state.rows.map((x) => x);
			this.setState({
				rows: copy_rows.sort((x, y) => ConferenceTable.sortHelper(this.state.sorted_by, x, y)).reverse(),
				is_sorted: true,
			});
		}
	}

	setSelected(row: Row, v: boolean) {
		row.selected = v;
		if (v !== row.selected && v) {
			this.nr_selected++;
		} else if (v !== row.selected && !v) {
			this.nr_selected--;
		}
	}

	getResponse(): Row[] {
		return this.state.rows;
	}

	renderMonths() {
		var rows = [];
		for (var i = 0; i < 12; i++) {
			// note: we are adding a key prop here to allow react to uniquely identify each
			// element in this array. see: https://reactjs.org/docs/lists-and-keys.html
			rows.push(<Month month={this.next_months[i]} key={"month-" + i} />);
		}
		return rows;
	}

	setHoveredDate(date: undefined | Date) {
		this.setState({ hoveredDate: date });
	}

	renderConferenceRows(): JSX.Element[] {
		var rows: JSX.Element[] = [];

		const response: Row[] = this.getResponse();

		for (let index = 0; index < response.length; index++) {
			const response_element: Row = response[index];
			const setSel = (x: boolean) => this.setSelected.bind(this)(response_element, x);
			const row_el: JSX.Element = (
				<ConferenceRow
					hoveredDate={this.state.hoveredDate}
					setHoveredDate={this.setHoveredDate.bind(this)}
					key={response_element.id_pk}
					row={response_element}
					setSelected={setSel}
				></ConferenceRow>
			);
			rows.push(row_el);
		}
		return rows;
	}
	/**
	 * If the legend should be rendered : it's rendered as two columns.
	 * --> This means two columns are rendered within the first columns
	 * of the outer structure in the render method.
	 *
	 * @return ReactElement | void
	 * @memberof ConferenceTable
	 */
	renderLegend(): ReactElement | void {
		if (!this.state.legendEnabled) {
			return;
		}
		return (
			// wrapper div
			<div className="">
				{/* render on row with title = Legend */}
				<div className="row p-0">
					<div className="col-md-4 m-0 p-1">
						<h5 className="text-left">Legend</h5>
					</div>
					<div className="col-md-8 text-left"></div>
				</div>

				{/* First Icon */}
				<div className="row p-1">
					<div className="deadline-icon-legend col-2 "></div>
					<div className="col-10 text-left">
						<p className="">Deadline of the conference</p>
					</div>
				</div>
				{/* Second Icon */}
				<div className="row p-1">
					<div className="start-conference-icon-legend col-2"></div>
					<div className="col-10 text-left">
						<p>Start of the conference</p>
					</div>
				</div>
				{/* Third Icon */}
				<div className="row p-1">
					<div className="notification-icon-legend col-2 "></div>
					<div className="col-10 text-left">
						<p>Notification of the conference</p>
					</div>
				</div>
				{/* 4th Icon */}
				<div className="row p-1">
					<div className="col-2 deadline-icon-legend-red"></div>
					<div className="col-10 text-left">
						<p>A red icon means that the date of the event lies in the past.</p>
					</div>
				</div>
				<div className="row p-1">
					<div className="col-2 deadline-icon-legend-grey"></div>
					<div className="col-10 text-left">
						<p>
							A greyed-out icon means that the event represented by the greyed-out icon took place before
							the event you are currently hovering.
						</p>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * generate a dropdown Button to sort.
	 * @returns ReactElement
	 */
	renderSortedButton(): ReactElement {
		return (
			<div className="my-3 mr-3 dropdown float-left" key={"sort-dropdown"}>
				<button
					className="btn btn-sm btn-secondary dropdown-toggle btn-semi-dark w-100"
					type="button"
					id="dropdownMenuButton"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					Sort by
				</button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
					<div className="dropdown-item" onClick={this.onClickSort.bind(this, "deadline")}>
						Deadline of the conference
					</div>
					<div className="dropdown-item" onClick={this.onClickSort.bind(this, "start")}>
						Start of the conference
					</div>
					<div className="dropdown-item" onClick={this.onClickSort.bind(this, "match")}>
						Match
					</div>
					<div className="dropdown-item" onClick={this.onClickSort.bind(this, "notification")}>
						Notification of the conference
					</div>
					<div className="dropdown-item" onClick={this.onClickSort.bind(this, "rank")}>
						CORE Ranking
					</div>
				</div>
			</div>
		);
	}

	toggleLegend() {
		this.setState({ legendEnabled: !this.state.legendEnabled });
	}

	toggleLegendButton() {
		return (
			<div className="my-3 mr-3 float-left" key={"toggle-legend-button"}>
				<button
					className="btn btn-sm btn-secondary btn-semi-dark w-100"
					onClick={this.toggleLegend.bind(this)}
					type="button"
					id="toggle-legend-button"
				>
					Legend
				</button>
			</div>
		);
	}

	keepSelected() {
		let rows: Row[] = [];
		for (let i = 0; i < this.state.rows.length; i++) {
			if (this.state.rows[i].selected) {
				rows.push(this.state.rows[i]);
			}
		}
		let query: string = "";
		for (let i = 0; i < rows.length; i++) {
			query = query.concat((rows[i] as Row).id_acro! + (i < rows.length - 1 ? "+" : ""));
		}
		this.setState({ hidden: <Navigate to={"/?query=" + query} /> });
	}

	renderKeepSelected() {
		return (
			<div className="my-3 mr-3 float-left" key={"keep-selected-button"}>
				<button
					className="btn btn-sm btn-secondary btn-semi-dark w-100"
					onClick={this.keepSelected.bind(this)}
					type="button"
					id="keep-selected-button"
				>
					Keep Selected
				</button>
			</div>
		);
	}

	componentDidMount() {
		if (this.state.hidden !== undefined) {
			this.setState({ hidden: undefined });
		}
		if (this.props.rows.length !== this.state.rows.length) {
			this.setState({ rows: this.props.rows });
		}
		if (!this.state.is_sorted) {
			this.sort();
		}
	}

	componentDidUpdate() {
		this.componentDidMount();
	}

	render() {
		return (
			<div className="p-4">
				{/* Legend and similar */}
				{/* First column */}
				<div className="col-md-4 ">
					{this.renderLegend()}
					<div className="row">
						<div className="d-flex flex-row align-items-end" key={"first-column"}>
							{/* Sorted Button */}
							{this.renderSortedButton()}
							{this.toggleLegendButton()}
							{this.renderKeepSelected()}
						</div>
					</div>
				</div>
				{/* Second column */}
				<div className="col-md-4" key={"legend"}></div>
				{/* Third column */}
				<div className="col-md-4" key={"third-column"}></div>

				<table className="d-table table border table-hover d-table">
					<thead>
						<tr>
							<th className="text-left align-middle max-width-4em" scope="col">
								Select
							</th>
							<th className="text-left align-middle px-4 text-nowrap" scope="col">
								Edit / Details
							</th>
							<th className="text-left align-middle max-width-5em" scope="col">
								Acronym
							</th>
							<th className="text-left align-middle min-width-15em" scope="col">
								Conference Name
							</th>
							<th className="text-left align-middle" scope="col">
								Web
							</th>
							<th className="text-left align-middle min-width-6em" scope="col">
								<div className="">
									<abbr title="CORE Ranking">Rank</abbr>
								</div>
							</th>
							{/* <th className='align-middle' scope="col">match</th> */}
							<th className="text-left align-middle" scope="col">
								Location
							</th>
							<th className="text-left align-middle p-1 dates" scope="col">
								<div className="d-flex justify-content-around text-center">{this.renderMonths()}</div>
							</th>
						</tr>
					</thead>
					<tbody>{this.renderConferenceRows()}</tbody>
				</table>
				{this.state.hidden}
			</div>
		);
	}
}
