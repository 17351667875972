import { Component } from "react";
import "./TipOfTheDay.scss";

type Props = {};

type State = { ctr: number };

const tip1 = (
	<div>
		<div className="h5">Tip : Keyword Search</div>
		<p>
			You can search for keywords. You could for example search for your paper title. The results will consist of
			the most relevant conferences for these keywords. This tip corresponds to the 1st search suggestion.
		</p>
	</div>
);

const tip2 = (
	<div>
		<div className="h5">Tip : Author Search</div>
		<p>
			You can search for authors, which results in an author search request. The results will be based on where
			the author has published recently. The names of the authors are based on
			<a className="alert-link" href="https://dblp.org/">
				{" "}
				dblp
			</a>
			. This tip corresponds to the 2nd search suggestion.
		</p>
	</div>
);

const tip3 = (
	<div>
		<div className="h5">Tip : Similar Conferences Search</div>
		<p>
			By searching for a <em>single Acronym Key</em> or an <em>exact Conference Name</em>, you can search for
			similar conferences. This tip corresponds to the 3rd search suggestion.
		</p>
		<p>
			Note that searching for multiple <em>Acronym Key</em>s will generate a static list of conferences
		</p>
	</div>
);

const tip4 = (
	<div>
		<div className="h5">Tip : Bookmarking</div>
		<p>
			By looking into the details page of a conference you can find a field <em>Acronym Key</em>. You can use this
			key in order to generate a list of conferences. Simply enter multiple such keys into the search bar,
			separated by a single space ' ', then copy / store the url for later. This tip corresponds to the 4th search
			suggestion.
		</p>
	</div>
);

const tip5 = (
	<div>
		<div className="h5">Tip : Calendar Icon</div>
		<p>By clicking on the calendar item you can download the relevant dates for the corresponding conference.</p>
	</div>
);

const tip6 = (
	<div>
		<div className="h5">Tip : Contact</div>
		<p>
			If you should run into any trouble. Do not hesitate to reach out to us by{" "}
			<a className="alert-link" href="mailto:alexthillen852@gmail.com">
				mail
			</a>
			.
		</p>
	</div>
);

const tip7 = (
	<div>
		<div className="h5">Tip : Hover</div>
		<p>By hovering over objects on the page you can often get more information. Try it!</p>
	</div>
);

const tip8 = (
	<div>
		<div className="h5">About</div>
		<p>
			If you want to learn more about who has created this webpage, then please{" "}
			<a className="alert-link" href="/about">
				click here
			</a>
			.
		</p>
		<p>
			<b>Click on the next button below to get some tips!</b>
		</p>
	</div>
);

const tips: any = [tip8, tip1, tip2, tip3, tip4, tip5, tip6, tip7];

export default class TipOfTheDay extends Component<Props, State> {
	state = { ctr: 0 };

	render() {
		return (
			<div className={"my-5 alert alert-dark fade show carousel slide"} role="alert">
				<div className="offset-md-2 col-md-8 mt-2">
					{tips[this.state.ctr]}
					<button
						className="btn opacity-0"
						onClick={(e) => this.setState({ ctr: (this.state.ctr + 1) % tips.length })}
					>
						<span className="carousel-control-next-icon blue outline" aria-hidden="true"></span>
					</button>
				</div>
			</div>
		);
	}
}
