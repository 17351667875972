export const example = `A generalization of multiple choice balls-into-bins.
 Sub-linear Distributed Algorithms for Sparse Certificates and Biconnected Components (Extended Abstract). 
 Adaptive AIMD congestion control. 
 Secure Distributed Computing Made (Nearly) Optimal. 
 Lazy Replication: Exploiting the Semantics of Distributed Services. 
 2021 Principles of Distributed Computing Doctoral Dissertation Award. 
 Secure communication over radio channels. 
 Practical summation via gossip. 
 Failure detectors are schedulers. 
 Improving Fast Mutual Exclusion. 
 FruitChains: A Fair Blockchain. 
 Session details: Session 1C: Wireless Networks. 
 Formal Verification of Timed Properties for Randomized Distributed Algorithms. 
 Brief announcement: locality-based aggregate computation in wireless sensor networks. 
 Distributed and Parallel Computing Issues in Data Warehousing (Abstract). 
 A Sequencing Service for Group Communication (Abstract). 
 Efficiency of Oblivious Versus Non-Oblivious Schedules for Optimistic, Rate-Based Flow Control (Extended Abstract). 
 Stochastic Coordination in Heterogeneous Load Balancing Systems. 
 How to withstand mobile virus attacks, revisited. 
 Randomized consensus in expected O(n log n) individual work. 
 Privatization techniques for software transactional memory. 
 Brief announcement: live streaming with utilities, quality and cost. 
 Understanding perfect failure detectors. 
 Interval routing schemes allow broadcasting with linear message-complexity (extended abstract). 
 Low-Congestion Shortcuts in Constant Diameter Graphs. 
 Towards Optimal Synchronous Counting. 
 Detectable byzantine agreement secure against faulty majorities. 
 Witness-Based Cryptographic Program Checking and Applications (an Announcement). 
 Brief Announcement: An Exponential Separation Between Randomized and Deterministic Complexity in the LOCAL Model. 
 Conflict on a communication channel. 
 A Thin Self-Stabilizing Asynchronous Unison Algorithm with Applications to Fault Tolerant Biological Networks. 
 Memory Requirements for Routing in Distributed Networks (Extended Abstract). 
 Coordinated consensus in dynamic networks. 
 Brief announcement: efficient implementation of a byzantine data storage system. 
 Partial information spreading with application to distributed maximum coverage. 
 Efficient Deadlock-Free Routing. 
 Concurrent Common Knowledge: A New Definition of Agreement for Asynchronous Systems. 
 An Algorithm for Replicated Objects with Efficient Reads. 
 Lower and Upper Bounds for Attacks on Authentication Protocols. 
 Competitive on-line paging strategies for mobile users under delay constraints. 
 I/O Automata Based Verification of Finite State Distributed Systems: Complexity Issues (Abstract). 
 Computation in networks of passively mobile finite-state sensors. 
 Plain SINR is Enough! 
 A Hierarchy of Temporal Properties. 
 Sequential composition of protocols without simultaneous termination. 
 Maintenance of a Spanning Tree in Dynamic Networks. 
 Understanding and Verifying Distributed Algorithms Using Stratified Decomposition. 
 Accurate byzantine agreement with feedback. 
 The power of DCAS: highly-concurrent software transactional memory. 
 Dumbo-MVBA: Optimal Multi-Valued Validated Asynchronous Byzantine Agreement, Revisited. 
 A Performance Evaluation of Lock-Free Synchronization Protocols. 
 Synchronizing without locks is inherently expensive. 
 Brief announcement: Super-fast t-ruling sets. 
 Optimal Distance Labeling Schemes for Trees. 
 A paradox of eventual linearizability in shared memory. 
 Election and Traversal in Unidirectional Networks. 
 Maintaining Authenticated Communication in the Presence of Break-ins. 
 An Optimal Algorithm for Generalized Causal Message Ordering (Abstract). 
 Consensus in the Presence of Partial Synchrony (Preliminary Version). 
 Brief announcement: coupling for Markov decision processes - application to self-stabilization with arbitrary schedulers. 
 The flip markov chain and a randomising P2P protocol. 
 Dynamic service assignment in mobile networks: the magma approach. 
 Liveness-Preserving Simulation Relations. 
 A Template for Implementing Fast Lock-free Trees Using HTM. 
 Principles and Patterns of High-Performance and Real-Time Distributed Object Computing. 
 Fast and Exact Majority in Population Protocols. 
 Impossibility Results for Distributed Transactional Memory. 
 An Omega(D log(N/D)) Lower Bound for Broadcast in Radio Networks. 
 Using k-Exclusion to Implement Resilient, Scalable Shared Objects (Extended Abstract). 
 A Wait-Free Sorting Algorithm. 
 SNZI: scalable NonZero indicators. 
 On the Parallels between Paxos and Raft, and how to Port Optimizations. 
 On the Power of Shared Object Types to Implement One-Resilient Consensus. 
 Brief announcement: constructing fault-tolerant overlay networks for topic-based publish/subscribe. 
 Viceroy: a scalable and dynamic emulation of the butterfly. 
 Brief announcement: pareto optimal solutions to consensus and set consensus. 
 Adaptive Wait-Free Algorithms for Lattice Agreement and Renaming (Extended Abstract). 
 Exploiting Locality in Maintaining Potential Causality. 
 Distributed Uniformity Testing. 
 Fundamental Distributed Protocols in Mobile Networks. 
 Separating Bounded and Unbounded Asynchrony for Autonomous Robots: Point Convergence with Limited Visibility. 
 How to wait when you are not going to block. 
 Multicoordinated Paxos. 
 Time-efficient randomized multiple-message broadcast in radio networks. 
 A highly concurrent group mutual /-exclusion algorithm 
 Flash Mixing. 
 Compact routing with slack in low doubling dimension. 
 Brief announcement: distributed algorithms for approximating wireless network capacity. 
 Efficient Delta-Causal Broadcasting of Multimedia Applications (Abstract). 
 Spanners and sparsifiers in dynamic streams. 
 Set-Linearizability. 
 Brief announcement: single-version permissive STM. 
 Brief Announcement: A Local Stochastic Algorithm for Separation in Heterogeneous Self-Organizing Particle Systems. 
 Language Concepts for Distributed Processing of Large Arrays. 
 Brief announcement: efficient clustering in unstructured radio networks. 
 Adaptively Secure Computation with Partial Erasures. 
 A Hierarchy of Temporal Properties (Abstract). 
 Complete Implementations for Shared Memory Consistency Conditions (Abstract). 
 The Price of being Adaptive. 
 Quantum Distributed Algorithm for the All-Pairs Shortest Path Problem in the CONGEST-CLIQUE Model. 
 Gossip-based aggregate computation: computing faster with non address-oblivious schemes. 
 Dynamic atomic storage without consensus. 
 What Critical Algebraic Property Allows Operations of Concurrent Abstract Data Types to be Fast? (Brief Announement). 
 Brief Announcement: Fast Shared Counting using (O(n)) Compare-and-Swap Registers. 
 Lower Bounds for Wait-Free Computation in Message-Passing Systems. 
 Compact routing schemes with improved stretch. 
 Brief announcement: improved asynchronous group mutual exclusion in token-passing networks. 
 Good-case Latency of Byzantine Broadcast: a Complete Categorization. 
 Asynchronous Byzantine Consensus. 
 Securing social networks. 
 Reclaiming Memory for Lock-Free Data Structures: There has to be a Better Way. 
 On using network attached disks as shared memory. 
 Distributing the setup in universally composable multi-party computation. 
 Incremental Attribute Evaluation in Distributed Language-Based Environments. 
 Parsimonious flooding in dynamic graphs. 
 A Trivial Yet Optimal Solution to Vertex Fault Tolerant Spanners. 
 Folding and Unrolling Systolic Arrays (Preliminary Version). 
 Toward an optimal social network defense against Sybil attacks. 
 Exploiting precision vs. efficiency tradeoffs in symmetric replication environments. 
 An Efficient Adaptive Partial Snapshot Implementation. 
 Brief announcement: gracefully degrading consensus and k-set agreement under dynamic link failures. 
 Reasoning About Networks With Many Identical Finite-State Processes. 
 Asynchronous convex hull consensus in the presence of crash faults. 
 On the expected payment of mechanisms for task allocation. 
 Lamport on mutual exclusion: 27 years of planting seeds. 
 Asynchronous resource discovery. 
 Online set packing and competitive scheduling of multi-part tasks. 
 Brief announcement: on the possibility of consensus in asynchronous systems with finite average response times. 
 The universe of symmetry breaking tasks. 
 On the power of the congested clique model. 
 Can Uncoordinated Beeps tell Stories? 
 Brief announcement: From sequential to concurrent: correctness and relative efficiency. 
 On the Relation between Bit Delay for Slot Reuse and the Number of Address Bits in the Dual Bus Configuration (Brief Announcement). 
 Unifying Self-Stabilization and Fault-Tolerance (Preliminary Version). 
 Local Stabilizer (Brief Announcement). 
 Distributed Task and Memory Management. 
 Tight bounds for delay-sensitive aggregation. 
 Space-Efficient Construction of Buffer-Optimal 1-Writer 1-Reader Multivalued Atomic Variable (Abstract). 
 Fast Asynchronous Byzantine Agreement (Extended Abstract). 
 Proving Safety and Liveness of Communicating Processes with Examples. 
 Local approximation schemes for topology control. 
 Randomized broadcast in radio networks with collision detection. 
 Brief Announcement: Gossiping with Latencies. 
 Random walks which prefer unvisited edges.: exploring high girth even degree expanders in linear time. 
 Lock-free linked lists and skip lists. 
 Brief Announcement: Active Information Spread in Networks. 
 Object location using path separators. 
 Locating a target with an agent guided by unreliable local advice: how to beat the random walk when you have a clock? 
 Upper and Lower Bounds on the Space Complexity of Detectable Objects. 
 The forgiving tree: a self-healing distributed data structure. 
 Pregel: a system for large-scale graph processing. 
 On the inherent weakness of conditional synchronization primitives. 
 Compact roundtrip routing with topology-independent node names. 
 Brief announcement: on the resilience of routing tables. 
 On the topologies formed by selfish peers. 
 A Little Knowledge Goes a Long Way: Simple Knowledge-based Derivations and Correctness Proofs for a Family of Protocols. 
 Brief Announcement: Noisy Beeping Networks. 
 Leaplist: lessons learned in designing tm-supported range queries. 
 Specification, implementation and application of randomized regular registers (brief announcement). 
 Separating Lock-Freedom from Wait-Freedom. 
 Bounds on Information Exchange for Byzantine Agreement. 
 Efficient and Reliable Broadcast is Achievable in an Eventually Connected Network. 
 Iterative approximate byzantine consensus in arbitrary directed graphs. 
 A Modular Technique for the Design of Efficient Distributed Leader Finding Algorithms. 
 Improved Analysis of Deterministic Load-Balancing Schemes. 
 The Weakest Failure Detector for Solving Consensus. 
 Distributed computing with rules of thumb. 
 A randomized distributed algorithm for the maximal independent set problem in growth-bounded graphs. 
 Constant RMR solutions to reader writer synchronization. 
 Paxos made live: an engineering perspective. 
 Asynchronous Group Mutual Exclusion (Extended Abstract). 
 Verifying linearizability with hindsight. 
 MIS on trees. 
 On implementing omega with weak reliability and synchrony assumptions. 
 Improved distributed steiner forest construction. 
 Contention Resolution with Predictions. 
 The Akamai approach to achieving performance and reliability on the internet. 
 Faster communication in known topology radio networks. 
 A Quantitative Approach to Dynamic Networks. 
 Extracting quorum failure detectors. 
 Session details: Session 3A: Congest. 
 Detecting Global Termination Conditions in the Face of Uncertainty. 
 Brief Announcement: On Implementing Software Transactional Memory in the C++ Memory Model. 
 Space Bounds for Transactional Synchronization (Abstract). 
 Compact policy routing. 
 Brief announcement: towards a secure indirection infrastructure. 
 Uniform Deployment of Mobile Agents in Asynchronous Rings. 
 Session details: Session 1A: Persistent Memory. 
 The Optimality of Distributed Constructions of Minimum Weigth and Degree Restricted Spanning Trees in a Complete Network of Processors. 
 Optimal failure detection with low sporadic overhead and communication locality. 
 Fast and compact self stabilizing verification, computation, and fault detection of an MST. 
 Fast byzantine agreement. 
 Trade-offs between selection complexity and performance when searching the plane without communication. 
 Revisionist Simulations: A New Approach to Proving Space Lower Bounds. 
 Brief announcement: a shared disk on distributed storage. 
 Scaling properties of the Internet graph. 
 Evaluating Quorum Systems Over the Internet (Abstract). 
 Brief Announcement: Reliable Message Transmission under Partial Knowledge and General Adversaries. 
 Brief announcement: scalable anonymous communication with byzantine adversary. 
 Robust data sharing with key-value stores. 
 Safe memory reclamation for dynamic lock-free objects using atomic reads and writes. 
 Impossibility and Universality Results for Wait-Free Synchronization. 
 Simple Constant-Time Consensus Protocols in Realistic Failure Models (Extended Abstract). 
 Brief Announcement: Distributed Approximation for Tree Augmentation. 
 Brief announcement: on enhancing concurrency in distributed transactional memory. 
 Snap-stabilization in message-passing systems. 
 On Characterization of Safety and Liveness Properties in Temporal Logic. 
 Brief announcement: on minimum interaction time for continuous distributed interactive computing. 
 A Combinatorial Treatment of Balancing Networks. 
 Brief Announcement: Byzantine-Tolerant Machine Learning. 
 Efficient algorithms for constructing (1+, varepsilon;, beta)-spanners in the distributed and streaming models. 
 Geometrically aware communication in random wireless networks. 
 Coordination Without Prior Agreement. 
 Brief announcement: exploring the consistency problem space. 
 Process Semantics: Universal Axioms Compositional Rules, and Applications. 
 A Categorical Approach to Distributed Systems Expressibility and Knowledge. 
 Brief announcement: techniques for programmatically troubleshooting distributed systems. 
 Randomized Byzantine Agreements. 
 Brief announcement: an efficient failure detector for omission environments. 
 Implementing Hybrid Consistency with High-Level Synchronization Operations (Extenced Abstract). 
 Leveraging Indirect Signaling for Topology Inference and Fast Broadcast. 
 Brief announcement: distributed phase synchronization of dynamic set of processes. 
 Brief announcement: a flexible framework for secret handshakes. 
 Fair and resilient incentive tree mechanisms. 
 SODA: A Simplified Operating System for Distributed Applications. 
 Noisy Beeps. 
 Byzantine broadcast in point-to-point networks using local linear coding. 
 Brief Announcement: Distributed Minimum Vertex Coloring and Maximum Independent Set in Chordal Graphs. 
 Asynchronous recommendation systems. 
 An Approach to Performance Analysis of Timestamp-driven Synchronization Mechanisms. 
 Brief announcement: communication efficient asynchronous byzantine agreement. 
 A Time Complexity Lower Bound for Randomized Implementations of Some Shared Objects. 
 Strong-Diameter Network Decomposition. 
 Two Classes of Communication Patterns. 
 Brief announcement: decentralized construction of multicast trees embedded into P2P overlay networks based on virtual geometric coordinates. 
 On Counting the Population Size. 
 A generalized asynchronous computability theorem. 
 Brief Announcement: A Tight Space Bound for Consensus. 
 Non-searchability of random scale-free graphs. 
 Efficient Token-Based Control in Rings (Abstract). 
 Nonatomic mutual exclusion with local spinning. 
 Efficient Solution to the Distributed Mutual Exclusion Problem. 
 Brief announcement: tight lower bounds for greedy routing in uniform small world rings. 
 Optimal smoothing schedules for real-time streams (extended abstract). 
 Brief Announcement: Robust and Private Distributed Shared Atomic Memory in Message Passing Networks. 
 Deterministic Distributed Dominating Set Approximation in the CONGEST Model. 
 Adaptive system anomaly prediction for large-scale hosting infrastructures. 
 Partial reversal acyclicity. 
 Efficient fork-linearizable access to untrusted shared memory. 
 Lower bounds for local approximation. 
 Combining Funnels: A New Twist on an Old Tale... 
 Tradeoffs for Selection in Distributed Networks (Preliminary Version). 
 Geographic gossip on geometric random graphs via affine combinations. 
 Optimal Multi-Writer Multi-Reader Atomic Register. 
 Transactional memory retry mechanisms. 
 Quorum placement in networks to minimize access delays. 
 Space Complexity of Fault-Tolerant Register Emulations. 
 The weakest failure detector for solving k-set agreement. 
 Distributed Pursuit-Evasion: Some Aspects of Privacy and Security in Distributed Computing. 
 One Bit Algorithms. 
 The Power of Choice in Priority Scheduling. 
 Brief announcement: a shorter and stronger proof of an Ω(d log(n/d)) lower bound for broadcast in radio networks. 
 Early stopping in aglobal data computation. 
 Improved Distributed Delta-Coloring. 
 Mechanism design for policy routing. 
 The IceCube approach to the reconciliation of divergent replicas. 
 Scalable Best Matching Prefix Lookups. 
 Brief announcement: on the inherent cost of generic broadcast. 
 Self-Stabilizing Algorithms for Finding Centers and Medians of Trees. 
 Brief announcement: network formation games can give rise to realistic networks. 
 Parallel Arithmetic with Concurrent Writes. 
 Analyzing Kleinberg's (and other) small-world Models. 
 Brief announcement: efficient flooding in power-law networks. 
 CAR-STM: scheduling-based collision avoidance and resolution for software transactional memory. 
 On Using Time Without Clocks via Zigzag Causality. 
 Rollback-Dependency Trackability: Visible Characterizations. 
 The inherent complexity of transactional memory and what to do about it. 
 Distance estimation and object location via rings of neighbors. 
 Solvability of geocasting in mobile ad-hoc networks. 
 Brief announcement: distributed almost stable marriage. 
 Brief announcement: collusion free protocol for rational secret sharing. 
 Wait-Freedom vs. Bounded Wait-Freedom in Public Data Structures (Extended Abstract). 
 2019 Edsger W. Dijkstra Prize in Distributed Computing. 
 Time and Message Bounds of Election in Synchronous and Asynchronous Complete Networks. 
 Brief announcement: branching time refinement. 
 On the Multiparty Communication Complexity of Testing Triangle-Freeness. 
 On the theoretical gap between synchronous and asynchronous MPC protocols. 
 Positive Aging Admits Fast Asynchronous Plurality Consensus. 
 Choosing a random peer. 
 Time-optimal, space-efficient single-scanner snapshots & multi-scanner snapshots using CAS. 
 Scheduling File Transfers in a Distributed Network. 
 Brief announcement: STALK: a self-stabilizing hierarchical tracking service for sensor networks. 
 A Faster Distributed Radio Broadcast Primitive: Extended Abstract. 
 Distributed Pseudo-Random Bit Generators - A New Way to Speed-Up Shared Coin Tossing. 
 Fast, Long-Lived Renaming Improved and Simplified (Abstract). 
 A Tradeoff Between Safety and Liveness for Randomized Coordinated Attack Protocols. 
 Power management of devices: when should i switch off? 
 Brief announcement: exponential speed-up of local algorithms using non-local communication. 
 Brief Announcement: Self-stabilizing Systems in Spite of High Dynamics. 
 Help! 
 Bounded wait-free implementation of optimally resilient byzantine storage without (unproven) cryptographic assumptions. 
 Bounded Round Numbers. 
 Data Summarization and Distributed Computation. 
 What cannot be computed locally! 
 Hierarchical Correctness Proofs for Distributed Algorithms. 
 Comparing Primary-Backup and State Machines for Crash Failures (Abstract). 
 Blockchains and the Future of Distributed Computing. 
 Brief announcement: early decision despite general process omission failures. 
 Brief announcement: wait-free implementation of multiple-writers/multiple-readers atomic byzantine data storage systems. 
 Engineering Distributed Systems that We Can Trust (and Also Run). 
 Computing Anonymously with Arbitrary Knowledge. 
 A Distributed Algorithm for Detecting Resource Deadlocks in Distributed Systems. 
 Randomized Proof-Labeling Schemes. 
 Distributed Approximation of Maximum Independent Set and Maximum Matching. 
 Brief announcement: on the quest of optimal service ordering in decentralized queries. 
 Mutable Check-Points: A New Checkpointing Approach for Mobile Computing Systems. 
 Fork-consistent constructions from registers. 
 An incomplete history of concurrency chapter 1. 1965-1977. 
 Distributed Construction of Light Networks. 
 tight bounds for k-set agreement with limited-scope failure detectors. 
 Linearizable State Machine Replication of State-Based CRDTs without Logs. 
 On the Time-Complexity of Broadcast in Radio Networks: An Exponential Gap Between Determinism and Randomization. 
 Optimal gradient clock synchronization in dynamic networks. 
 Universal constructions that ensure disjoint-access parallelism and wait-freedom. 
 Memory consistency and process coordination for SPARC v8 multiprocessors (brief announcement). 
 Weighted Matchings via Unweighted Augmentations. 
 Brief Announcement: Sustainable Blockchains through Proof of eXercise. 
 Towards Dataflow Analysis of Communicating Finite State Machines. 
 Efficient Communication in Cognitive Radio Networks. 
 On ad hoc routing with guaranteed delivery. 
 New combinatorial topology upper and lower bounds for renaming. 
 Brief announcement: ART--sub-logarithmic decentralized range query processing with probabilistic guarantees. 
 Distributed Control for PARIS. 
 Game authority for robust andscalable distributed selfish-computer systems. 
 Unbeatable Set Consensus via Topological and Combinatorial Reasoning. 
 Self-stabilizing counting in mobile sensor networks. 
 Timeliness-based wait-freedom: a gracefully degrading progress condition. 
 Brief announcement: impossibility results for optimistic fair exchange with multiple autonomous arbiters. 
 Randomized shared queues. 
 Reliable broadcast in radio networks: the bounded collision case. 
 On perfectly secure cmmunication over arbitrary networks. 
 Adaptive Algorithms for PASO Systems. 
 Accountability for distributed systems. 
 Brief announcement: breaking the O(nm) bit barrier, secure multiparty computation with a static adversary. 
 Competitive weighted throughput analysis of greedy protocols on DAGs. 
 How computer architecture trends may affect future distributed systems: from infiniBand clusters to inter-processor speculation (abstract). 
 Aggregation in dynamic networks. 
 High performance adaptive middleware for CORBA-based systems. 
 Randomized compact routing in decomposable metrics. 
 Progress Measures and Stack Assertions for Fair Termination. 
 Brief announcement: waiting in dynamic networks. 
 Oracle size: a new measure of difficulty for communication tasks. 
 Concurrent Data Structures. 
 Achieving scalability and expressiveness in an Internet-scale event notification service. 
 Minimizing Access Costs in Replicated Distributed Syste (Abstract). 
 Breaking the O(√ n)-Bit Barrier: Byzantine Agreement with Polylog Bits Per Party. 
 On Achieving Consensus Using a Shared Memory. 
 Object replication degree customization for high availability. 
 Fast protocol transition in a distributed environment (brief announcement). 
 Brief announcement: on the round complexity of distributed consensus over synchronous networks. 
 Randomized Adaptive Video on Demand (Abstract). 
 Efficient Multiparty Interactive Coding for Insertions, Deletions, and Substitutions. 
 Low-Congestion Shortcuts without Embedding. 
 Pragmatic primitives for non-blocking data structures. 
 Brief Announcement: MUSIC: Multi-Site Entry Consistencyfor Geo-Distributed Services. 
 Bringing practical lock-free synchronization to 64-bit applications. 
 Experiences with a Model for Parallel Computation. 
 Randomized Wait-Free Concurrent Objects (Extended Abstract). 
 On the Analysis of Cooperation and Antagonism in Networks of Communicating Processes. 
 Virtual infrastructure for collision-prone wireless networks. 
 Toward Optimal Bounds in the Congested Clique: Graph Connectivity and MST. 
 Effectiveness of Delaying Timestamp Computation. 
 Lower Bounds on the State Complexity of Population Protocols. 
 Revisiting Optimal Resilience of Fast Byzantine Consensus. 
 Structured Derivations of Consensus Algorithms for Failure Detectors. 
 Brief announcement: linial's lower bound made easy. 
 Load Balancing Networks (Abstract). 
 Configurations: a model for distributed data storage. 
 On Termination of a Flooding Process. 
 Resettable vector clocks. 
 Brief announcement: fair maximal independent sets in trees. 
 Efficient single phase unconditionally secure message transmission with optimum communication complexity. 
 Measuring robustness of superpeer topologies. 
 Stability of a peer-to-peer communication system. 
 Constructing scalable overlays for pub-sub with many topics. 
 Brief Announcement: Linearizability: A Typo. 
 Efficient top-K query calculation in distributed networks. 
 Patterns of Communication in Consensus Protocols. 
 Brief Announcement: Optimal Address-Oblivious Epidemic Dissemination. 
 Distributed algorithms for edge dominating sets. 
 Collecting Cyclic Distributed Garbage Using Heuristics to Control Migration. 
 A framework for semantic reasoning about Byzantine quorum systems. 
 Relations Between Concurrent-Write Models of Parallel Computation. 
 A Simple Algorithmically Reasoned Characterization of Wait-Free Computations (Extended Abstract). 
 Congested Clique Algorithms for the Minimum Cut Problem. 
 Contention-free Complexity of Shared Memory Algorithms. 
 Search via Parallel Lévy Walks on Z2. 
 What's a little collusion between friends? 
 Local Computation Algorithms. 
 Observable Clock Synchronization (Extended Abstract). 
 Computing property-preserving behaviour abstractions from trace reductions: abstraction-based verification of linear-time properties under fairness. 
 Trade-offs in Fault-Containing Self-Stabilization. 
 Brief announcement: the price of anarchy for distributed network formation in an adversary model. 
 On the complexity of asynchronous agreement against powerful adversaries. 
 Brief Announcement: Performance Prediction for Coarse-Grained Locking. 
 A Predicate Transformer Approach to Knowledge and Knowledge-Based Protocols (Extended Abstract). 
 Can Distributed Uniformity Testing Be Local? 
 Efficient Schemes for Parallel Communication. 
 On Efficient Distributed Construction of Near Optimal Routing Schemes: Extended Abstract. 
 Collective Consistency (Work in Progress, Abstract). 
 Can Message Buffers be Characterized in Linear Temporal Logic? 
 Evaluating the running time of a communication round over the internet. 
 Lock-free reference counting. 
 Computing separable functions via gossip. 
 String realizers of posets with applications to distributed computing. 
 Tight Bounds for Shared Memory Symmetric Mutual Exclusion Problems. 
 Backward Consistency and Sense of Direction in Advanced Distributed Systems. 
 The multi-agent rotor-router on the ring: a deterministic alternative to parallel random walks. 
 On the Decidability of Distributed Decision Tasks (Brief Announcement). 
 Programming models for extreme-scale computing. 
 Brief announcement: capacity of byzantine agreement with finite link capacity - complete characterization of four-node networks. 
 Order optimal information spreading using algebraic gossip. 
 The amortized complexity of non-blocking binary search trees. 
 Asynchronous group key exchange with failures. 
 A Fault-Tolerant Dynamic Resource Allocation Algorithm (Short Abstract). 
 Communication Complexity of Byzantine Agreement, Revisited. 
 Bounds for the convergence rate of randomized local search in a multiplayer load-balancing game. 
 The weakest failure detector to solve nonuniform consensus. 
 Brief announcement: byzantine agreement with a strong adversary in polynomial expected time. 
 Scalable Concurrent Priority Queue Algorithms. 
 Efficient Detection of Corrupted Pages in a Replicated File (Preliminary Report). 
 Distributed Minimum Degree Spanning Trees. 
 Brief Announcement: Population Protocols for Leader Election and Exact Majority with  
 Self-Stabilization by Counter Flushing. 
 Collecting Distributed Garbage Cycles by Back Tracing. 
 Brief Announcement: Data Dissemination in Unified Dynamic Wireless Networks. 
 On Local Distributed Sampling and Counting. 
 Algorithms for dynamic multicast key distribution trees. 
 Brief announcement: what can be computed without communication? 
 Locally Checkable Problems in Rooted Trees. 
 Restorable Shortest Path Tiebreaking for Edge-Faulty Graphs. 
 Brief Announcement: Oh-RAM! One and a Half Round Read/Write Atomic Memory. 
 The Complexity of (Δ+1) Coloring in Congested Clique, Massively Parallel Computation, and Centralized Local Computation. 
 Four Combinators for Concurrency. 
 Time-Optimal Construction of Overlay Networks. 
 Issues and Directions in Scalable Parallel Computing. 
 Fast, Wait-Free (2 
 The disagreement power of an adversary: extended abstract. 
 Consensus Numbers of Multi-Objects. 
 Distributing the Power of a Government to Enhance the Privacy of Voters (Extended Abstract). 
 Brief announcement: completing the lock-free dynamic cycle. 
 On reliable broadcast in a radio network. 
 Load balancing without regret in the bulletin board model. 
 On the Use of Registers in Achieving Wait-Free Consensus. 
 A Technique for Partial Broadcasting in Networks (Abstract). 
 Proactive secure message transmission in asynchronous networks. 
 Brief announcement: tree decomposition for faster concurrent data structures. 
 Long-lived and adaptive atomic snapshot and immediate snapshot (extended abstract). 
 Solving the at-most-once problem with nearly optimal effectiveness. 
 A Dynamic View-Oriented Group Communication Service. 
 Fast scalable deterministic consensus for crash failures. 
 Analysis of Timing-Based Mutual Exclusion with Random Times. 
 Analysis of the evolution of peer-to-peer systems. 
 Split-ordered lists: lock-free extensible hash tables. 
 Synchronization and Recovery of Actions. 
 Self-Stabilizing Leader Election. 
 Decentalized Replicated-Object Protocols. 
 Brief Announcement: Eventually Consistent Linearizability. 
 Eventually linearizable shared objects. 
 Assignment-based partitioning in a condition monitoring system. 
 Brief Announcement: Optimal Leader Election in Multi-Hop Radio Networks. 
 An Automatic Speedup Theorem for Distributed Problems. 
 Forget him and keep on moving. 
 B-neck: a distributed and quiescent max-min fair algorithm. 
 UIDS as Internal Names in a Distributed File System. 
 DConstructor: Efficient and Robust Network Construction with Polylogarithmic Overhead. 
 Faster randomized consensus with an oblivious adversary. 
 Mobile proactive secret sharing. 
 Towards a Topological Characterization of Asynchronous Complexity (Preliminary Version). 
 Brief announcement: NUMA-aware transactional memory. 
 A hierarchy of conditions for consensus solvability. 
 K-set agreement bounds in round-based models through combinatorial topology. 
 Fast Deflection Routing for Packets and Worms (Extended Summary). 
 Brief announcement: building data structures on untrusted peer-to-peer storage with per-participant logs. 
 The Inhibition Spectrum and the Achievement of Causal Consistency. 
 Early-Stopping Terminating Reliable Broadcast Protocol for General Omission Failures (Abstract). 
 Automatically Increasing the Fault-Tolerance of Distributed Systems. 
 A Temporal Logic for Reasoning about Partially Ordered Computations (Extended Abstract). 
 Uniform Actions in Asynchronous Distributed Systems (Extended Abstract). 
 Message Reduction in the LOCAL Model is a Free Lunch. 
 Real-Time Programming and Asynchronous Message Passing. 
 Memory models: a case for rethinking parallel languages and hardware. 
 Brief Announcement: Distributed Single-Source Reachability. 
 Brief Announcement: Simple and Local Independent Set Approximation. 
 Sketching asynchronous streams over a sliding window. 
 Stabilizing Algorithms for Diagnosing Crash Failures. 
 Replicated condition monitoring. 
 On maintaining multiple versions in STM. 
 Optimally efficient multi-valued byzantine agreement. 
 Knowledge, Timed Precedence and Clocks (Preliminary Report). 
 Hardness of Exact Distance Queries in Sparse Graphs Through Hub Labeling. 
 Finding Safe Paths in a Faulty Environment. 
 The power of simulation relations. 
 Efficient generation of all regular non-dominated coteries. 
 Fast Concurrent Data Sketches. 
 Stone age distributed computing. 
 Transactions and privatization in Delaunay triangulation. 
 Towards fast decentralized construction of locality-aware overlay networks. 
 Supporting Quality of Service in HTTP Servers. 
 New Opportunities for PODC?: Massive, Volatile, but Highly Predictable Resources. 
 Software-improved hardware lock elision. 
 Minimizing the total cost of network measurements in a distributed manner: a primal-dual approach. 
 Stable and fault-tolerant object allocation. 
 Distributed deterministic edge coloring using bounded neighborhood independence. 
 Memory-Efficient and Self-Stabilizing Network {RESET} (Extended Abstract). 
 Asynchronous MPC with a strict honest majority using non-equivocation. 
 Vorpal: Vector Clock Ordering For Large Persistent Memory Systems. 
 Inexact Agreement: Accuracy, Precision, and Graceful Degradation. 
 On Processor Coordination Using Asynchronous Hardware. 
 Optimal atomic broadcast and multicast algorithms for wide area networks. 
 Fault-Tolerant Consensus in Directed Graphs. 
 GS 
 Partially Replicated Causally Consistent Shared Memory: Lower Bounds and An Algorithm. 
 Implementation of Authenticated Communication Based on Hierarchy-Relative Naming Scheme. 
 A work-optimal deterministic algorithm for the asynchronous certified write-all problem. 
 Anti-Omega: the weakest failure detector for set agreement. 
 Brief announcement: virtual stationary automata for mobile networks. 
 Brief announcement: a local approximation algorithm for MDS problem in anonymous planar networks. 
 Brief Announcement: Graph Exploration Using Constant-Size Memory and Storage. 
 Recent Trends in Experimental Operating Systems Research. 
 Brief announcement: exposing and eliminating vulnerabilities to denial of service attacks in secure gossip-based multicast. 
 Non-blocking steal-half work queues. 
 A jamming-resistant MAC protocol for single-hop wireless networks. 
 Tolerating corrupted communication. 
 Verification Engineering: A Future Profession (A. M. Turing Award Lecture). 
 How to meet when you forget: log-space rendezvous in arbitrary graphs. 
 Complete Characterization of Adversaries Tolerable in Secure Multi-Party Computation (Extended Abstract). 
 The art of multiprocessor programming. 
 Brief announcement: an asymmetric flat-combining based queue algorithm. 
 Brief announcement: efficient replication of large data objects. 
 Massively Parallel Algorithms for Finding Well-Connected Components in Sparse Graphs. 
 On Site Fidelity and the Price of Ignorance in Swarm Robotic Central Place Foraging Algorithms. 
 Construction and Impromptu Repair of an MST in a Distributed Network with o(m) Communication. 
 Highly dynamic distributed computing with byzantine failures. 
 On the locality of distributed sparse spanner construction. 
 Brief Announcement: Local Independent Set Approximation. 
 Brief Announcement: What's Live? Understanding Distributed Consensus. 
 Brief announcement: decentralized network supercomputing in the presence of malicious and crash-prone workers. 
 Set Consensus Using Arbitrary Objects (Preliminary Version). 
 Fair group mutual exclusion. 
 d-clock: distributed QoS in heterogeneous resource environments. 
 Impossibility of (Repeated) Reliable Broadcast (Abstract). 
 A Proof of a Theorem in Algebraic Topology by a Distributed Algorithm (Abstract). 
 Brief announcement: a generic construction for nonblocking dual containers. 
 A network pricing game for selfish traffic. 
 Transactional memory for a modern microprocessor. 
 On the Memory Overhead of Distributed Snapshots. 
 Optimal Gossip Algorithms for Exact and Approximate Quantile Computations. 
 Sticks and stones: a coding scheme for parameterized verification. 
 Distributed Edge Coloring in Time Quasi-Polylogarithmic in Delta. 
 On Distributed Listing of Cliques. 
 A Polylog Time Wait-Free Construction for Closed Objects. 
 Efficient synchronous snapshots. 
 Brief announcement: adaptive balancing networks. 
 How Useful Is Old Information? (Extended Abstract). 
 A Deterministic Distributed Algorithm for Exact Weighted All-Pairs Shortest Paths in Õ(n 3/2 ) Rounds. 
 Properties of a Family of Booster Types. 
 Space Bounds for Reliable Storage: Fundamental Limits of Coding. 
 Brief Announcement: Secure Self-Stabilizing Computation. 
 Load balancing and locality in range-queriable data structures. 
 Beyond geometry: towards fully realistic wireless models. 
 Locking Timestamps versus Locking Objects. 
 Simple and fast optimistic protocols for fair electronic exchange. 
 Noisy Rumor Spreading and Plurality Consensus. 
 Constant Amortized RMR Abortable Mutex for CC and DSM. 
 Efficient lookup on unstructured topologies. 
 Disentangling Multi-Object Operations (Extended Abstract). 
 Efficient distributed source detection with limited bandwidth. 
 Modelling Garbage Collection Algorithms Using CCS and Temporal Logic (Abstract). 
 Near-optimal communication-time tradeoff in fault-tolerant computation of aggregate functions. 
 Leslie Lamport's properties and actions. 
 Principle for High Speed Network Control: Congestion- and Deadlock-Freeness, Self-Routing, and a Single Buffer per Link. 
 Locally checkable proofs. 
 Communication-efficient leader election and consensus with limited link synchrony. 
 Scheduling sensors by tilinglattices. 
 Brief Announcement: Classifying Trusted Hardware via Unidirectional Communication. 
 Compact Deterministic Distributed Dictionaries (Extended Abstract). 
 Rationality authority for provable rational behavior. 
 Performance Issues in Non-blocking Synchronization on Shared-memory Multiprocessors. 
 Brief Announcement: Leader Election in SINR Model with Arbitrary Power Control. 
 Compiler Support for Decoupled Virtual Shared Memory Systems. 
 Preventing versus curing: avoiding conflicts in transactional memories. 
 Brief announcement: concurrent maintenance of rings. 
 Competitiveness in Distributed Algorithms. 
 Distributed discovery of large near-cliques. 
 A new technique for distributed symmetry breaking. 
 Comparing How Atomicity Mechanisms Support Replication. 
 Properties of the  
 A Complexity-Based Hierarchy for Multiprocessor Synchronization: [Extended Abstract]. 
 Connectivity Lower Bounds in Broadcast Congested Clique. 
 Concurrent Programming For The Masses (PODC 1984 Invited Address). 
 Multimedia Networking: Applications and Challenges. 
 Fast Set Agreement in the Presence of Timing Uncertainty. 
 On the impact of geometry on ad hoc communication in wireless networks. 
 Broadcasting in unreliable radio networks. 
 An Agent-Based Architecture for Buildiang CORBA Distributed Systems. 
 A Characterization of Eventual Byzantine Agreement. 
 Active disk paxos with infinitely many processes. 
 Brief announcement: realizing secure multiparty computation on incomplete networks. 
 On Serializability of Iterated Transactions. 
 A Semantics for a Logic of Authentication (Extended Abstract). 
 Time-Optimal Message-Efficient Work Performance in the Presence of Faults (Extended Summary). 
 The multiplicative power of consensus numbers. 
 Distributed and parallel algorithms for weighted vertex cover and other covering problems. 
 A Consistent History Link Connectivity Protocol. 
 Leap Forward Virtual Clock: A New Fair Queuing Scheme with Guaranteed Delays and Throughput Fairness. 
 The Unified Structure of Consensus: A  
 Distributed Dynamic Channel Allocation for Mobile Computing. 
 Dynamic input/output automata, a formal model for dynamic systems. 
 Derivation of Fault Tolerance Properties of Distributed Algorithms. 
 Interleaving Set Temporal Logic (Preliminary Version). 
 Brief announcement: all-to-all gradecast using coding with byzantine failures. 
 Two-Bit Messages are Sufficient to Implement Atomic Read/Write Registers in Crash-prone Systems. 
 Optimal-time adaptive strong renaming, with applications to counting. 
 Specification faithfulness in networks with rational nodes. 
 Brief Announcement: Improved Distributed Approximations for Maximum-Weight Independent Set. 
 The Greedy Spanner is Existentially Optimal. 
 New Models and Algorithms for Future Networks. 
 How to Spread a Rumor: Call Your Neighbors or Take a Walk? 
 Efficient Distributed Recovery Using Message Logging. 
 Feedback control for router congestion resolution. 
 Timed Consistency for Shared Distributed Objects. 
 Improved Algorithms for Distributed Resource Allocation. 
 A Provably Secure Polynomial Approximation Scheme for the Distributed Lottery Problem (Extended Abstract). 
 On the time and space complexity of randomized test-and-set. 
 p 
 Counting Networks are Practically Linearizable. 
 Tight Bounds for the Sequence Transmission Problem. 
 On the Space Complexity of Set Agreement? 
 Gossip in a Smartphone Peer-to-Peer Network. 
 The Global Time Assumption and Semantics for Concurrent Systems. 
 Possibility and complexity of probabilistic reliable communication in directed networks. 
 From an intermittent rotating star to a leader. 
 Slowing Sequential Algorithms for Obtaining Fast Distributed and Parallel Algorithms: Maximum Matchings. 
 Brief Announcement: A Family of Leaderless Generalized-Consensus Algorithms. 
 Brief Announcement: Partially Replicated Causally Consistent Shared Memory. 
 Coins, Weights and Contention in Balancing Networks. 
 Information-Theoretic Lower Bounds on the Storage Cost of Shared Memory Emulation. 
 Distributed averaging in the presence of a sparse cut. 
 Brief announcement: local approximability of minimum dominating set on planar graphs. 
 Broadcasting in Noisy Radio Networks. 
 Designing Algorithms for Distributed Systems with Partially Synchronized Clocks. 
 Brief Announcement: Statement Voting and Liquid Democracy. 
 Efficient player-optimal protocols for strong and differential consensus. 
 An Asynchronou [(n-1)/3]-Resilient Consensus Protocol. 
 An  
 An Interval-Based Framework for Clock Rate Synchronization. 
 Contention in Balancing Networks Resolved (Extended Abstract). 
 Non-blocking timeout in scalable queue-based spin locks. 
 Balancing energy consumption for uniform data gathering wireless sensor networks. 
 Active Client Primary-Backup Protocols (Abstract). 
 Brief announcement: are lock-free concurrent algorithms practically wait-free? 
 Correctness of gossip-based membership under message loss. 
 A Protocol for Wait-Free, Atomic, Multi-Reader Shared Variables. 
 Choice Coordination with Bounded Failure (a Preliminary Version). 
 Constant-Round Spanners and Shortest Paths in Congested Clique and MPC. 
 Scalable public-key tracing and revoking. 
 Triangle Finding and Listing in CONGEST Networks. 
 Log-Space Polynomial End-to-End Communication (Abstract). 
 Synthesis of Communicating Processes. 
 Brief Announcement: Self-stabilizing Clock Synchronization with 3-bit Messages. 
 Search on a Line with Faulty Robots. 
 Generalizing the Sharp Threshold Phenomenon for the Distributed Complexity of the Lovász Local Lemma. 
 Contention Resolution on a Fading Channel. 
 Time-Optimal Self-Stabilizing Leader Election in Population Protocols. 
 Dynamic packet scheduling in wireless networks. 
 Verifiable Outsourced Computation: A Survey. 
 Overview of Persistent Memory in Distributed Systems Architecture - Past, Present, Future. 
 Knowledge and Common Knowledge in a Distributed Environment. 
 Emerging challenges and opportunities in parallel computing: the cretaceous redux? 
 Brief announcement: a calculus of policy-based routing systems. 
 Shifting Gears: Changing Algorithms on the Fly To Expedite Byzantine Agreement. 
 Computing Shortest Paths and Diameter in the Hybrid Network Model. 
 Beyond nash equilibrium: solution concepts for the 21st century. 
 A complexity separation between the cache-coherent and distributed shared memory models. 
 Randomized Concurrent Set Union and Generalized Wake-Up. 
 Optimal gossip with direct addressing. 
 The Space Complexity of Scannable Binary Objects. 
 Brief Announcement: How Large is your Graph? 
 Distributed MST for constant diameter graphs. 
 Deterministic Distributed (Delta + o(Delta))-Edge-Coloring, and Vertex-Coloring of Graphs with Bounded Diversity. 
 Efficient Deterministic Distributed Coloring with Small Bandwidth. 
 On the Robustness of Herlihy's Hierarchy. 
 Brief announcement: reconfigurable state machine replication from non-reconfigurable building blocks. 
 The Consensus Hierarchy Is Not Robust. 
 Brief announcement: Stateless distributed algorithms for generalized packing linear programs. 
 Summarizing data using bottom-k sketches. 
 Distributed Spanner Approximation. 
 A Recognize-and-Accuse Policy to Speed up Distributed Processes. 
 Broadcast with Partial Knowledge (Preliminary Version). 
 Brief announcement: abstractions for implementing atomic objects in dynamic systems. 
 Distributed k-Selection: From a Sequential to a Distributed Algorithm. 
 Building secure file systems out of Byzantine storage. 
 Brief announcement: topology knowledge affects probabilistic reliable communication. 
 Facility location: distributed approximation. 
 Brief announcement: the accuracy of tree-based counting in dynamic networks. 
 Maximal independent sets in multichannel radio networks. 
 A Randomized Concurrent Algorithm for Disjoint Set Union. 
 Real-Time Sequence Transmission Problem. 
 Brief announcement: sources of instability in data center multicast. 
 Stability of long-lived consensus (extended abstract). 
 Asymptotically Optimal Algorithms For Approximale Agreement. 
 Efficient randomised broadcasting in random regular networks with applications in peer-to-peer systems. 
 Eventually-Serializable Data Services. 
 Space-Optimal Wait-Free Queues. 
 On the Use of Randomness in Local Distributed Graph Algorithms. 
 Byzantine agreement with homonyms. 
 The Convergence of Stochastic Gradient Descent in Asynchronous Shared Memory. 
 Distributed algorithms for scheduling on line and tree networks. 
 Working with mike on distributed computing theory, 1978--1992. 
 Brief Announcement: Making Synchronous BFT Protocols Secure in the Presence of Mobile Sluggish Faults. 
 A Simple Deterministic Distributed MST Algorithm, with Near-Optimal Time and Message Complexities. 
 Brief announcement: self-monitoring in dynamic wireless networks. 
 On the Subject of Non-Equivocation: Defining Non-Equivocation in Synchronous Agreement Systems. 
 Hardness of Minimal Symmetry Breaking in Distributed Computing. 
 SuperStabilizing Protocols for Dynamic Distributed Systems (Abstract). 
 Estimation for monotone sampling: competitiveness and customization. 
 Time and Space Lower Bounds for Non-Blocking Implementations (Preliminary Version). 
 Byzantine Clock Synchronization. 
 A closer look at fault tolerance. 
 Collaborative search on the plane without communication. 
 Byzantine renaming in synchronous systems with t<N. 
 Epidemic Algorithms for Replicated Database Maintenance. 
 Low-Congestion Shortcuts for Graphs Excluding Dense Minors. 
 Differential Privacy in Distributed Environments: An Overview and Open Questions. 
 Faster than optimal snapshots (for a while): preliminary version. 
 Decision Power of Weak Asynchronous Models of Distributed Computing. 
 On Information Complexity in the Broadcast Model. 
 Communication Primitives in Cognitive Radio Networks. 
 Routing networks for distributed hash tables. 
 SINR diagrams: towards algorithmically usable SINR models of wireless networks. 
 Every problem has a weakest failure detector. 
 Brief Announcement: On the Message Complexity of Fault-Tolerant Computation: Leader Election and Agreement. 
 Fast deterministic consensus in a noisy environment. 
 Universal Operations: Unary versus Binary (Extended Abstract). 
 The effect of power-law degrees on the navigability of small worlds: [extended abstract]. 
 Brief announcement: on the expected overpayment of VCG mechanisms in large networks. 
 Massively Parallel Computation of Matching and MIS in Sparse Graphs. 
 On cost sharing mechanisms in the network design game. 
 Brief Announcement: Resource Competitive Broadcast against Adaptive Adversary in Multi-channel Radio Networks. 
 Nesting-Safe Recoverable Linearizability: Modular Constructions for Non-Volatile Memory. 
 On spectrum sharing games. 
 On local representation of distances in trees. 
 A fast distributed slicing algorithm. 
 Brief announcement: the synchronous condition-based consensus hierarchy. 
 Brief Announcement: 2D-Stack - A Scalable Lock-Free Stack Design that Continuously Relaxes Semantics for Better Performance. 
 Brief announcement: brokerage and closure in a strategic model of social capital. 
 Compact routing with stretch factor of less than three (brief announcement). 
 About State Recording in Asynchronous Computations (Abstract). 
 Token Management Schemes and Random Walks Yield Self-Stabilizing Mutual Exclusion. 
 Truth in advertising: lightweight verification of route integrity. 
 Potential Function Analysis of Greedy Hot-Potato Routing. 
 The cost of fault tolerance in multi-party communication complexity. 
 Dynamic-sized lock-free data structures. 
 Transforming worst-case optimal solutions for simultaneous tasks into all-case optimal solutions. 
 Brief Announcement: Hazard Pointer Protection of Structures with Immutable Links. 
 A Layered Architecture for Erasure-Coded Consistent Distributed Storage. 
 Randomness in Private Computations. 
 Deterministic Objects: Life Beyond Consensus. 
 General perfectly periodic scheduling. 
 Linear-in-delta lower bounds in the LOCAL model. 
 Brief announcement: analysis of a randomized contention-resolution protocol for distributed access. 
 XMP++: An Object-Oriented Solution for Hiding the Complexity of Network Management Protocols. 
 Near-Optimal Distributed Routing with Low Memory. 
 f-arrays: implementation and applications. 
 Verification of Multiprocess Probabilistic Protocols. 
 Self-stabilizing byzantine agreement. 
 Requirements for Deadlock-Free, Adaptive Packet Routing. 
 The Complexity of End-to-End Communication in Memoryless Networks. 
 Brief announcement: an incremental algorithm for calculation of backup-paths in link-state networks. 
 The Global Efficiency of Distributed, Rate-Based, Flow Control Algorithms. 
 Skip-webs: efficient distributed data structures for multi-dimensional data sets. 
 Efficient distributed approximation algorithms via probabilistic tree embeddings. 
 Resource Discovery in Distributed Networks. 
 Symmetry Breaking in the Plane: Rendezvous by Robots with Unknown Attributes. 
 Stable and scalable universal swarms. 
 Towards Efficient Verification of Population Protocols. 
 Fast Partial Distance Estimation and Applications. 
 Reliable Message Delivery and Conditionally-Fast Transactions are not Possible Without Accurate Clocks. 
 Sublinear Message Bounds for Randomized Agreement. 
 The HiMAT Model for Mobile Agent Applications. 
 An Inherent Bottleneck in Distributed Counting. 
 Distributed Algorithms for Sparse k-Connectivity Certificates (Abstract). 
 Using Mappings to Prove Timing Properties. 
 Resource Bounds and Combinations of Consensus Objects. 
 Fair Leader Election for Rational Agents in Asynchronous Rings and Networks. 
 Random oracles in constantipole: practical asynchronous Byzantine agreement using cryptography (extended abstract). 
 Real-Time Object Sharing with Minimal System Support (Extended Abstract). 
 Brief announcement: PUSH, a DISC shell. 
 Why Control of the Concurrency Level in Distributed Systems is More Fundamental Than Deadlock Management. 
 Wait-Freedom vs. t-Resiliency and the Robustness of Wait-Free Hierarchies. 
 Distributed Convex Thresholding. 
 Secure and Verifiable Schemes for Election and General Distributed Computing Problems. 
 Dynamic Sets for Search. 
 Session details: Session 2C: Security, Blockchains, and Replication. 
 Agreement is Harder than Consensus: Set Consensus Problems in Totally Asynchronous Systems. 
 Toward more localized local algorithms: removing assumptions concerning global knowledge. 
 The Power of Multimedia: Combining Point-to Point and Multi-Access Networks. 
 Toward a theory of transactional contention managers. 
 Brief Announcement: Labeling Schemes for Power-Law Graphs. 
 A Calculus for Concurrent Update (Abstract). 
 Adaptive routing with stale information. 
 A View-Based Approach to Relaxing Global Serializability in a Multidatabase System (Abstract). 
 Optmial plans for aggregation. 
 Breathe before speaking: efficient information dissemination despite noisy, limited and anonymous communication. 
 Tight Bounds for Weakly Bounded Protocols. 
 The complexity of updating multi-writer snapshot objects. 
 Routing complexity of faulty networks. 
 Simple, Deterministic, Constant-Round Coloring in the Congested Clique. 
 Asynchronous Approximate Agreement. 
 Avoiding The State Explosion Problem in Temporal Logic Model Checking. 
 The Role of Data-Race-Free Programs in Recoverable DSM (Abstract). 
 Spreading Rumors Rapidly Despite and Adversary. 
 Scalability versus semantics of concurrent FIFO queues. 
 Distributed error confinement. 
 A Formally Verifiable Algorithm for Clock Synchronization under a Hybrid Fault Model. 
 Message-Optimal Protocols for Byzantine Agreement (Extended Abstract). 
 Long-Lived Snapshots with Polylogarithmic Amortized Step Complexity. 
 Brief announcement: maintaining large dense subgraphs on dynamic networks. 
 Distributed Fingerprints and Secure Information Dispersal. 
 Validity bound of regular registers with churn and byzantine processes. 
 Robust, Distributed References and Acyclic Garbage Collection. 
 Average probe complexity in quorum systems. 
 Coalescing random walks and voting on graphs. 
 Constructing Two-Writer Atomic Registers. 
 Replicated Procedure Call. 
 Resilient Consensus Protocols. 
 Brief announcement: strong detection of misconfigurations. 
 Analyzing consistency properties for fun and profit. 
 Randomness vs. Fault-Tolerance. 
 Equational Reasoning About Nondeterministic Processes. 
 Memory Requirement for Universal Routing Schemes. 
 The Complexity of Data Mining on the Web (Abstract). 
 Brief announcement: a note on distributed stable matching. 
 Dynamic Systems and Their Distributed Termination. 
 Reconciliations. 
 Self-Stabilization of Dynamic Systems Assuming only Read/Write Atomicity. 
 The weakest failure detectors to solve certain fundamental problems in distributed computing. 
 A Distributed Learning Dynamics in Social Groups. 
 Synchronization Mechanisms for SCRAMNet+ Systems. 
 The Power of Multi-objects (Extended Abstract). 
 Brief announcement: distributed algorithms for throughput performance in wireless networks. 
 Brief announcement: detecting malicious routers. 
 Minor Excluded Network Families Admit Fast Distributed Algorithms. 
 Brief Announcement: Asynchronous Coordination with Constraints and Preferences. 
 Tight Bounds on the Cumulative Profit of Distributed Voters (Abstract). 
 Leveraging Multiple Channels in Ad Hoc Networks. 
 Lower Bounds for Searching Robots, some Faulty. 
 A Case for NOW (Networks of Workstations) - Abstract. 
 Distributed Priority Algorithms Under One-Bit-Delay Constraints. 
 2019 Principles of Distributed Computing Doctoral Dissertation Award. 
 Constant phase efficient protocols for secure message transmission in directed networks. 
 A Design of a Protocol for Detecting an Agent Clone in Mobile Agent Systems and its Correctness Proof. 
 Tradeoffs between stretch factor and load balancing ratio in routing on growth restricted graphs. 
 Asynchrony versus Bulk-Synchrony in QRQW PRAM model (Abstract). 
 Brief Announcement: Fast and Simple Node Coloring in the SINR Model. 
 Algebraic Spans (Preliminary Version). 
 Return of the primal-dual: distributed metric facilitylocation. 
 k-set agreement with limited accuracy failure detectors. 
 A Recoverable Mutex Algorithm with Sub-logarithmic RMR on Both CC and DSM. 
 Global Flush Communication Primitive for Inter-process Communication. 
 Peer-to-peer systems for prefix search. 
 Consensus in the Presence of Timing Uncertainty: Omission and Byzantine Failures (Extended Abstract). 
 Simulations and reductions for colorless tasks. 
 Brief announcement: evaluation of tree-based data gathering algorithms for wireless sensor networks. 
 Optimal Mobile Byzantine Fault Tolerant Distributed Storage: Extended Abstract. 
 Specifying Graceful Degradation in Distributed Systems. 
 On the complexity of universal leader election. 
 Contention in Counting Networks. 
 Distributed connectivity decomposition. 
 Communication in dynamic radio networks. 
 Lock-Free Linked Lists Using Compare-and-Swap. 
 Sublogarithmic distributed MIS algorithm for sparse graphs using nash-williams decomposition. 
 The Space Requirement of Local Forwarding on Acyclic Networks. 
 Silence. 
 Brief announcement: parameterized model checking of fault-tolerant distributed algorithms by abstraction. 
 OCD: obsessive consensus disorder (or repetitive consensus). 
 Towards a theory of transactional contention managers. 
 On FTSS-Solvable Distributed Problems. 
 Fast Distributed Construction of k-Dominating Sets and Applications. 
 Brief announcement: asynchronous bounded expected delay networks. 
 Improvements in the Time Complexity of Two Message-Optimal Election Algorithms. 
 Shared-Memory vs. Message-Passing in an Asynchronous Distributed Environment. 
 Fault-tolerant implementations of atomic registers by safe registers in networks. 
 Optimally work-competitive scheduling for cooperative computing with merging groups. 
 Muteness Detectors for Consensus with Byzantine Processes. 
 How fast can a distributed atomic read be? 
 Leader Election in Well-Connected Graphs. 
 The asynchronous bounded-cycle model. 
 The Ambiguity of Choosing. 
 Security and Privacy for Distributed Optimization & Distributed Machine Learning. 
 How much does randomness help with locally checkable problems? 
 Distributed algorithms for barrier coverage using relocatable sensors. 
 The RAPID C++ Environment (Abstract). 
 Near-Additive Spanners In Low Polynomial Deterministic CONGEST Time. 
 Disjoint-Access Parallelism: Impossibility, Possibility, and Cost of Transactional Memory Implementations. 
 Efficient atomic broadcast using deterministic merge. 
 Crash Failures can Drive Protocols to Arbitrary States. 
 Brief announcement: optimization based rate allocation for application layer multicast. 
 Crumbling Walls: A Class of Practical and Efficient Quorum Systems (Extended Abstract). 
 Scheduling Loop-free Network Updates: It's Good to Relax! 
 A New Look at Membership Services (Extended Abstract). 
 Efficient Asynchronous Consensus with the Weak Adversary Scheduler. 
 Scheduling tasks with dependencies on asymmetric multiprocessors. 
 Sequentially Consistent versus Linearizable Counting Networks. 
 Differential Privacy and Byzantine Resilience in SGD: Do They Add Up? 
 Brief announcement: an overview of the content-addressable network D2B. 
 Reaching Consensus for Asynchronous Distributed Key Generation. 
 Approximate Byzantine Fault-Tolerance in Distributed Optimization. 
 Brief Announcement: Investigating the Cost of Anonymity on Dynamic Networks. 
 Signature-free asynchronous byzantine consensus with t 2<n/3 and o(n 
 Improved Distributed Expander Decomposition and Nearly Optimal Triangle Enumeration. 
 Fundamental Challenges in Mobile Computing. 
 On the internet delay space dimensionality. 
 Greedy Packet Scheduling on Shortest Paths (Preliminary Version). 
 Brief announcement: optimal self-stabilizing multi-token ring: a randomized solution. 
 Optimal Memory-Anonymous Symmetric Deadlock-Free Mutual Exclusion. 
 Randomized mutual exclusion in O(log N / log log N) RMRs. 
 Probabilistic Solitude Verification on a Ring. 
 Efficient Evaluation of Causality Relations Between Nonatomic Events. 
 Single-scanner multi-writer snapshot implementations are fast! 
 Brief announcement: locally-accessible implementations for distributed shared memory multiprocessors. 
 Brief Announcement: Why Extension-Based Proofs Fail. 
 Time- and Space-Efficient Randomized Consensus. 
 Quorum Consensus in Nested Transaction Systems. 
 Breaking the  
 The Cost of Messages. 
 Brief announcement: cluster-based control mechanism for communication networks. 
 With Great Speed Come Small Buffers: Space-Bandwidth Tradeoffs for Routing. 
 Specifying Message Passing Systems Requires Extending Temporal Logic. 
 Delay-Optimal Quorum-Based Mutual Exclusion for Distributed Systems. 
 Brief Announcement: Variants of Approximate Agreement on Graphs and Simplicial Complexes. 
 A Compositional Verification Method for LOTOS. 
 On asymmetric progress conditions. 
 Automatic Verification of Liveness Properties of Randomized Systems. 
 Session details: Session 2A: Approximation and Learning. 
 Lower Bounds for Convergence Function Based Clock Synchronization. 
 Site Optimal Termination Protocols for a Distributed Database under Network Partitioning. 
 Brief Announcement: Not a COINcidence: Sub-Quadratic Asynchronous Byzantine Agreement WHP. 
 Distributed reconfigurtion of metamorphic robot chains. 
 Optimizing consistency checking for memory-intensive transactions. 
 Discrete load balancing is (almost) as easy as continuous load balancing. 
 Constant-RMR implementations of CAS and other synchronization primitives using read and write operations. 
 On-the-fly Deadlock Prevention. 
 Correctness of a gossip based membership protocol. 
 Brief Announcement: Routing the Internet with Very Few Entries. 
 ENF Event Predicate Detection in Distributed Systems. 
 A Knowledge-Theoretic Analysis of Uniform Distributed Coordination and Failure Detectors. 
 A Gap Theorem for Consensus Types (Extended Abstract). 
 Scalable rational secret sharing. 
 The topology of shared-memory adversaries. 
 Can quantum communication speed up distributed computation? 
 Brief announcement: collaborative measurement of upload speeds in P2P systems. 
 Incentive-compatible distributed greedy protocols. 
 Distributed Match-Making for Processes in Computer Networks (Preliminary Version). 
 Strong-Feasibilities of Equivalence-Completions. 
 A New Way to Achieve Round-Efficient Byzantine Agreement. 
 Deterministic (Δ + 1)-Coloring in Sublinear (in Δ) Time in Static, Dynamic and Faulty Networks. 
 On the Push&Pull Protocol for Rumour Spreading: [Extended Abstract]. 
 Unreliable Failure Detectors for Asynchronous Systems (Preliminary Version). 
 Brief announcement: randomized rumor spreading with fewer phone calls. 
 Perigee: Efficient Peer-to-Peer Network Design for Blockchains. 
 Fast distributed random walks. 
 Synchronous byzantine agreement with nearly a cubic number of communication bits: synchronous byzantine agreement with nearly a cubic number of communication bits. 
 Mixed Consistency: A Model for Parallel Programming (Extended Abstract). 
 On Register Linearizability and Termination. 
 Reconstructing approximate tree metrics. 
 Brief Announcement: A Key-Value Map for Massive Real-Time Analytics. 
 Brief announcement: linear time byzantine self-stabilizing clock synchronization. 
 The do-all problem in broadcast networks. 
 Brief announcement: the design of an internet-scale monitoring and assertion-checking infrastructure. 
 Correction: practical implementations of non-blocking synchronization primitives. 
 Integrated resource allocation in heterogeneous SAN data centers. 
 Source to Destination Communication in the Presence of Faults. 
 Faster information dissemination in dynamic networks via network coding. 
 Asynchronous Shared Channel. 
 Erasure Coding in Object Stores: Challenges and Opportunities. 
 Dynamic Wavelength Allocation in Optical Networks. 
 Connectivity and aggregation in multihop wireless networks. 
 The Possibility and the Complexity of Achieving Fault-Tolerant Coordination. 
 Distributed selfish load balancing with weights and speeds. 
 More on t-Resilience vs. Wait-Freedom (Extended Abstract). 
 Improved Distributed Lower Bounds for MIS and Bounded (Out-)Degree Dominating Sets in Trees. 
 Brief announcement: actions in the twilight - concurrent irrevocable transactions and inconsistency repair. 
 On the hardness and approximation of minimum topic-connected overlay. 
 Brief announcement: modelling MapReduce for optimal execution in the cloud. 
 Component Stability in Low-Space Massively Parallel Computation. 
 Lower bounds for adaptive collect and related objects. 
 Testing Incomplete Specifications of Distributed Systems. 
 The wireless synchronization problem. 
 Method for Distributed Transaction Commit and recovery Using Byzantine Agreement Within Clusters of Processors. 
 Brief announcement: concurrent counting is harder than queuing. 
 All You Need is DAG. 
 On beyond registers: wait-free readable objects. 
 Terminating Iterative Solution of Simultaneous Equations in Distributed Message Passing Systems. 
 The LATEX legacy: 2.09 and all that. 
 Atomic Snapshots in O(n log n) Operations (Preliminary Version). 
 The inherent price of indulgence. 
 A Model and Proof System for Asynchronous Networks. 
 Beyond set disjointness: the communication complexity of finding the intersection. 
 Sharing Memory Robustly in Message-Passing Systems. 
 Brief announcement: how to speed-up fault-tolerant clock generation in VLSI systems-on-chip via pipelining. 
 Reliable broadcast in unknown fixed-identity networks. 
 DoS-resilient secure aggregation queries in sensor networks. 
 Routing without regret: on convergence to nash equilibria of regret-minimizing algorithms in routing games. 
 A Logarithmic Depth Counting Network (Abstract). 
 Fault-Local Distributed Mending (Extended Abstract). 
 Grouped distributed queues: distributed queue, proportional share multiprocessor scheduling. 
 Refined quorum systems. 
 The Benefits of Relaxing Punctuality. 
 The Slide Mechanism with Applications in Dynamic Networks (Extended Abstract). 
 Bandwidth constrained placement in a WAN. 
 An Error Control Scheme for Large-Scale Multicast Applications. 
 Sticky Bits and Universality of Consensus. 
 Algebraic Methods in the Congested Clique. 
 Multi-Party Computations: Past and Present. 
 Distributed multi-broadcast in unknown radio networks. 
 A Hundred Impossibility Proofs for Distributed Computing. 
 Simple, Fast, and Practical Non-Blocking and Blocking Concurrent Queue Algorithms. 
 Distributed MST and Routing in Almost Mixing Time. 
 Fast, Scalable Synchronization with Minimal Hardware Support (Extended Abstract). 
 Strongly Linearizable Implementations of Snapshots and Other Types. 
 CoLa: A Coordination Language for Massive Parallelism. 
 Elle: Finding Isolation Violations in Real-World Databases. 
 Brief announcement: anonymity and trust in distributed systems. 
 A Refinement of Kahn's Semantic to Handle Non-Determinism and Communication (Extended Abstract). 
 The lotus-eater attack. 
 Resilience of General Interactive Tasks. 
 A tight unconditional lower bound on distributed randomwalk computation. 
 Fail Termination of Communicating Processe. 
 Revisiting Asynchronous Fault Tolerant Computation with Optimal Resilience. 
 Performing work with asynchronous processors: message-delay-sensitive bounds. 
 A Local Constant Factor MDS Approximation for Bounded Genus Graphs. 
 Sharing is harder than agreeing. 
 Efficient Synchronization on Multiprocessors with Shared Memory. 
 Optimal distributed all pairs shortest paths and applications. 
 Efficient reliable communication over partially authenticated networks. 
 On the Value of Information in Distributed Decision-Making (Extended Abstract). 
 Randomized Abortable Mutual Exclusion with Constant Amortized RMR Complexity on the CC Model. 
 Finding mobile data under delay constraints with searching costs. 
 Brief Announcement: Population Protocols Are Fast. 
 When graph theory helps self-stabilization. 
 Automated Logical Verification Based on Trace Abstractions. 
 Brief Announcement: constraint-based synchronization and verification of concurrent programs. 
 Distributed social systems. 
 Reconsidering Fragmentation and Reassembly. 
 Brief announcement: delay or deliver dilemma in organization networks. 
 Towards a Minimal Object-Oriented Language for Distributed and Concurrent Programming. 
 Fine-grained Analysis on Fast Implementations of Distributed Multi-writer Atomic Registers. 
 Using Events Structures for the Efficient Analysis of State Graphs (Abstract). 
 Brief announcement: towards robust medium access in multi-hop networks. 
 On the design of distributed protocols from differential equations. 
 Brief announcement: deterministic self-stabilizing leader election with O(log log n)-bits. 
 Brief Announcement: Hierarchical Consensus. 
 Brief announcement: distributed contention resolution in wireless networks. 
 Detection of Stable Properties in Distributed Applications. 
 Maximal independent sets in radio networks. 
 An Adaptive Totally Ordered Multicast Protocol That Tolerates Partitions. 
 SuperTrust: a secure and efficient framework for handling trust in super-peer networks. 
 A Framework for Protocol Composition in Horus. 
 Optimal Efficiency of Optimistic Contract Signing. 
 The life and times of a zookeeper. 
 Rational and resilient protocols. 
 Analysis of a cone-based distributed topology control algorithm for wireless multi-hop networks. 
 An Algorithm for Replicated Directories. 
 Balls-into-leaves: sub-logarithmic renaming in synchronous message-passing systems. 
 Brief announcement: Trilix: a scalable unstructured lookup system for dynamic environments. 
 Lower bound for scalable Byzantine Agreement. 
 Xheal: localized self-healing using expanders. 
 Quantum distributed consensus. 
 Brief announcement: a platform for experimenting with mobile algorithms in a laboratory. 
 Towards a formal model for view maintenance in data warehouses. 
 Constant-time distributed dominating set approximation. 
 Safety-Liveness Exclusion in Distributed Computing. 
 Veracity radius: capturing the locality of distributed computations. 
 Round- and Message-Optimal Distributed Graph Algorithms. 
 Optimal implementation of the weakest failure detector for solving consensus (brief announcement). 
 Brief announcement: virtual mobile nodes for mobile ad hoc networks. 
 Self-stabilizing Balls & Bins in Batches: The Power of Leaky Bins [Extended Abstract]. 
 Multiple Source Replacement Path Problem. 
 Optimal Clock Synchronization. 
 Fault Tolerant Distributed Services. 
 On the Space Complexity of Randomized Synchronization. 
 Freedom from Deadlock of Locked Transactions in a Distributed Database. 
 Recoverable Mutual Exclusion with Constant Amortized RMR Complexity from Standard Primitives. 
 Terminating Distributed Construction of Shapes and Patterns in a Fair Solution of Automata. 
 Reductions and Extension-Based Proofs. 
 Distributed House-Hunting in Ant Colonies. 
 Brief announcement: efficient causality tracking in distributed storage systems with dotted version vectors. 
 Tracking distributed aggregates over time-based sliding windows. 
 The Complexity of Crash Failures. 
 Average-case analysis of greedy packet scheduling (extended astract). 
 Brief announcement: self-assembly as graph grammar as distributed system. 
 Nearly-Tight Analysis for 2-Choice and 3-Majority Consensus Dynamics. 
 Reliable Communication over Highly Connected Noisy Networks. 
 Efficient Parallel Algorithms Can Be Made Robust. 
 A Faster Distributed Algorithm for Computing Maximal Matchings Deterministically. 
 Barriers due to Congestion and Two Ways to Deal With Them. 
 Laziness pays! using lazy synchronization mechanisms to improve non-blocking constructions. 
 Brief announcement: revisiting the power-law degree distribution for social graph analysis. 
 Simple and efficient asynchronous byzantine agreement with optimal resilience. 
 Responsiveness and Consistency Tradoffs in Interactive Groupware. 
 Adversarial queuing on the multiple-access channel. 
 Probabilistic Quorum Systems. 
 A Perspective on AN2: Local Area Network as Distributed System. 
 What Can be Sampled Locally? 
 Fault-Tolerant Decision Making in Totally Asynchronous Distributed Systems (Preliminary Version). 
 Feasibility and complexity of broadcasting with random transmission failures. 
 Stateless Computation. 
 Decentralization of Process Nets with Centralized Control. 
 Efficient Traffic Laws for Mobile Robots - Work in Progress (Avstract). 
 Exact Byzantine Consensus on Undirected Graphs under Local Broadcast Model. 
 The   Mobile Adversary   Paradigm in Distributed Computation and Systems. 
 Brief announcement: routing with obstacle avoidance mechanism with constant approximation ratio. 
 Brief announcement: almost-tight approximation distributed algorithm for minimum cut. 
 Brief announcement: controlled quorum selection in arbitrary topologies. 
 How Processes Learn. 
 1/k phase stamping for continuous shared data (extended abstract). 
 Synchrony weakened by message adversaries vs asynchrony restricted by failure detectors. 
 The space complexity of long-lived and one-shot timestamp implementations. 
 Asynchronous Time-Adaptive Self Stabilization. 
 Immediate Atomic Snapshots and Fast Renaming (Extended Abstract). 
 Cortical Computation. 
 A Lattice-Structured Proof of a Minimum Spanning. 
 Faster Computation On Directed Networks of Automata (Extended Abstract). 
 From Serializable to Causal Transactions (Abstract). 
 Making evildoers pay: resource-competitive broadcast in sensor networks. 
 Knowledge, Probability, and Adversaries. 
 Compact routing with slack. 
 An Omega ( 
 Operation-valency and the cost of coordination. 
 Failure Detectors in Omission Failure Environments. 
 Real Time Resource Allocation in Distributed Systems. 
 The Weakest Failure Detector for Eventual Consistency. 
 Competitive Dynamic Bandwidth Allocation. 
 Symmetry and Similarity in Distributed Systems. 
 How Asynchrony Affects Rumor Spreading Time. 
 Brief announcement: superpeer formation amidst churn and rewiring. 
 An Algorithm for Message Delivery to Mobile Units. 
 Delimiting the Power of Bounded Size Synchronization Objects (Extended Abstract). 
 Weak models of distributed computing, with connections to modal logic. 
 Failure detectors in loosely named systems. 
 On Distributed Object Checkpointing and Recovery. 
 Verifying distributed erasure-coded data. 
 Indulgent algorithms (preliminary version). 
 Collaborative enforcement of firewall policies in virtual private networks. 
 Determining Consensus Numbers. 
 PCODE: Efficient Parallel Computing over Distributed Environments. 
 End-to-End Communication in Unreliable Networks. 
 On the generalized dining philosophers problem. 
 Brief announcement: order-preserving renaming in synchronous message passing systems with byzantine faults. 
 Universal Constructions for Multi-Object Operations. 
 The Protocol for Hierarchical Multicast Routing. 
 Tight bounds for clock synchronization. 
 Consensus Power Makes (Some) Sense! (Extended Abstract). 
 Implementing atomic objects in a dynamic environment. 
 Consistency and Conformance in ODP (Abstract). 
 A programming language perspective on transactional memory consistency. 
 Fast flooding over Manhattan. 
 Understanding and Using Asynchronous Message Passing (Preliminary Version). 
 A Direct Lower Bound for  
 Asynchronous Byzantine Approximate Consensus in Directed Networks. 
 Brief Announcement: Symmetry Breaking in the CONGEST Model: Time- and Message-Efficient Algorithms for Ruling Sets. 
 Brief announcement: efficient perfectly secure communication over synchronous networks. 
 Fast Isolation of Arbitrary Forwarding Faults. 
 An optimally robust hybrid mix network. 
 Simulation as an Iterated Task (Abstract). 
 Time and message-efficient S-based consensus (brief announcement). 
 Fault-tolerant routing in peer-to-peer systems. 
 An asynchronous protocol for distributed computation of RSA inverses and its applications. 
 Adapting to a reliable network path. 
 Persistent Messages in Local Transactions. 
 Wait-free programming for general purpose computations on graphics processors. 
 Towards a Theory of Randomized Shared Memory Algorithms. 
 Dynamic Load Sharing Algorithm with a Weighted Load Representation. 
 Brief Announcement: Detectable Sequential Specifications for Recoverable Shared Objects. 
 Long-Lived Renaming Made Fast. 
 Lexicographically optimal smoothing for broadband traffic multiplexing. 
 Brief announcement: a method for exchanging valuable data: how to realize matching oblivious transfer. 
 Distributed approximate matching. 
 Recoverable Mutual Exclusion Under System-Wide Failures. 
 Establishing wireless conference calls under delay constraints. 
 On the establishment of distinct identities in overlay networks. 
 Inconsistency and Contamination (Preliminary Version). 
 Internet Support for Wireless and Mobile Networking. 
 Comparison of Initial Conditions for Distributed Algorithms on Anonymous Networks. 
 A Tight Lower Bound for Randomized Synchronous Consensus. 
 Information dissemination on multiple channels. 
 Strongly linearizable implementations: possibilities and impossibilities. 
 BMobi_Causal: a causal broadcast protocol in mobile dynamic groups. 
 Brief Announcement: Multi-Broadcasting under the SINR Model. 
 The impact of randomization in smoothing networks. 
 3-Processor Tasks Are Undecidable (Abstract). 
 Brief announcement: there are plenty of tasks weaker than perfect renaming and stronger than set agreement. 
 From causal to z-linearizable transactional memory. 
 How to Find It: Research Issues in Distributed Search (Abstract). 
 On deterministic abortable objects. 
 Greedy Routing and the Algorithmic Small-World Phenomenon. 
 Computing on an Anonymous Ring. 
 Optimal Space Distributed Move-to-Front Lists. 
 Simplified VSS and Fast-Track Multiparty Computations with Applications to Threshold Cryptography. 
 Fast Distributed Agreement (Preliminary Version). 
 Packet mode and QoS algorithms for buffered crossbar switches with FIFO queuing. 
 Theory of BAR games. 
 Rigorous system design. 
 Synchronous Byzantine Quorum Systems. 
 Brief announcement: a framework for building self-stabilizing overlay networks. 
 A (Truly) Local Broadcast Layer for Unreliable Radio Networks. 
 A distributed polylogarithmic time algorithm for self-stabilizing skip graphs. 
 Bootstrapping Public Blockchains Without a Trusted Setup. 
 On the effect of the deployment setting on broadcasting in Euclidean radio networks. 
 Reliability and performance of hierarchical RAID with multiple controllers. 
 Brief announcement: leader election vs pattern formation. 
 Specification and Complexity of Collaborative Text Editing. 
 Issues in Distributed Control for ATM Networks. 
 Ant-Inspired Density Estimation via Random Walks: Extended Abstract. 
 Forbidden-set distance labels for graphs of bounded doubling dimension. 
 Session details: Session 3D: Graphs and Population. 
 Brief announcement: degree: optimal deterministic routing for P2P systems. 
 The Virtual Path Layout Problem in Fast Networks (Extended Abstract). 
 Almost-Surely Terminating Asynchronous Byzantine Agreement Revisited. 
 Minimal Synchrony for Byzantine Consensus. 
 Early-deciding consensus is expensive. 
 Improved compact routing schemes for dynamic trees. 
 Baked Potatoes: Deadlock Prevention Via Scheduling (Abstract). 
 Brief Announcement: Object Oriented Consensus. 
 Contention Resolution on Multiple Channels with Collision Detection. 
 Brief announcement: perfectly secure message transmission in directed networks re-visited. 
 Easy impossibility proofs for k-set agreement in message passing systems. 
 Brief Announcement: Deterministic Lower Bound for Dynamic Balanced Graph Partitioning. 
 The SkipTrie: low-depth concurrent search without rebalancing. 
 Lower Bounds for Distributed Sketching of Maximal Matchings and Maximal Independent Sets. 
 On the Complexity of Distributed Splitting Problems. 
 The Impact of Time on the Session Problem. 
 Securing the Internet (Abstract). 
 Deriving Optimal Checkpoint Protocols for Distributed Shared Memory Architectures (Abstract). 
 Early-Delivery Atomic Broadcast. 
 Brief Announcement: Persistent Multi-Word Compare-and-Swap. 
 The Consensus Number of a Cryptocurrency. 
 Performing Work Efficiently in the Presence of Faults. 
 Distributed verification of minimum spanning trees. 
 Solved Problems, Unsolved Problems and Non-Problems in Concurrency (Invited Address). 
 Robust Efficient Distributed RSA-Key Generation. 
 Probabilistic Byzantine Quorum Systems. 
 Data-dependent Concurrency Control and Recovery (Extended Abstract). 
 Athena lecture: distributed computing theory for wireless networks and mobile systems. 
 Wait-Free Solvability Via Combinatorial Topology (Abstract). 
 On the weakest failure detector ever. 
 On fault tolerance and wireless networks. 
 Lazy-Writer Multivalued Registers (Abstract). 
 Brief announcement: distributed trust management and revocation. 
 The Load and Availability of Byzantine Quorum Systems. 
 Adaptively secure broadcast, revisited. 
 Dynamic-sized nonblocking hash tables. 
 Rapid randomized pruning for fast greedy distributed algorithms. 
 Brief Announcement: Automatic Log Enhancement for Fault Diagnosis. 
 HotStuff: BFT Consensus with Linearity and Responsiveness. 
 Stably computable predicates are semilinear. 
 Distributed computing building blocks for rational agents. 
 Tight RMR lower bounds for mutual exclusion and other problems. 
 The Formal Verification of an ATM Network. 
 The Offset Problem (Abstract). 
 A Local Broadcast Layer for the SINR Network Model. 
 2018 Edsger W. Dijkstra Prize in Distributed Computing. 
 Hedging Against Sore Loser Attacks in Cross-Chain Transactions. 
 A Multi-Version Concurrency Scheme With No Rollbacks. 
 Graceful degradation via versions: specifications and implementations. 
 Brief announcement: network-destabilizing attacks. 
 Oblivious signature-based envelope. 
 A note on efficient aggregate queries in sensor networks. 
 Anonymous and censorship resistant content sharing in unstructured overlays. 
 Polylog Randomized Wait-Free Consensus. 
 Deterministic Digital Clustering of Wireless Ad Hoc Networks. 
 Efficient pipeline configuration in distributed heterogeneous computing environments. 
 Brief announcement: global consistency can be easier than point-to-point communication. 
 Optimal and Efficient Clock Synchronization Under Drifting Clocks. 
 A middleware overview: goals, research issues, and coded examples (abstract). 
 Resource Bounds for Self Stabilizing Message Driven Protocols. 
 A near-optimal distributed fully dynamic algorithm for maintaining sparse spanners. 
 Dynamically Configurable Distributed Objects. 
 Sublinear-Time Quantum Computation of the Diameter in CONGEST Networks. 
 Highly-Available Distributed Service and Fault-Tolerant Distributed Garbage Collection. 
 Multiparty Interactive Communication with Private Channels. 
 A Combinatorial Characterization of the Distributed Tasks Which Are Solvable in the Presence of One Faulty Processor. 
 A Distributed Algorithm for Deadlock Detection and Resolution. 
 Toward a theory of maximally concurrent programs (shortened version). 
 Reconfigurable Atomic Transaction Commit. 
 Wait-Free Clock Synchronization (Extended Abstract). 
 Optimal scheduling for disconnected cooperation. 
 Cheating Husbands and Other Stories: A Case Study of Knowledge, Action, and Communication (Preliminary Version). 
 Fault-Tolerant Labeling and Compact Routing Schemes. 
 Brief announcement: the overlay network content distribution problem. 
 Using Process Groups to Implement Failure Detection in Asynchronous Environments. 
 Asynchronous Secure Computations with Optimal Resilience (Extended Abstract). 
 Characterization of Message Ordering Specifications and Protocols (Abstract). 
 A Polylogarithmic Gossip Algorithm for Plurality Consensus. 
 Brief announcement: non-self-stabilizing and self-stabilizing gathering in networks of mobile agents--the notion of speed. 
 Brief announcement: pan and scan. 
 Achieving Independence in Logarithmic Number of Rounds. 
 A scheme for load balancing in heterogenous distributed hash tables. 
 Brief announcement: stretch between nearby peers. 
 Adaptive and efficient mutual exclusion (extended abstract). 
 Searching for a black hole in arbitrary networks: optimal mobile agent protocols. 
 Brief announcement: name-independent compact routing in trees. 
 Countering web spam with credibility-based link analysis. 
 A simple approach for adapting continuous load balancing processes to discrete settings. 
 Tight Trade-off in Contention Resolution without Collision Detection. 
 Assigning labels in unknown anonymous networks (extended abstract). 
 Brief announcement: prefix hash tree. 
 The Impact of RDMA on Agreement. 
 Optimal Clock Synchronization under Different Delay Assumptions (Preliminary Version). 
 On the Relevance of Communication Costs of Rollback-Recovery Protocols. 
 Brief announcement: a single enqueuer wait-free queue implementation. 
 The  
 Anonymous networks: randomization = 2-hop coloring. 
 Layering Data Structures over Skip Graphs for Increased NUMA Locality. 
 On a network creation game. 
 The future(s) of shared data structures. 
 Information spreading in dynamic graphs. 
 Distributed network monitoring and multicommodity flows: a primal-dual approach. 
 How to meet asynchronously at polynomial cost. 
 Asynchronous interactive consistency and its relation with error-correcting codes. 
 Dynamic Voting for Consistent Primary Components. 
 Fault-Containing Self-Stabilizing Algorithms. 
 Brief announcement: minimal system conditions to implement unreliable failure detectors. 
 A Brief Tutorial on Distributed and Concurrent Machine Learning. 
 Compact Routing Schemes with Low Stretch Factor (Extended Abstract). 
 Session details: Session 2D: Consensus. 
 Time bounds for shared objects in partially synchronous systems. 
 Brief Announcement: A Probabilistic Performance Model and Tuning Framework for Eventually Consistent Distributed Storage Systems. 
 High Performance Clusters: State of the Art and Challenges Ahead. 
 Designing a Global Name Service. 
 Brief announcement: efficient optimally resilient statistical AVSS and its applications. 
 Stateless distributed algorithms for near optimal maximum multicommodity flows. 
 Using Broadcast Primitives in Replicated Databases (Abstract). 
 Optimal Coteries. 
 Fast fault-tolerant agreement algorithms. 
 The Topology of Randomized Symmetry-Breaking Distributed Computing. 
 Brief Announcement: On the Significance of Consecutive Ballots in Paxos. 
 Quorum placement in networks: minimizing network congestion. 
 Time-decaying sketches for sensor data aggregation. 
 Brief announcement: distributed cryptography using trinc. 
 An almost-surely terminating polynomial protocol forasynchronous byzantine agreement with optimal resilience. 
 Brief announcement: deterministic dominating set construction in networks with bounded degree. 
 Brief Announcement: Be Prepared When Network Goes Bad: An Asynchronous View-Change Protocol. 
 Passing Messages while Sharing Memory. 
 Brief announcement: an obstacle to scalability in wireless networks. 
 Brief announcement: monotonic stabilization. 
 Distributed data classification in sensor networks. 
 Brief announcement: increasing the power of the iterated immediate snapshot model with failure detectors. 
 A formal treatment of an abstract channel implementation using java sockets and TCP. 
 Compact routing on euclidian metrics. 
 Brief announcement: complexity and solution of the send-receive correlation problem. 
 Controlling Real-Time Asynchronous Tasks with ESTEREL Synchronous Language. 
 Repeatable and Portable Message-Passing Programs. 
 Brief Announcement: Almost-surely Terminating Asynchronous Byzantine Agreement Protocols with a Constant Expected Running Time. 
 On the Potential of FEC Algorithms in Building Fault-Tolerant Distributed Applications to Support High QoS Video Communications. 
 Distributed computation of the mode. 
 Constructing fair-exchange protocols for E-commerce via distributed computation of RSA signatures. 
 An  
 Looking for the optimal conditions for solving set agreement. 
 On the power of hardware transactional memory to simplify memory management. 
 Distributed cooperation in the absence of communication (brief announcement). 
 Computing on an Anonymous Network. 
 Topological Characterization of Consensus under General Message Adversaries. 
 Can We Break Symmetry with o(m) Communication? 
 Extending the Wait-free Hierarchy to Multi-Threaded Systems. 
 Simple and efficient local codes for distributed stable network construction. 
 Completely fair SFE and coalition-safe cheap talk. 
 Brief Announcement: The Only Undoable CRDTs are Counters. 
 The Energy Complexity of BFS in Radio Networks. 
 Dynamic and self-stabilizing distributed matching. 
 Connection-Based Communication in Dynamic Networks (Extended Abstract). 
 Brief announcement: an incentive-compatible capacity assignment algorithm for bulk data distribution using P2P. 
 Brief Announcement: A Hierarchy of Congested Clique Models, from Broadcast to Unicast. 
 Fischer's cryptographic protocols. 
 Memory Space Requirements for Self-Stabilizing Leader Election Protocols. 
 Nearest-neighbor graphs on random point sets and their applications to sensor networks. 
 Correctness criteria for replicated database systems with snapshot isolation replicas. 
 The Coalescing-Branching Random Walk on Expanders and the Dual Epidemic Process. 
 On tradeoff between network connectivity, phase complexity and communication complexity of reliable communication tolerating mixed adversary. 
 Self-organized Segregation on the Grid. 
 Principles of untrusted storage: a new look at consistency conditions. 
 Some Sequential Algorithms are Almost Always Parallel. 
 From anarchy to geometric structuring: the power of virtual coordinates. 
 Self-stabilization - beyond the token ring circulation (brief announcement). 
 How To Elect a Leader Faster than a Tournament. 
 Brief announcement: achieving reliability in master-worker computing via evolutionary dynamics. 
 A topological characterization of weakness. 
 Brief announcement: exactly electing a unique leader is not harder than computing symmetric functions on anonymous quantum networks. 
 Language Constructs and Support Systems for Distributed Computing. 
 Controller and estimator for dynamic networks. 
 Self-Stabilization by Window Washing. 
 Brief Announcement: Specification and Implementation of Replicated List: The Jupiter Protocol Revisited. 
 Optimal Distributed Algorithm for Minimum Spanning Trees Revisited. 
 The Failure and Recovery Problem for Replicated Databases. 
 Maintaining the Time in a Distributed System. 
 Online and dynamic embeddings of approximate ultrametrics. 
 Minimal replication cost for availability. 
 Analysing Snapshot Isolation. 
 Probabilistic Self-Stabilizing Mutual Exclusion in Uniform Rings. 
 Composable Computation in Discrete Chemical Reaction Networks. 
 On the Complexity of Global Computation in the Presence of Link Failures: The Case of Uni-Directional Faults. 
 What Processes Know: Definitions and Proof Methods (Preliminary Version). 
 Network synchronization and localization based on stolen signals. 
 Non-blocking binary search trees. 
 Time versus cost tradeoffs for deterministic rendezvous in networks. 
 An improved lower bound for the time complexity of mutual exclusion. 
 A Predicate Transformer Approach to Semantics of Parallel Programs. 
 P-SLOCAL-Completeness of Maximum Independent Set Approximation. 
 Brief Announcement: Sublinear-Space Distance Labeling Using Hubs. 
 A theory of goal-oriented communication. 
 Brief Announcement: Using Read-k Inequalities to Analyze a Distributed MIS Algorithm. 
 In-Place Reconstruction of Delta Compressed Files. 
 On the computational power of oblivious robots: forming a series of geometric patterns. 
 Knowledge-Based Programs. 
 Observing Self-Stabilization. 
 Implementing and Evaluating an Eventualy-Serializable Data Service. 
 Data Link Layer: Two Impossibility Results. 
 Brief announcement: distributed algorithmic mechanism design for scheduling. 
 Greedy distributed optimization of multi-commodity flows. 
 On the Locality of Nash-Williams Forest Decomposition and Star-Forest Decomposition. 
 On the (limited) power of non-equivocation. 
 Stochastic analysis of distributed deadlock scheduling. 
 The Inherent Cost of Nonblocking Commitment. 
 Brief Announcement: Efficient Self-Stabilizing 1-Maximal Matching Algorithm for Arbitrary Networks. 
 Life is not a state-machine: the long road from research to production. 
 Scheduling in Client-Server Systems (Abstract). 
 Computing Vector Functions on Anonymous Networks. 
 Secure data structures based on multi-party computation. 
 A Markov Chain Algorithm for Compression in Self-Organizing Particle Systems. 
 Deterministic distributed resource discovery (brief announcement). 
 Improved sparse covers for graphs excluding a fixed minor. 
 Brief announcement: concurrency-aware linearizability. 
 Constructing Multi-reader Atomic Values From Non-atomic Values. 
 Substituting for Real Time and Common Knowledge in Asynchronous Distributed Systems. 
 Timeliness, failure-detectors, and consensus performance. 
 Fault-Tolerant Multi-Agent Optimization: Optimal Iterative Distributed Algorithms. 
 Optimal Reactive  
 Brief announcement: on L-resilience, hitting sets, and colorless tasks. 
 The impact of memory models on software reliability in multiprocessors. 
 Selfish caching in distributed systems: a game-theoretic analysis. 
 On the liveness of transactional memory. 
 Adaptive Video on Demand. 
 Brief announcement: RT oblivious erasure correcting. 
 Efficient lock-free binary search trees. 
 Adaptive Solutions to the Mutual Exclusion Problem (Extended Abstract). 
 Update Propagation in Bakunin Data Networks. 
 Achieving Independence Efficiently and Securely. 
 Sharpness, a tight condition for throughput scalability. 
 Brief announcement: virtual world consistency: a new condition for STM systems. 
 Session details: Session 1B: Shared Memory Theory. 
 Evolution of distributed computing theory: from concurrency to networks and beyond. 
 On a selfish caching game. 
 Brief announcement: lightweight key agreement and digital certificates for wireless sensor networks. 
 Compact routing for average-case networks. 
 A note on group mutual exclusion. 
 Byzantine Agreement and Leader Election: From Classical to the Modern. 
 Efficient multi-word locking using randomization. 
 Algorithmic Problems in Internet Research. 
 Universal Service-Providers for Database Private Information Retrieval (Extended Abstract). 
 Brief announcement: veto number and the respective power of eventual failure detectors. 
 Interaction-Oriented Programming: An Application Semantics Approach for Engineering Decentralized Applications. 
 Scalable and dynamic quorum systems. 
 From Sequential Layers to Distributed Processes: Deriving a Distributed Minimum Weight Spanning Tree Algorithm (Extended Anstract). 
 Unifying Synchronous and Asynchronous Message-Passing Models. 
 Refining Knowledge Oriented Actions to Layered Implementations. 
 Relaxed Schedulers Can Efficiently Parallelize Iterative Algorithms. 
 Prediction Based Task Scheduling in Distributed Computing (Abstract). 
 Software Transactional Memory. 
 Using CSP to Derive a Sequentially Consistent DSM System. 
 Broadcast in radio networks tolerating byzantine adversarial behavior. 
 Max registers, counters, and monotone circuits. 
 Brief Announcement: Fence Insertion for Straight-line Programs is in P. 
 A Concurrency Control Theory for Nested Transactions. 
 Practical private computation of vector addition-based functions. 
 Balanced binary trees for ID management and load balance in distributed hash tables. 
 Optimal Message Routing without Complete Routing Tables (preliminary version). 
 Massively Parallel Algorithms for Minimum Cut. 
 A Lower Bound on Wait-Free Counting. 
 The inherent difficulty of timely primary-backup replication. 
 Towards a Universal Directory Service. 
 Cassandra: structured storage system on a P2P network. 
 Sparse communication networks and efficient routing in the plane (extended abstract). 
 Property Testing of Planarity in the CONGEST model. 
 Compact roundtrip routing in directed networks (extended abstract). 
 Distributed Variable Server for Atomic Unification. 
 Broadcasting in udg radio networks with unknown topology. 
 Brief announcement: on the possibility and the impossibility of message-driven self-stabilizing failure detection. 
 Tight bounds on information dissemination in sparse mobile networks. 
 Policy-hiding access control in open environment. 
 Distributed Algorithms for Ensuring Fair Interprocess Communications. 
 Near-Optimal Scheduling of Distributed Algorithms. 
 Optimal-stretch name-independent compact routing in doubling metrics. 
 Brief Announcement: A Time and Space Optimal Stable Population Protocol Solving Exact Majority. 
 Brief announcement: hardness of broadcasting in wireless networks with unreliable communication. 
 Want to Gather? No Need to Chatter! 
 Transactional predication: high-performance concurrent sets and maps for STM. 
 Garbage collection of timestamped data in Stampede. 
 A Modular Measure of Competitiveness for Distributed Algorithms (Abstract). 
 Seeing Far vs. Seeing Wide: Volume Complexity of Local Graph Problems. 
 Brief announcement: self-stabilizing resource discovery algorithm. 
 Born and raised distributively: fully distributed non-interactive adaptively-secure threshold signatures with short shares. 
 An Adaptive Approach to Recoverable Mutual Exclusion. 
 CCS Expressions, Finite State Processes, and THree Problems of Equivalence. 
 Hyaline: Fast and Transparent Lock-Free Memory Reclamation. 
 Improved Massively Parallel Computation Algorithms for MIS, Matching, and Vertex Cover. 
 Brief announcement: (more) efficient pruning of ad-hoc wireless networks. 
 Distributed maximal matching: greedy is optimal. 
 Virtual Time II: Storage Management in Conservative and Optimistic Systems. 
 Compact Distributed Certification of Planar Graphs. 
 How Emerging Memory Technologies Will Have You Rethinking Algorithm Design. 
 Sleeping is Efficient: MIS in  
 On Reliable Message Diffusion. 
 Life Beyond Set Agreement. 
 Making Operations of Concurrent Data Types Fast. 
 Exploitng event stream interpretation in publish-subscribe systems. 
 Adaptive randomized mutual exclusion in sub-logarithmic expected time. 
 Cost-Sensitive Analysis of Communication Protocols. 
 Fast Distributed Almost Stable Matchings. 
 Building responseive TMR-based servers in presence of timing constraints. 
 A modular approach to shared-memory consensus, with applications to the probabilistic-write model. 
 Concurrent updates with RCU: search tree as an example. 
 Tight Lower Bound for the RMR Complexity of Recoverable Mutual Exclusion. 
 Distributed MIS via All-to-All Communication. 
 Wait-Free Synchronization in Multiprogrammed Systems: Integrating Priority-Based and Quantum-Based Scheduling. 
 Brief announcement: concurrent non-commutative boosted transactions. 
 Delegation of computation with verification outsourcing: curious verifiers. 
 Distributed Approximation of Minimum k-edge-connected Spanning Subgraphs. 
 Coding theory for scalable media delivery. 
 Meeting the deadline: on the complexity of fault-tolerant continuous gossip. 
 On the Convergence Complexity of Optimistic Rate Based Flow Control Algorithms (Brief Announcement). 
 Lazy Consistency Using Loosely Synchronized Clocks. 
 Advanced contention management for dynamic software transactional memory. 
 How Proofs are Prepared at Camelot: Extended Abstract. 
 Brief Announcement: Optimal Record and Replay under Causal Consistency. 
 A BGP-based mechanism for lowest-cost routing. 
 Fault-Tolerance in Distributed Optimization: The Case of Redundancy. 
 A dynamic exchange game. 
 Complexity tradeoffs for read and update operations. 
 Gradient clock synchronization. 
 Restoration by path concatenation: fast recovery of MPLS paths. 
 Hardness of Distributed Optimization. 
 Distributed connectivity of wireless networks. 
 Fail-Awareness in Timed Asynchronous Systems. 
 Knowledge in Shared Memory Systems (Preliminary Version). 
 Crash Failures vs. Crash + Link Failures (Abstract). 
 Brief announcement: towards security and privacy for outsourced data in the multi-party setting. 
 N-Processors Graph Distributively Achieve Perfect Matchings in O(log 
 Efficient Message Ordering in Dynamic Networks. 
 Efficient algorithms for leader election in radio networks. 
 k-Stabilization of Reactive Tasks. 
 A tradeoff analysis on message complexity and lifetime optimality for a distributed multicast algorithm in WSNs. 
 An Asynchronous Computability Theorem for Fair Adversaries. 
 Analyzing Contention and Backoff in Asynchronous Shared Memory. 
 On the complexity of greedy routing in ring-based peer-to-peer networks. 
 Competitive and fair throughput for co-existing networks under adversarial interference. 
 Efficient distributed random walks with applications. 
 The Distributed Complexity of Locally Checkable Problems on Paths is Decidable. 
 Asynchronous failure detectors. 
 Probably Approximately Knowing. 
 Concurrent imitation dynamics in congestion games. 
 Matching Events in a Content-Based Subscription System. 
 Automatic discovery of mutual exclusion algorithms. 
 On selection problem in radio networks. 
 A log-star distributed maximal independent set algorithm for growth-bounded graphs. 
 Predicates are Predicate Transformers: A Unified Compositional Theory for Concurrency. 
 Memory Efficient, Self-Stabilizing Algorithm to Construct BFS Spanning Trees. 
 Brief Announcement: Pattern Formation Problem for Synchronous Mobile Robots in the Three Dimensional Euclidean Space. 
 Hybrid-secure MPC: trading information-theoretic robustness for computational privacy. 
 A New Fault-Tolerant Algorithm for Clock Synchronization. 
 Leader election in shared spectrum radio networks. 
 The Energy Complexity of Broadcast. 
 Genuinely Distributed Byzantine Machine Learning. 
 Synthesis of Distributed Control Systems (Abstract). 
 Distributed broadcasting and mapping protocols in directed anonymous networks. 
 Distributed Resource Discovery in Sub-Logarithmic Time. 
 Broadcasting in undirected ad hoc radio networks. 
 The future of distributed computing: renaissance or reformation? 
 Edge Locks and Deadlock Avoidance in Distributed Systems. 
 Adaptive and efficient abortable mutual exclusion. 
 GEM: A Tool for Concurrency Specification and Verification. 
 Brief announcement: queuing or priority queuing? on the design of cache-coherence protocols for distributed transactional memory. 
 Session details: Session 3C: Coloring. 
 Structuring unreliable radio networks. 
 Open Systems in TLA. 
 Trinity: distributed defense against transient spam-bots. 
 Non-Cryptographic Fault-Tolerant Computing in Constant Number of Rounds of Interaction. 
 Exploiting Spontaneous Transmissions for Broadcasting and Leader Election in Radio Networks. 
 Bounded Polynomial Randomized Consensus. 
 Our brothers' keepers. 
 External Logging on a Logical Ring. 
 Simultaneous broadcast revisited. 
 Common2 extended to stacks and unbounded concurrency. 
 Amortizing Randomness in Private Multiparty Computations. 
 Diversity, Fairness, and Sustainability in Population Protocols. 
 Verification of a Distributed Algorithm (Abstract). 
 On the Bit Complexity of Distributed Computations in a Ring with a Leader. 
 Optimal, Distributed Decision-Making: The Case of no Communication. 
 Vertical paxos and primary-backup replication. 
 Distributed graph coloring in a few rounds. 
 Disjoint-Access-Parallel Implementations of Strong Shared Memory Primitives. 
 Synthesis of Fault-Tolerant Concurrent Programs. 
 EquiCast: scalable multicast with selfish users. 
 Sleeping on the job: energy-efficient and robust broadcast for radio networks. 
 Optimal inter-object correlation when replicating for availability. 
 A distributed algorithm for gathering many fat mobile robots in the plane. 
 Average probe complexity of non-dominated coteries (brief announcement). 
 A Randomized Byzantine Agreement Protocol with Constant Expected Time and Guaranteed Termination in Optimal (Deterministic) Time. 
 Randomized Competitive Algorithms for Admission Control in General Networks (Abstract). 
 The Recoverable Consensus Hierarchy. 
 Multiple Communication in Multi-Hop Radio Networks. 
 Extracting models from design documents with mapster. 
 Brief announcement: game theoretical approach for energy-delay balancing in distributed duty-cycled MAC protocols of wireless networks. 
 The concurrency hierarchy, and algorithms for unbounded concurrency. 
 An algorithm composition scheme preserving monotonicity. 
 Two-handed emulation: how to build non-blocking implementation of complex data-structures using DCAS. 
 Single-Source Shortest Paths in the CONGEST Model with Improved Bound. 
 Half-Order Modal Logic: How to Prove Real-Time Properties. 
 Brief announcement: threshold load balancing in networks. 
 Another Advantage of Free Choice: Completely Asynchronous Agreement Protocols (Extended Abstract). 
 Multi-message broadcast with abstract MAC layers and unreliable links. 
 Failure Detectors and the Wait-Free Hierarchy. 
 Consensus and collision detectors in wireless Ad Hoc networks. 
 Brief Announcement: A Randomness-efficient Massively Parallel Algorithm for Connectivity. 
 Efficient threshold detection in a distributed environment: extended abstract. 
 Formal Semantics for Expressing Optimism: The Meaning of HOPE. 
 MST in Log-Star Rounds of Congested Clique. 
 Random walk for self-stabilitzing group communication in ad hoc networks. 
 Towards the minimal synchrony for byzantine consensus. 
 Brief Announcement: Proactive Secret Sharing with a Dishonest Majority. 
 Model checking transactional memory with spin. 
 Brahms: byzantine resilient random membership sampling. 
 Knowledge-Oriented Programming (Extended Abstract). 
 Efficient revocation and threshold pairing based cryptosystems. 
 Distributed computational complexities: are you volvo-addicted or nascar-obsessed? 
 On the complexity of distributed graph coloring. 
 Brief announcement: minimum spanning trees and cone-based topology control. 
 A verification technique for self-stabilizing algorithms based on Ljapunov's   Second Method   (brief announcement). 
 Brief announcement: broadcast in radio networks in the presence of byzantine adversaries. 
 Randomized loose renaming in  
 An incentive-compatible multi-armed bandit mechanism. 
 A Sharp Threshold Phenomenon for the Distributed Complexity of the Lovász Local Lemma. 
 Competitive concurrent distributed queuing. 
 A Formal Description of the UNIX Operating System. 
 Bounds on the shared memory requirements for long-lived adaptive objects (extended abstract). 
 Distributed consensus in the presence of sectional faults. 
 Brief announcement: deterministic skipnet. 
 Squirrel: a decentralized peer-to-peer web cache. 
 Fault Tolerance Bounds for Memory Consistency. 
 On a capacitated multivehicle routing problem. 
 Seeing is Believing: A Client-Centric Specification of Database Isolation. 
 A Verification Method Based on Homomorphic Model Abstractions (Abstract). 
 Brief Announcement: Round eliminator: a tool for automatic speedup simulation. 
 Byzantine disk paxos: optimal resilience with byzantine shared memory. 
 Brief announcement: secret handshakes from CA-oblivious encryption. 
 Debugging distributed programs using controlled re-execution. 
 Detecting distributed cycles of garbage in large-scale systems. 
 A Distributed Algorithm for Generalized Deadlock Detection. 
 Brief Announcement: Certified Multiplicative Weights Update: Verified Learning Without Regret. 
 Self-stabilizing token circulation on asynchronous uniform unidirectional rings. 
 An analysis of intention preservation in group editors. 
 LOTEC: A Simple DSM Consistency Protocol for Nested Object Transactions. 
 The forgiving graph: a distributed data structure for low stretch under adversarial attack. 
 Elections in the Presence of Faults. 
 A Completeness Theorem for a Class of Synchronization Objects (Extended Abstract). 
 Brief announcement: spoofing prevention method. 
 Does Preprocessing Help under Congestion? 
 Trade-Offs in Implementing Optimal Message Logging Protocols. 
 Introducing speculation in self-stabilization: an application to mutual exclusion. 
 Competing against Specialists. 
 Brief announcement: self-stabilizing distance-d distinct labels via enriched fair composition. 
 Atomic Snapshots of Shared Memory. 
 Constructing a Reliable Test&Set Bit (Abstract). 
 Brief announcement: deaf, dumb, and chatting robots. 
 When selfish meets evil: byzantine players in a virus inoculation game. 
 Distributed Coloring in Sparse Graphs with Fewer Colors. 
 Knowledge, Common Knowledge and related puzzles (Extended Summary). 
 Fast Randomized Algorithms for Distributed Edge Coloring (Extended Abstract). 
 Transactional contention management as a non-clairvoyant scheduling problem. 
 Nearly optimal FIFO buffer management for DiffServ. 
 A Decidable Temporal Logic to Reason About Many Processes. 
 On Fault Tolerant Routings in General Networks. 
 Composite Registers. 
 The Complexity of Using Forwarding Addresses for Decentralized Object Finding. 
 The price of selfish behavior in bilateral network formation. 
 The Effect of Link Failures on Computations in Asynchronous Rings. 
 On Mixing Eventual and Strong Consistency: Bayou Revisited. 
 Optimistic Concurrency Control for Abstract Data Types. 
 Minimum congestion mapping in a cloud. 
 An Optimal Synchronizer for the Hypercube. 
 Software transactional memory for dynamic-sized data structures. 
 Using Belief to Reason about Cache Coherence. 
 Bringing order to BGP: decreasing time and message complexity. 
 Self-Stabilizing Leader Election in Regular Graphs. 
 On the Impossibility of Group Membership. 
 Tight Bounds for Asymptotic and Approximate Consensus. 
 Dual Failure Resilient BFS Structure. 
 Modular Verification of Asynchronous Networks. 
 Trading Fences with RMRs and Separating Memory Models. 
 Brief announcement: the price of anonymity: optimal consensus despite asynchrony, crash and anonymity. 
 Tutorial: Specifying, Implementing, and Verifying Algorithms for Persistent Memory. 
 Efficient dependency tracking for relevant events in shared-memory systems. 
 Building scalable and robust peer-to-peer overlay networks for broadcasting using network coding. 
 2021 Edsger W. Dijkstra Prize in Distributed Computing. 
 Brief announcement: complexity analysis and algorithm design for pipeline configuration in distributed networks. 
 Brief announcement: dynamic interoperable point-to-point connection of MPI implementations. 
 Maximizing quorum availability in multi-clustered systems. 
 Proof labeling schemes. 
 Deterministic distributed vertex coloring in polylogarithmic time. 
 Simple, Efficient Asynchronous Parallel Algorithms for Maximization. 
 Reasoning about Meta Level Activities in Open Distributed Systems. 
 Optimal Distributed Covering Algorithms. 
 On the locality of bounded growth. 
 Recoverable Mutual Exclusion: [Extended Abstract]. 
 The Synthesis of Communication Protocols. 
 Modular Construction of Nearly Optimal Byzantine Agreement Protocols. 
 Leader Election in the Presence of Link Failures (Abstract). 
 LCL Problems on Grids. 
 Stabilizing Server-Based Storage in Byzantine Asynchronous Message-Passing Systems: Extended abstract. 
 Byzantine Fault Tolerant State Machine Replication in Any Programming Language. 
 Lower bounds for randomized consensus under a weak adversary. 
 Script: A Communication Abstraction Mechanism. 
 Error-free multi-valued consensus with byzantine failures. 
 Asynchronous exclusive selection. 
 Continous Routing and Batch Routing on the Hypercube. 
 On the Coding of Dependencies in Distributed Computations (Abstract). 
 Distributed Approximation on Power Graphs. 
 Brief announcement: reconfigurable byzantine-fault-tolerant atomic memory. 
 The Balanced Sorting Network. 
 Brief announcement: dynamic group communication. 
 A Distributed Transaction Processing Protocol Based on Majority Consensus. 
 Brief Announcement: Probabilistic Asynchronous Arbitrary Pattern Formation. 
 Compiling Knowledge-Based Programs (Abstract). 
 Easy Impossibility Proofs for Distributed Consensus Problems. 
 Limitations of Highly-Available Eventually-Consistent Data Stores. 
 Brief Announcement: Deterministic Graph Connectivity in the Broadcast Congested Clique. 
 Are Shared Objects Composable under an Oblivious Adversary? 
 Cryptography and competition policy: issues with 'trusted computing'. 
 Proving Probabilistic Correctness Statements: the Case of Rabin's Algorithm for Mutual Exclusion. 
 Fault-Tolerant Clock Synchronization. 
 The cost of radio network broadcast for different models of unreliable links. 
 The stretched exponential distribution of internet media access patterns. 
 Improved Distributed Approximations for Minimum-Weight Two-Edge-Connected Spanning Subgraph. 
 Brief announcement: weakest failure detectors via an egg-laying simulation. 
 Brief announcement: building an adaptive distributed web server system on the fly for handling web hotspots. 
 On Parallel Searching (Extended Abstract). 
 Brief Announcement: On Using Null Messages in a Byzantine Setting. 
 Brief announcement: implementing multi-word atomic snapshots on current hardware. 
 Broadcast Extensions with Optimal Communication and Round Complexity. 
 The communication complexity of distributed task allocation. 
 On the robustness of (semi) fast quorum-based implementations of atomic shared memory. 
 Computing almost shortest paths. 
 Fast self-stabilizing byzantine tolerant digital clock synchronization. 
 Brief Announcement: Computing in the Presence of Weak Crash Failures. 
 A Dynamic Self-Stabilizing Algorithm for Finding Strongly Connected Components. 
 Research Issues in Ubiquitous Computing. 
 Group membership: a novel approach and the first single-round algorithm. 
 On the Complexity of Reader-Writer Locks: Extended Abstract. 
 Brief Announcement: Asynchronous Secure Distributed Computing with Transferrable Non-equivocation Revisited. 
 Deterministic Abortable Mutual Exclusion with Sublogarithmic Adaptive RMR Complexity. 
 Distributed Strong Diameter Network Decomposition: Extended Abstract. 
 Primal-dual based distributed algorithms for vertex cover with semi-hard capacities. 
 Proving Time Bounds for Randomized Distributed Algorithms. 
 Distributed churn measurement in arbitrary networks. 
 Greedy distributed optimization of unsplittable multicommodity flows. 
 Oblivious interference scheduling. 
 Brief Announcement: Space-Time Tradeoffs for Distributed Verification. 
 Generalized lattice agreement. 
 Tight Analysis of Asynchronous Rumor Spreading in Dynamic Networks. 
 Implementing Wait-Free Objects on Priority-Based Systems. 
 The Intractability of Bounded Protocols for Non-FIFO Channels. 
 Brief announcement: the theory of network tracing. 
 Improved Deterministic (Δ+1) Coloring in Low-Space MPC. 
 Logarithmic Expected-Time Leader Election in Population Protocol Model. 
 Implementing Mediators with Asynchronous Cheap Talk. 
 Transaction Commit in a Realistic Fault Model. 
 Continuous Clock Amortization Need Not Affect the Precision of a Clock Synchronization Algorithm. 
 The Randomized Local Computation Complexity of the Lovász Local Lemma. 
 From Classical to Blockchain Consensus: What Are the Exact Algorithms? 
 Abortable and query-abortable objects and their efficient implementation. 
 X-ability: a theory of replication. 
 Parallel and distributed programming extensions for mainstream languages based on pi-calculus. 
 The Elusive Atomic Register Revisited. 
 Exponentially Faster Shortest Paths in the Congested Clique. 
 Protocols for Dynamic Vote Reassignment. 
 Space adaptation: privacy-preserving multiparty collaborative mining with geometric perturbation. 
 Memory Requirements for Silent Stabilization (Extended Abstract). 
 Compressed bloom filters. 
 Brief Announcement: Rapid Asynchronous Plurality Consensus. 
 Distributed algorithms for dynamic coverage in sensor networks. 
 Using Lock-Free Objects in Hard Real-Time Applications (Abstract). 
 Interactive Distributed Proofs. 
 An Efficient Recovery-Based Spin Lock Protocol for Preemptive Shared Memory Multiprocessors. 
 A nonblocking set optimized for querying the minimum value. 
 Brief announcement: new bounds for the controller problem. 
 The coBFIT toolkit. 
 Robust network supercomputing without centralized control. 
 Distributed algorithms for ultrasparse spanners and linear size skeletons. 
 Byzantine vector consensus in complete graphs. 
 Online Resource Leasing. 
 Round-by-Round Fault Detectors: Unifying Synchrony and Asynchrony (Extended Abstract). 
 A world of (Im) possibilities. 
 A Simple Local-Spin Group Mutual Exclusion Algorithm. 
 Ultra-Sparse Near-Additive Emulators. 
 From bounded to unbounded concurrency objects and back. 
 Embedding a Deterministic BFT Protocol in a Block DAG. 
 The ABCD's of Paxos. 
 Network formation games with local coalitions. 
 On Implementing Stabilizing Leader Election with Weak Assumptions on Network Dynamics. 
 Efficient Size Estimation and Impossibility of Termination in Uniform Dense Population Protocols. 
 Concurrent Counting (Extended Abstract). 
 Expansion and the cover time of parallel random walks. 
 Distributed computing meets game theory: robust mechanisms for rational secret sharing and multiparty computation. 
 On a Random Walk Problem Arising in Self-Stabilizing Token Management. 
 Distributed Computation and Reconfiguration in Actively Dynamic Networks. 
 On the interconnection of causal memory systems. 
 A lattice-theoretic characterization of safety and liveness. 
 Recoverable Mutual Exclusion in Sub-logarithmic Time. 
 A Design Methodology for Mobile Distributed Applications Based on UNITY Formalism and Communication-Closed Layering. 
 Bounded budget connection (BBC) games or how to make friends and influence people, on a budget. 
 Tutorial: Consistency Choices in Modern Distributed Systems. 
 Flooding time in edge-Markovian dynamic graphs. 
 Brief announcement: adaptive content placement for peer-to-peer video-on-demand systems. 
 Efficient Solutions to the Replicated Log and Dictionart Problems. 
 Selective private function evaluation with applications to private statistics. 
 Brief announcement: network traffic can optimize consolidation during transformation to virtualization. 
 Rational Consensus: Extended Abstract. 
 Brief announcement: k-shot distributed broadcasting in radio networks. 
 Making objects writable. 
 A certain freedom: thoughts on the CAP theorem. 
 A Technique for Proving Liveness of Communicating Finite State Machines with Examples. 
 Brief Announcement: Space-Optimal Naming in Population Protocols. 
 Brief announcement: gradient clock synchronization in sensor networks. 
 Fast Approximate Shortest Paths in the Congested Clique. 
 On the price of equivocation in byzantine agreement. 
 Group mutual exclusion in  
 Geometric ad-hoc routing: of theory and practice. 
 A better wake-up in radio networks. 
 Bootstrap Network Resynchronization (Extended Abstract). 
 Adding Concurrency to Smart Contracts. 
 From Self-Stabilization to Self-Optimization: Principles of Distributed Network Design. 
 A new approach to on-demand loop-free routing in ad hoc networks. 
 Session details: Session 1D: Graph Algorithms. 
 Efficient Parallel Algorithms on Restartable Fail-Stop Processors. 
 Computing Weak Consistency in Polynomial Time: [Extended Abstract]. 
 Memory Coherence in Shared Virtual Memory Systems. 
 On utilizing speed in networks of mobile agents. 
 A Checkpoint Protocol for an Entry Consistent Shared Memory System. 
 Distributed Communication via Global Buffer. 
 Comparison Dynamics in Population Protocols. 
 Brief announcement: Cataclysm: handling extreme overloads in internet services. 
 Efficient Deterministic Leader Election for Programmable Matter. 
 Brief Announcement: Intermediate Value Linearizability: A Quantitative Correctness Criterion. 
 Feedback from nature: an optimal distributed algorithm for maximal independent set selection. 
 Brief announcement: secret sharing based on the social behaviors of players. 
 Coded State Machine - Scaling State Machine Execution under Byzantine Faults. 
 Brief Announcement: Proust: A Design Space for Highly-Concurrent Transactional Data Structures. 
 Distributed algorithms in TLA (abstract). 
 Brief announcement: impossibility results for concurrent secure two-party computation. 
 Data communications vs. distributed computing (abstract). 
 Limitations on Database Availability when Networks Partition. 
 Irreducibility and additivity of set agreement-oriented failure detector classes. 
 Brief Announcement: Certified Universal Gathering in R 
 Peer counting and sampling in overlay networks: random walk methods. 
 A Formal Model of Knowledge, Action, and Communication in Distributed Systems: Preliminary Report. 
 Lock-Free Algorithms under Stochastic Schedulers. 
 Distributed algorithms for the Lovász local lemma and graph coloring. 
 Brief announcement: tolerating permanent and transient value faults. 
 A Lower Bound on the Local Time Complexity of Universal Constructions. 
 Unbounded contention resolution in multiple-access channels. 
 Distributed Allocation with Pools of Servers. 
 Fast and unconditionally secure anonymous channel. 
 Brief announcement: perfectly secure message transmissiontolerating mobile mixed adversary with reduced phase complexity. 
 Transactional Lock Elision Meets Combining. 
 2018 Doctoral Dissertation Award. 
 Gap Theorems for Distributed Computation. 
 Brief announcement: ring-like DHTs and the postage stamp problem. 
 Architecture Decisions for Wide Area Applications (Abstract). 
 Brief announcement: resettable objects and efficient memory reclamation for concurrent algorithms. 
 New Routing Techniques and their Applications. 
 An almost non-blocking stack. 
 Brief Announcement: Malicious Security Comes for Free in Consensus with Leaders. 
 Brief Announcement: Fault-tolerant Broadcast in Anonymous Distributed Systems with Fair Lossy Communication Channels. 
 Optimal Routing Tables. 
 Fault Tolerant Gradient Clock Synchronization. 
 Optimal Locking Integrated with Operational Transformation in Distributed Real-Time Group Editors. 
 Dynamic Hashing + Quorum = Efficient Location Management for Mobile Computing Systems. 
 How to be an Efficient Snoop, or the Probe Complexity of Quorum Systems (Extended Abstract). 
 Time-Adaptive Self Stabilization. 
 Brief Announcement: Readers of Wait-Free Unbounded Registers Must Write. 
 A Communication-Efficient Canonical Form for Fault-Tolerant Distributed Protocols. 
 A Framework for Partitionable Membership Service (Abstract). 
 A Characterization of Networks Supporting Linear Interval Routing. 
 Selective anti-entropy. 
 Relative Liveness and Behavior Abstraction (Extended Abstract). 
 On the Borowsky-Gafni Simulation Algorithm (Abstract). 
 An efficient communication strategy for ad-hoc mobile networks. 
 The round complexity of distributed sorting: extended abstract. 
 Brief announcement: fast scalable Byzantine agreement in the full information model with a nonadaptive adversary. 
 Detecting Stable Properties of Networks in Concurrent Logic Programming Languages. 
 Optimizing data popularity conscious bloom filters. 
 Constraint-based Structuring of Distributed Protocols (Abstract). 
 Asymptotically Optimal Validated Asynchronous Byzantine Agreement. 
 Two Messages are Almost Optimal for Conveying Information. 
 Brief Announcement: Collect in the Presence of Continuous Churn with Application to Snapshots and Lattice Agreement. 
 Calling Names in Nameless Networks. 
 Atomic Cross-Chain Swaps. 
 Viewstamped Replication: A General Primary Copy. 
 Practical Implementations of Non-Blocking Synchronization Primitives. 
 A Theory of Relaxed Atomicity (Extended Abstract). 
 Bounding the locality of distributed routing algorithms. 
 How Fast Reads Affect Multi-Valued Register Simulations. 
 The Strength of Counting Networks (Abstract). 
 Distributed Multi-Destination Routing: The Constraints of Local Information. 
 Ignore or Comply?: On Breaking Symmetry in Consensus. 
 The combined power of conditions and failure detectors to solve asynchronous set agreement. 
 Brief announcement: polygonal broadcast, secret maturity and the firing sensors. 
 Brief Announcement: Broadcast in Radio Networks, Time vs. Energy Tradeoffs. 
 Refining the way to consensus. 
 Resilience of mutual exclusion algorithms to transient memory faults. 
 Bayesian ignorance. 
 Atomicity in Electronic Commerce. 
 Brief Announcement: Average Complexity for the LOCAL Model. 
 On scalable and efficient distributed failure detectors. 
 The price of anarchy in network creation games. 
 Beyond Alice and Bob: Improved Inapproximability for Maximum Independent Set in CONGEST. 
 Brief announcement: distributed universality: contention-awareness; wait-freedom; object progress, and other properties. 
 Optimal Dynamic Distributed MIS. 
 An Interval Logic for Higher-Level Temporal Reasoning. 
 Partition approach to failure detectors for k-set agreement. 
 Communication-Time Trade-Offs in Network Synchronization. 
 Reliable end-user communication under a changing packet network protocol. 
 Self-Stabilizing Extensions for Message-Passing Systems. 
 Optimal Allocation of Electronic Contect in Networks. 
 Leader Election in Complete Networks. 
 Randomized Mutual Exclusion Algorithms Revisited. 
 The wakeup problem in synchronous broadcast systems (extended abstract). 
 Clock synchronization with faults and recoveries (extended abstract). 
 Resiliency of Interactive Distributed Tasks (Extended Abstract). 
 Self-Stabilizing Snapshot Objects for Asynchronous Failure-Prone Networked Systems. 
 Fast byzantine agreement in dynamic networks. 
 Replay, Recovery, Replication, and Snapshots of Nondeterministic Concurrent Programs. 
 Brief announcement: optimal amortized secret sharing with cheater identification. 
 Specifying and Using a Partitionable Group Communication Service. 
 Distributed order scheduling and its application to multi-core dram controllers. 
 Brief announcement: extending SQL access control to derived and distributed data. 
 The internet is flat: a brief history of networking in the next ten years. 
 Computing with Faulty Shared Memory (Extended Abstract). 
 Brief Announcement: Wake Up and Join Me! An Energy Efficient Algorithm for Maximal Matching in Radio Networks. 
 Wait-freedom with advice. 
 A denial-of-service resistant DHT. 
 Distance-2 Coloring in the CONGEST Model. 
 Brief Announcement: An Improved Distributed Approximate Single Source Shortest Paths Algorithm. 
 Brief announcement: a tight RMR lower bound for randomized mutual exclusion. 
 Partial synchrony based on set timeliness. 
 Space-optimal multi-writer snapshot objects are slow. 
 Brief announcement: continuous containment and local stabilization in path-vector routing. 
 Generalizing Fair Reachability Analysis to Protocols with Arbitrary Topology (Abstract). 
 Brief Announcement: Beeping a Time-Optimal Leader Election. 
 Brief Announcement: Classification of Distributed Binary Labeling Problems. 
 Plenary talk. 
 Near-Optimal Distributed Maximum Flow: Extended Abstract. 
 Closing the complexity gap between mutual exclusion and FCFS mutual exclusion. 
 Symmetry Breaking with Noisy Processes. 
 Efficient and practical constructions of LL/SC variables. 
 Eclecticism shrinks even small worlds. 
 From Bezout's Identity to Space-Optimal Election in Anonymous Memory Systems. 
 On the Classification of Deterministic Objects via Set Agreement Power. 
 Recovery in Distributed Systems Using Asynchronous Message Logging and Checkpointing. 
 Central Control over Distributed Asynchronous Systems: A Tutorial on Software-Defined Networks and Consistent Network Updates. 
 Practical Uses of Synchronized Clocks in Distributed Systems. 
 On the Distribution of an Assertion. 
 On  
 Self-Stabilizing Clock Synchronization in the Presence of Byzantine Faults (Abstract). 
 Brief Announcement: On the Feasibility of Leader Election and Shape Formation with Self-Organizing Programmable Matter. 
 Brief announcement: swarming secrets. 
 Population Stability: Regulating Size in the Presence of an Adversary. 
 On the complexity of asynchronous gossip. 
 Brief Announcement: Optimal Time and Space Leader Election in Population Protocols. 
 Distributed public key schemes secure against continual leakage. 
 Upper bound on the complexity of solving hard renaming. 
 Simulating Fail-Stop in Asynchronous Distributed Systems. 
 Brief announcement: improving social-network-based sybil-resilient node admission control. 
 Correctness Conditions for Highly Available Replicated Databases. 
 Testing Concurrent Data Structures (Abstract). 
 Signed quorum systems. 
 Brief Announcement: Brokering with Hashed Timelock Contracts is NP-Hard. 
 How Fair is Your Protocol?: A Utility-based Approach to Protocol Optimality. 
 Concurrency Control Algorithms for Multiversion Database Systems.`;
