import React from "react";

type Props = {
	alertType?: string; // success, danger, warning, info
};

type State = {};

export default class InformationBox extends React.Component<Props, State> {
	state = {};
	static defaultProps: { alertType: string };

	render() {
		return (
			<div className={"alert alert-" + this.props.alertType + " alert-dismissible fade show"} role="alert">
				{this.props.children}
				<button type="button" className="close" data-dismiss="alert" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		);
	}
}

InformationBox.defaultProps = {
	alertType: "info",
};
