import { isEmptyObject } from "jquery";
import { Component } from "react";
import { Navigate } from "react-router";
import { retrieveInformation } from "../../Utils/utils_details";
import CustomButton from "../Buttons/CustomButton";
import Conference from "../Conference";
import InformationBox from "../InformationBox/InformationBox";
import "./Details.scss";

type Props = {
	id_pk: string;
};

type State = {
	c: Conference | undefined;
	hidden: any;
};

export default class Details extends Component<Props, State> {
	state = {
		c: undefined,
		hidden: undefined,
	};

	information: Promise<Conference | undefined> = retrieveInformation(this.props.id_pk).then(
		(response) => {
			if (isEmptyObject(response)) {
				return undefined;
			}
			const conference = new Conference();
			conference.initFromObject(response!);
			return conference;
		},
		(error) => {
			console.log(error);

			return undefined;
		}
	);

	componentDidMount() {
		this.information
			.then((result) => {
				this.setState({ c: result });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	render() {
		if (!this.state.c) {
			return (
				<div className="Details  text-left p-3">
					<div className="h2">Details</div>
					<InformationBox>Have you specified a valid id?</InformationBox>
				</div>
			);
		}

		const conf: Conference = this.state.c! as Conference;

		return (
			<div className="Details text-left p-3">
				<div className="h2">Details</div>
				{/* {this.state.c ? (this.state.c as Conference).toString() : ""} */}
				<div className="wrap-details">
					{/* Row 0 : Top 10 keywords */}
					<div className="row">
						<div className="col-md-12 form-group">
							<label>Top 25 Keywords</label>
							<textarea className="form-control" readOnly value={conf.getTopKeywords(25)}></textarea>
						</div>
					</div>
					{/* Row 1 : Acronym & Name */}
					<div className="row">
						<div className="col-md-4">
							<label>Acronym</label>
							<input readOnly className="form-control" value={conf.acronym}></input>
						</div>
						<div className="col-md-8">
							<label>Name</label>
							<input className="form-control" readOnly value={conf.name}></input>
						</div>
					</div>
					{/* Row 2 : Embed Acronym */}
					<div className="row">
						<div className="col-md-4">
							<label>Acronym Key</label>
							<input
								className="form-control"
								readOnly
								value={(this.state.c! as Conference).id_acro}
							></input>
						</div>
					</div>
					{/* Row 3 : Rank &  */}
					<div className="row">
						<div className="col-md-8">
							<label>Conference Website</label>
							<a
								target="_blank"
								rel="noreferrer"
								href={conf.www ? conf.www : ""}
								style={{ overflowWrap: "break-word" }}
							>
								<input
									className="form-control clickable"
									readOnly
									value={conf.www ? conf.www : "/"}
								></input>
							</a>
						</div>
						<div className="col-md-4">
							<label>Rank (CORE Ranking)</label>
							<input className="form-control" readOnly value={conf.rank ? conf.rank : "/"}></input>
						</div>
					</div>
					{/* Row 4 : Location & Deadline */}
					<div className="row">
						<div className="col-md-6">
							<label>Location</label>
							<input
								className="form-control"
								readOnly
								value={conf.location ? conf.location : "/"}
							></input>
						</div>
					</div>
					{/* Row 5 : Deadline & Notification */}
					<div className="row">
						<div className="col-md-6">
							<label>Deadline</label>
							<input
								className="form-control"
								readOnly
								value={conf.deadline ? conf.deadline : "/"}
							></input>
						</div>
						<div className="col-md-6">
							<label>Notification</label>
							<input
								className="form-control"
								readOnly
								value={conf.notification ? conf.notification : "/"}
							></input>
						</div>
					</div>
					{/* Row 6 : Start & End */}
					<div className="row">
						<div className="col-md-6">
							<label>Start of Conference</label>
							<input className="form-control" readOnly value={conf.start ? conf.start : "/"}></input>
						</div>
						<div className="col-md-6">
							<label>End of Conference</label>
							<input className="form-control" readOnly value={conf.end ? conf.end : "/"}></input>
						</div>
					</div>
				</div>
				<div className="my-3">
					<CustomButton
						value="Home"
						flavor="light-blue"
						onClick={(e) => this.setState({ hidden: <Navigate to="/" /> })}
					></CustomButton>
					<CustomButton
						value="Edit"
						flavor="semi-dark-composite"
						onClick={(e) => this.setState({ hidden: <Navigate to={"/edit/" + this.props.id_pk} /> })}
					></CustomButton>
				</div>
				{this.state.hidden}
			</div>
		);
	}
}
