import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import SearchPage from "./Pages/SearchPage";
import EditPage from "./Pages/EditPage";
import DetailsPage from "./Pages/DetailsPage";
import ComputeKeywords from "./Pages/ComputeKeywordsPage";
import Page404 from "./Pages/Page404";
import AddPage from "./Pages/AddPage";
import AboutPage from "./Pages/AboutPage";

function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="/" element={<SearchPage />} />
					<Route path="/edit/:id_pk" element={<EditPage />} />
					<Route path="/details/:id_pk" element={<DetailsPage />} />
					<Route path="/compute-keywords" element={<ComputeKeywords />} />
					<Route path="/add" element={<AddPage />} />
					<Route path="/about" element={<AboutPage />} />
					<Route path="*" element={<Page404 />} />
				</Routes>
			</Router>
			{/* TODO : Add the ConfSearch Logo. */}
		</div>
	);
}

export default App;
