import * as React from "react";
import "./Wrapper.scss";
import logo_disco from "../Assets/Images/distributed-computing-logo.png";
import logo_con from "../Assets/Images/confsearch-logo.png";

export interface IWrapperProps {}

export default class Wrapper extends React.Component<IWrapperProps> {
	public render() {
		return (
			<div className="wrapper p-3">
				<div className="h1 header m-5">
					<a className="title" href={"/"}>
						Computer Science <span className="text-light-blue">Conference Search</span>
					</a>
				</div>
				<div className="children">{this.props.children}</div>
				<footer className="border-top">
					<div className="row">
						<div className="col-md-6 ">
							<img className={"logo"} src={logo_con} alt="" />
						</div>
						<div className="col-md-6">
							<a href="https://disco.ethz.ch/">
								<img alt="logo" className={"logo"} src={logo_disco}></img>
							</a>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}
