import { useLocation } from "react-router";
import InformationBox from "../Components/InformationBox/InformationBox";
import Search from "../Components/Search/Search";
import Wrapper from "./Wrapper";

type Props = {};

const SearchPage = (props: Props) => {
	const location = useLocation();

	var renderBanner = () => {
		if (location.state && (location.state as any).banner_type) {
			var kind: string = "info";
			if ((location.state as any).banner_type && (location.state as any).banner_type === 1) {
				kind = "success";
			}
			if ((location.state as any).banner_message) {
				const msg = (location.state as any).banner_message;
				return <InformationBox alertType={kind}>{msg}</InformationBox>;
			}
		}
		return;
	};

	return (
		<Wrapper>
			{renderBanner()}
			<Search></Search>
		</Wrapper>
	);
};

export default SearchPage;
