import Wrapper from "./Wrapper";

type Props = {};

export default function AboutPage(props: Props) {
	return (
		<Wrapper>
			<div className="text-left offset-md-2 col-md-8">
				<div className="h2">About</div>
				<div className="h4">ConfSearch</div>
				<p>
					ConfSearch is a search engine and conference calendar designed to search for scientific conferences
					in the computer science domain. It supports different search types (such as search by keyword,
					related conferences, or author) and provides additional meta-information about conferences (such as
					submission deadline or conference location). The core search functionality is based on data from{" "}
					<a href="https://dblp.org/">DBLP</a> and currently covers more than 5000 conferences and more than
					1.9M authors. For the insertion of a conference's meta-data, ConfSearch makes use of the
					wiki-prinicple, i.e. it relies, similarly as Wikipedia, on the users to fill in the information.
					Please help by contributing the conference dates, website links, and so on using the modify button
					on the left. The initial meta-data provided by us stems from{" "}
					<a href="http://www.wikicfp.com/cfp/">WikiCFP</a> and{" "}
					<a href="http://portal.core.edu.au/conf-ranks/">CORE</a>. These two pages are a good reference for
					guiding future user edits. They should be used along side the official conference websites.
				</p>
				<div className="h4">ConfSearch 2022</div>
				<p>
					This version of ConfSearch is comes as an update to the initial ConfSearch version first released in
					2008. It comes with several simplifications, a reworked design and search engine as well as new
					data. There is now support for more than 5000 conferences, which is about 3000 more than in 2008.
				</p>
				<div className="h4">Team</div>
				<p>
					The project was realized in the scope of a bachelor thesis proposed by the{" "}
					<a href="https://disco.ethz.ch/">Distributed Computing Group</a> of ETH Zurich, led by{" "}
					<a href="https://disco.ethz.ch/members/wroger">Prof. Dr. Roger Wattenhofer</a>. The task was
					realized by <a href="https://www.linkedin.com/in/athillen">Alex Thillen</a> and supervised by{" "}
					<a href="https://disco.ethz.ch/members/wangye">Ye Wang</a>. Credits are also due to{" "}
					<a href="https://disco.ethz.ch/alumni/kuhnmi">Dr. Michael Kuhn</a>, who brought this project to life
					in 2008.
				</p>
			</div>
		</Wrapper>
	);
}
