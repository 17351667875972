import React, { Component } from 'react'
import './Month.scss'

type Props = {
    month: string;
}

type State = {}

export default class Month extends Component<Props, State> {
  state = {}

  render() {
    return (
      <span className='month'>{this.props.month}</span>
    )
  }
}