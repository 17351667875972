import { Params, useParams } from "react-router";
import Details from "../Components/Details/Details";
import InformationBox from "../Components/InformationBox/InformationBox";
import { isNumeric } from "../Utils/utils";
import Wrapper from "./Wrapper";

type Props = {};

export default function DetailsPage(props: Props) {
	// Retrieve id string from URL.
	const params: Params<string> = useParams();
	if (!params["id_pk"] || !isNumeric(params["id_pk"])) {
		return (
			<Wrapper>
				<div className="h2">Details</div>
				<InformationBox>You did not specify a valid id. If yes, please try again later.</InformationBox>
			</Wrapper>
		);
	}
	return (
		<Wrapper>
			<Details id_pk={params["id_pk"]}></Details>
		</Wrapper>
	);
}
