import { Component, MouseEventHandler } from "react";
import "./Button.scss";

type Props = {
	value: string;
	flavor?: string; // semi-dark, semi-dark-composite, light-custom, light-blue
	onClick: MouseEventHandler<HTMLButtonElement>;
	btnBlock?: string;
	navigate?: undefined | string;
};

type State = {};

export default class CustomButton extends Component<Props, State> {
	state = {};
	static defaultProps: {
		value: string;
		flavor: string;
		onClick: MouseEventHandler<HTMLButtonElement>;
		btnBlock?: string;
		navigate?: undefined | string;
	};

	componentDidMount() {}

	onClick(e: any) {
		this.props.onClick(e);
		if (this.props.navigate !== undefined) {
			window.location.assign(this.props.navigate!);
		}
	}

	render() {
		return (
			<button
				value={this.props.value}
				onClick={this.onClick.bind(this)}
				className={"shadow " + this.props.btnBlock + " clickable btn  btn-" + this.props.flavor}
			>
				{this.props.value}
			</button>
		);
	}
}

CustomButton.defaultProps = {
	value: "Button",
	flavor: "light-custom",
	onClick: () => {},
	btnBlock: "btn-block",
	navigate: undefined,
};
