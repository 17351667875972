export function checkValidityOfDate(date : string | undefined) : boolean {
    if (date === undefined) {
        return false;
    }
    var date2 : Date = new Date(date);
    var today : Date = new Date();
    if (isNaN(date2.valueOf()) || (today.getFullYear() - date2.getFullYear()) > 10) {
        return false;
    }
    return true;
}