import EditForm from "../Components/Edit/EditForm";
import Wrapper from "./Wrapper";

type Props = {};

export default function EditPage(props: Props) {
	return (
		<Wrapper>
			<EditForm></EditForm>
		</Wrapper>
	);
}
