class Conference {
	acronym: string | undefined;
	name: string | undefined;
	rank: string | undefined;
	www: string | undefined;
	location: string | undefined;
	deadline: string | undefined;
	notification: string | undefined;
	start: string | undefined;
	end: string | undefined;
	id_pk: number | undefined;
	id_acro: string | undefined;
	user_rating: string | undefined;
	deleted: string | boolean | undefined;
	protected: boolean;
	weighted_keywords: any | undefined;

	constructor() {
		this.acronym = "";
		this.name = "";
		this.rank = "";
		this.www = "";
		this.location = "";
		this.deadline = "";
		this.notification = "";
		this.start = "";
		this.end = "";
		this.id_pk = -1;
		this.id_acro = "";
		this.user_rating = "";
		this.deleted = false;
		this.protected = false;
		this.weighted_keywords = [];
	}

	get(k: string): boolean | string | undefined | number {
		for (const key in this) {
			if ((key as string) === k) {
				return this[key] as any; // should be string
			}
		}
		return undefined;
	}

	set(k: string, elem: (string | number)[][] | number | string | undefined | boolean) {
		for (const key in this) {
			if ((key as string) === k) {
				if (k === "weighted_keywords" && elem !== undefined && typeof elem === "string") {
					const obj = JSON.parse((elem as string)!);
					let list = [];
					for (const key in obj) {
						list.push([key, obj[key]]);
					}
					list.sort((a, b) => -(a[1] - b[1]));
					(this[key] as any) = list;
					return;
				}
				(this[key] as any) = elem;
				return;
			}
		}
		// console.log("Couldn't find key : " + k);
	}

	initFromObject(object: object) {
		for (const key in object) {
			this.set(key, object[key as keyof object]);
		}
	}

	getTopKeywords(nr: number) {
		let s: string = "";
		if (this.weighted_keywords === undefined || this.weighted_keywords!.length === 0) {
			return s;
		}
		// console.log(this.weighted_keywords);

		for (let i = 0; i < Math.min(nr, this.weighted_keywords.length); i++) {
			const a = i < Math.min(nr, this.weighted_keywords.length) - 1 ? " , " : "";
			s = s.concat(this.weighted_keywords[i][0] + a);
		}
		return s;
	}

	export(): string {
		return JSON.stringify(this.export_obj());
	}

	export_obj(): any {
		var obj: any = {};
		for (const key in this) {
			obj[key as any] = this.get(key);
		}
		return obj;
	}

	toString(): string {
		return this.export();
	}
}

export default Conference;
