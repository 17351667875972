import { Component } from "react";
import "./Icons.scss";

type Props = {
	outOfDate: boolean;
};

type State = {};

export default class DeadlineIcon extends Component<Props, State> {
	state = {};

	render() {
		return (
			<div className={"deadline-icon" + (this.props.outOfDate ? "-out-of-date" : "")}>{this.props.children}</div>
			// <div className="deadline-icon">{this.props.children}</div>
		);
	}
}
