import { Component } from "react";
import "./ConferenceRow.scss";
import { Row } from "./Types";
import DateCell from "./DateCell";
import WebIcon from "../../Icons/WebIcon";
import CalendarIcon from "../../Icons/CalendarIcon/CalendarIcon";
import { checkValidityOfDate } from "../Date/DateUtils";
import { ics } from "../../../Assets/CodeRepo/ics/ics";
import EditIcon from "../../Icons/EditIcon/EditIcon";
import DetailsIcon from "../../Icons/DetailsIcon/DetailsIcon";
import Tooltip from "../../Tooltip/Tooltip";

type ConferenceRowProps = {
	row: Row;
	hoveredDate: undefined | Date;
	setHoveredDate: (date: Date | undefined) => void;
	setSelected: (x: boolean) => void;
};

type ConferenceRowState = {
	hidden: JSX.Element | undefined;
};

type Event = {
	file_name: string;
	subject: string;
	description: string;
	location: string;
	begin: string;
	stop: string;
};

/**
 * Generates a row for a conference. Designed to be embedded in ConferenceTable.
 *
 * @export
 * @class ConferenceRow
 * @extends {Component<ConferenceRowProps, ConferenceRowState>}
 */
export class ConferenceRow extends Component<ConferenceRowProps, ConferenceRowState> {
	hidden: any;
	constructor(props: any) {
		super(props);
		this.state = { hidden: undefined };
		this.hidden = undefined;
	}

	createICSForEvent(events: Event[]) {
		var cal = ics();
		for (let i = 0; i < events.length; i++) {
			let event: Event = events[i];
			cal?.addEvent(event.subject, event.description, event.location, event.begin, event.stop, undefined);
		}
		cal?.download(events[0].file_name, ".ics");
		return;
	}

	createEvent(date: string, kind: string) {
		var file_name = "";
		var name = "";
		var location = "";
		var description = "Based on the data of ConfSearch. Please check that the dates are correct.";
		var start = date;
		var end: string = "";
		if (this.props.row.name) {
			name = this.props.row.name + " (" + kind + ")";
			file_name = this.props.row.name;
		}
		if (this.props.row.acronym) {
			name = this.props.row.acronym + " - " + name;
		}
		if (this.props.row.location) {
			location = this.props.row.location;
		}
		if (start) {
			let end2: Date = new Date();
			end2.setTime(new Date(start).getTime() + 60 * 60 * 1000);
			end = end2.toString();
		}

		var ev: Event = {
			file_name: file_name,
			begin: start,
			description: description,
			location: location,
			stop: end,
			subject: name,
		};
		return ev;
	}

	renderCalendar() {
		var events: Event[] = [];
		//
		let deadline_q = this.props.row.deadline?.split(";");
		if (deadline_q) {
			for (let i = 0; i < deadline_q.length; i++) {
				let elem: string = deadline_q[i];
				if (checkValidityOfDate(elem)) {
					events.push(this.createEvent(elem!, "Deadline"));
				}
			}
		}

		if (checkValidityOfDate(this.props.row.end)) {
			events.push(this.createEvent(this.props.row.end!, "End"));
		}
		if (checkValidityOfDate(this.props.row.start)) {
			events.push(this.createEvent(this.props.row.start!, "Start"));
		}

		let notification_q = this.props.row.notification?.split(";");
		if (notification_q) {
			for (let i = 0; i < notification_q.length; i++) {
				let elem: string = notification_q[i];
				if (checkValidityOfDate(elem)) {
					events.push(this.createEvent(elem!, "Notification"));
				}
			}
		}

		if (events.length === 0) {
			return <div className="min-width-24 min-height-24"></div>;
		} else {
			return (
				<div className="min-width-24 min-height-24 d-inline">
					<Tooltip
						styleInner=""
						styleOuter=""
						content={
							<div>
								<h6>
									Click to download <em>.ics</em> file with the dates shown on the left.
								</h6>
								<p>Please consider updating the dates if they are out-of-date first.</p>
							</div>
						}
					>
						<CalendarIcon callback={() => this.createICSForEvent(events)}></CalendarIcon>
					</Tooltip>
				</div>
			);
		}
	}

	componentDidUpdate() {}

	render() {
		return (
			<tr className="text-left text-break-all" key={this.props.row.id_pk}>
				{/* Select Checkbox */}
				<th
					scope="row"
					className="max-width-3em position-relative  px-4 pt-3 "
					key={"selected" + this.props.row.selected}
				>
					<input
						className=""
						type={"checkbox"}
						defaultChecked={this.props.row.selected}
						onChange={(e) => this.props.setSelected(e.target.checked)}
					/>
				</th>

				{/* Calendar, Edit, View*/}
				<td className="px-4 min-width-150px">
					{/* Calendar */}
					<div className="row">
						<div className="col-4 p-1">
							<a href={"/edit/" + this.props.row.id_pk}>
								<Tooltip styleInner="" content={<h6>Click to Edit.</h6>}>
									<EditIcon></EditIcon>
								</Tooltip>
							</a>
						</div>
						<div className="col-4 p-1">
							<a href={"/details/" + this.props.row.id_pk}>
								<Tooltip styleInner="" content={<h6>Click for details.</h6>}>
									<DetailsIcon></DetailsIcon>
								</Tooltip>
							</a>
						</div>
						<div className="col-4 p-1">{this.renderCalendar()}</div>
					</div>
				</td>

				{/* Acronym */}
				<th scope="row" className="max-width-8em position-relative" key={"acronym" + this.props.row.id_pk}>
					<span className="text-break">{this.props.row.acronym}</span>
				</th>
				{/* Name */}
				<td
					className={"max-width-30em " + (this.props.row.deleted ? "grey-out" : "")}
					key={"name" + this.props.row.id_pk}
				>
					{this.props.row.deleted ? (
						<abbr title="This conference was marked discontinued.">{this.props.row.name}</abbr>
					) : (
						this.props.row.name
					)}
				</td>
				{/* Web */}
				<td className="align-top" key={"www" + this.props.row.id_pk}>
					{this.props.row.www !== "" ? (
						<a target="_blank" rel="noreferrer" href={this.props.row.www}>
							<WebIcon></WebIcon>
						</a>
					) : (
						<div></div>
					)}
				</td>
				{/* Rank */}
				<td className="max-width-4em" key={"rank" + this.props.row.id_pk}>
					{this.props.row.rank}
				</td>
				{/* Location */}
				<td className="max-width-18em" key={"location" + this.props.row.id_pk}>
					{this.props.row.location}
				</td>

				{/* Important Dates */}
				<td key={"dates-" + this.props.row.id_pk} className="p-0 position-relative">
					<DateCell
						setHoveredDate={this.props.setHoveredDate}
						hoveredDate={this.props.hoveredDate}
						row={this.props.row}
					></DateCell>
				</td>
				{/* hidden */}
				{this.state.hidden}
			</tr>
		);
	}
}

export default ConferenceRow;
