import axios, { AxiosRequestHeaders, AxiosResponse } from "axios";
import { CSRF_URL } from "../Config/settings";

export function forceNavigate(s: string) {
	window.location.assign(s);
}

export function updateURLParameter(url: string, param: string, paramVal: string) {
	// https://stackoverflow.com/questions/1090948/change-url-parameters-and-specify-defaults-using-javascript
	var TheAnchor = null;
	var newAdditionalURL = "";
	var tempArray = url.split("?");
	var baseURL = tempArray[0];
	var additionalURL = tempArray[1];
	var temp = "";

	if (additionalURL) {
		var tmpAnchor = additionalURL.split("#");
		var TheParams = tmpAnchor[0];
		TheAnchor = tmpAnchor[1];
		if (TheAnchor) additionalURL = TheParams;

		tempArray = additionalURL.split("&");

		for (var i = 0; i < tempArray.length; i++) {
			if (tempArray[i].split("=")[0] != param) {
				newAdditionalURL += temp + tempArray[i];
				temp = "&";
			}
		}
	} else {
		var tmpAnchor = baseURL.split("#");
		var TheParams = tmpAnchor[0];
		TheAnchor = tmpAnchor[1];

		if (TheParams) baseURL = TheParams;
	}

	if (TheAnchor) paramVal += "#" + TheAnchor;

	var rows_txt = temp + "" + param + "=" + paramVal;
	return baseURL + "?" + newAdditionalURL + rows_txt;
}

export function objectIsEmpty(obj: object) {
	return (
		obj && // 👈 null and undefined check
		Object.keys(obj).length === 0 &&
		Object.getPrototypeOf(obj) === Object.prototype
	);
}

export function arrayIsEmpty(arr: any[]) {
	return !(Array.isArray(arr) && arr.length);
}

export function isLeapYear(date: Date) {
	var year = date.getFullYear();
	if ((year & 3) !== 0) return false;
	return year % 100 !== 0 || year % 400 === 0;
}

export function isNumeric(str: string | number) {
	if (typeof str != "string") return false; // we only process strings!
	return (
		!isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str))
	); // ...and ensure strings of whitespace fail
}

// Get Day of Year
export function getDOY(date: Date) {
	var dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
	var mn = date.getMonth();
	var dn = date.getDate();
	var dayOfYear = dayCount[mn] + dn;
	if (mn > 1 && isLeapYear(date)) dayOfYear++;
	return dayOfYear;
}

export function getCookie(name: string) {
	let cookieValue = null;
	if (document.cookie && document.cookie !== "") {
		const cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			// Does this cookie string begin with the name we want?
			if (cookie.substring(0, name.length + 1) === name + "=") {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
	}
	return cookieValue;
}

// post request
/**
 *
 * @param url ! only use backend URLs.
 * @param data
 * @param headers
 */
export async function post_request_to_backend_with_csrf(
	url: string,
	data: { [key: string]: string },
	additional_headers: AxiosRequestHeaders | undefined = undefined
): Promise<AxiosResponse<any, any>> {
	// Could theoretically only update CSRF token,
	// whenever the token is invalid (403 error) or the cookie is not set
	await setCSRFCookie();
	var csrftoken = getCookie("csrftoken");

	var headers: AxiosRequestHeaders = {
		"X-Requested-With": "XMLHttpRequest",
		"X-CSRFToken": csrftoken!,
		"Access-Control-Allow-Origin": "*",
		"Content-Type": "application/json",
	};
	for (let key in additional_headers) {
		if (!(key in headers)) {
			headers[key] = additional_headers[key];
		}
	}
	return axios.post(url, data, {
		//
		withCredentials: true, // send cookies, authorization headers or TLS client certificates cross -origin.
		headers: headers, // contains CSRF cookie among others.
	});
}

function setCookie(cname: string, cvalue: string, exdays: number) {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	let expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

async function setCSRFCookie() {
	var csrftoken: any = undefined;
	await axios.get(CSRF_URL, { withCredentials: true }).then((response) => {
		var el = document.createElement("input");
		el.innerHTML = response.data;
		csrftoken = (el.querySelector("[name=csrfmiddlewaretoken]") as any).value;
		setCookie("csrftoken", csrftoken, 1);
	});
}

export function getRandomInt(max: number) {
	return Math.floor(Math.random() * max);
}

export function pick(list: any[]) {
	let size: number = list.length;
	return list[getRandomInt(size)];
}
