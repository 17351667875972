import { Component, ReactElement } from "react";
import { Navigate } from "react-router";
import { BACKEND_HOST } from "../../Config/settings";
import { post_request_to_backend_with_csrf } from "../../Utils/utils";
import CustomButton from "../Buttons/CustomButton";
import SubmitButton from "../Buttons/SubmitButton/SubmitButton";
import Conference from "../Conference";
import InformationBox from "../InformationBox/InformationBox";
import Tooltip from "../Tooltip/Tooltip";

type Props = {};

type State = {
	c: Conference;
	hidden: undefined | ReactElement;
	checkbox: boolean;
	titles: string;
	banner: undefined | ReactElement;
};

export default class Add extends Component<Props, State> {
	state = {
		c: new Conference(),
		hidden: undefined,
		checkbox: false,
		titles: "",
		banner: undefined,
	};

	submit(e: any) {
		e.preventDefault();

		if (!this.state.checkbox) {
			const banner = (
				<InformationBox>
					<h5>Check Validity</h5>
					<p>Please confirm the correctness of your edits using the checkbox at the bottom of the form.</p>
				</InformationBox>
			);
			window.scrollTo(0, 0);
			this.setState({ banner: banner });
			return;
		} else {
			var data: any = this.state.c.export_obj();
			data["titles"] = this.state.titles;
			let url = BACKEND_HOST + "/search-engine/add/";
			post_request_to_backend_with_csrf(url, data).then(
				(response) => {
					// console.log(response);

					const success_message: string = response.data["message"];
					const success: number = 1;
					const banner = (
						<Navigate to="/" state={{ banner_message: success_message, banner_type: success }}></Navigate>
					);
					this.setState({ banner: banner });
				},
				(err) => {
					const banner = (
						<InformationBox>
							<h5>Error</h5>
							<p>
								The server replied with the following error.
								<br /> {err.response.data["message"]}
							</p>
						</InformationBox>
					);
					this.setState({ banner: banner });
					window.scrollTo(0, 0);
				}
			);
		}
	}

	render() {
		const conf: Conference = this.state.c! as Conference;

		return (
			<div className="text-left p-3">
				{this.state.banner}
				<div className="h2">Add conference</div>
				<div>
					{/* FORM */}
					<form onSubmit={this.submit.bind(this)}>
						{/* Acronym & Conference Name */}
						<h5>Mandatory fields</h5>
						<div className="form-row">
							<div className="form-group col-md-4">
								<label htmlFor="acronym">Acronym</label>
								<input
									type="text"
									className="form-control form-input"
									id="acronym"
									// name="acronym"
									placeholder="PODC"
									onChange={(e) => {
										conf.set("acronym", e.target.value);
									}}
									required
								/>
							</div>
							<div className="form-group col-md-8">
								<label htmlFor="conference-name">Conference Name</label>
								<input
									type="text"
									className="form-control form-input"
									id="conference-name"
									// name="name"
									placeholder="Symposium on Principles of Distributed Computing"
									onChange={(e) => {
										conf.set("name", e.target.value);
									}}
									required
								/>
							</div>
						</div>
						{/* Keywords */}
						<label htmlFor="input" className="">
							Provide a list of paper titles for this conference (min. 500 words). This is required to
							compute the keywords for this conference.{" "}
							<a target="_blank" href="/compute-keywords">
								Click here for an Example.
							</a>
						</label>
						<textarea
							className="form-control form-input mb-3"
							rows={10}
							id="titles"
							// name="acronym"
							placeholder="A Framework for Distributed Quantum Queries in the CONGEST Model Early Adapting to Trends Self-Stabilizing Information Spread using Passive Optimal Synchronous Approximate Agreement with Asynchronous Fallback "
							onChange={(e) => {
								this.setState({ titles: e.target.value });
							}}
							required
						/>
						<div className="border-top"></div>
						<h5 className="mt-3">Optional fields</h5>
						<div className="form-row">
							<div className="form-group col-md-4">
								<label htmlFor="rank">
									<Tooltip
										styleOuter={"d-inline"}
										content={
											<div>
												<h6>Use the most recent CORE rank.</h6>
												<p>A*, A, B, C, etc. or leave empty.</p>
											</div>
										}
									>
										<abbr title="">Rank (CORE Ranking)</abbr>
									</Tooltip>
								</label>
								<input
									type="text"
									className="form-control form-input"
									id="rank"
									// name="rank"
									placeholder="A*"
									onChange={(e) => {
										conf.set("rank", e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="form-group col-md-6">
								<label htmlFor="conference-website">Conference Website</label>
								<input
									type="text"
									className="form-control form-input"
									id="conference-website"
									// name="conference"
									placeholder="https://www.podc.org/"
									onChange={(e) => {
										conf.set("www", e.target.value);
									}}
								/>
							</div>
							<div className="form-group col-md-6">
								<label htmlFor="location">Location</label>
								<input
									type="text"
									className="form-control form-input"
									id="location"
									// name="location"
									placeholder="Salerno, Italy"
									onChange={(e) => {
										conf.set("location", e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="form-group col-md-6">
								<label htmlFor="deadline">
									<Tooltip
										styleOuter={"d-inline"}
										content={
											<div>
												<h6>Respect the following format</h6>{" "}
												<p>NameOfMonth[0:3] DayOfEvent, Year, e.g. Feb 13, 2022</p>
											</div>
										}
									>
										<abbr title="">Deadline</abbr>
									</Tooltip>
								</label>
								<input
									type="text"
									className="form-control form-input"
									id="deadline"
									// name="deadline"
									placeholder={"Feb 13, 2022"}
									onChange={(e) => {
										conf.set("deadline", e.target.value);
									}}
								/>
							</div>
							<div className="form-group col-md-6">
								<label htmlFor="notification">Notification</label>
								<input
									type="text"
									className="form-control form-input"
									id="notification"
									// name="notification"
									placeholder={"May 3, 2022"}
									onChange={(e) => {
										conf.set("notification", e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="form-group col-md-6">
								<label htmlFor="start-conference">Start of Conference</label>
								<input
									type="text"
									className="form-control form-input"
									id="start-conference"
									// name="start"
									placeholder={"Jul 25, 2022"}
									onChange={(e) => {
										conf.set("start", e.target.value);
									}}
								/>
							</div>
							<div className="form-group col-md-6">
								<label htmlFor="end">End of Conference</label>
								<input
									type="text"
									className="form-control form-input"
									id="end"
									// name="end"
									placeholder={"Jul 29, 2022"}
									onChange={(e) => {
										conf.set("end", e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="form-group">
							<div className="row">
								<div className="form-check col-md-6">
									<div className="ml-3">
										<input
											className="form-check-input"
											type="checkbox"
											id="gridCheck"
											// name="checkBox"
											onChange={(e) => {
												this.setState({ checkbox: e.target.checked });
											}}
											defaultChecked={this.state.checkbox}
										/>
										<label className="form-check-label" htmlFor="gridCheck">
											Confirm the validity of your changes.
										</label>
									</div>
								</div>
							</div>
						</div>
						<SubmitButton value="Submit"></SubmitButton>
					</form>
					<CustomButton value="Home" flavor="semi-dark-composite" navigate="/"></CustomButton>
					<div className="hidden">{this.state.hidden}</div>
				</div>
			</div>
		);
	}
}
