import ComputeKeywordsForm from "../Components/ComputeKeywords/ComputeKeywordsForm";
import Wrapper from "./Wrapper";

type Props = {};

export default function ComputeKeywords(props: Props) {
	return (
		<Wrapper>
			<ComputeKeywordsForm></ComputeKeywordsForm>
		</Wrapper>
	);
}
