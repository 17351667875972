import { Component } from "react";
import "./DetailsIcon.scss";
import "../../Buttons/Button.scss";

type Props = {
	// callback: (() => void) | undefined;
};

type State = {};

export default class DetailsIcon extends Component<Props, State> {
	state = {};

	render() {
		return (
			<div className="">
				<div className="details-icon clickable"></div>
				{this.props.children}
			</div>
		);
	}
}
