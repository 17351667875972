import Add from "../Components/Add/Add";
import Wrapper from "./Wrapper";

type Props = {};

export default function AddPage(props: Props) {
	return (
		<Wrapper>
			<Add></Add>
		</Wrapper>
	);
}
