import { Component } from "react";
import "./Icons.scss";

type Props = {
	outOfDate: boolean;
};

type State = {};

export default class StartConferenceIcon extends Component<Props, State> {
	state = {};

	render() {
		return (
			<div className={"start-conference-icon" + (this.props.outOfDate ? "-out-of-date" : "")}>
				{this.props.children}
			</div>
		);
	}
}
