import axios from "axios";
import { BACKEND_HOST } from "../Config/settings";

/**
 *
 * @param id_pk : primary key of the data object you want to obtain details on.
 * @returns a promise for the data containing the information of
 */
export async function retrieveInformation(id_pk: string): Promise<object | undefined> {
	var request_url = BACKEND_HOST + "/search-engine/details/?id_pk=" + id_pk;
	var responseDataPromise: Promise<object | undefined> = axios
		.get(request_url, {
			headers: { "Content-Type": "application/json" },
			timeout: 2500,
		})
		.then(
			(response: any) => {
				// Success
				var responseDataPromise: object = response.data;
				return responseDataPromise;
			},
			(error: any) => {
				// Failure
				console.log(error);
				return undefined;
			}
		);
	return responseDataPromise;
}
