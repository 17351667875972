import { Component } from "react";
import "./CalendarIcon.scss";
import "../../Buttons/Button.scss";

type Props = { callback: (() => void) | undefined };

type State = {};

export default class CalendarIcon extends Component<Props, State> {
	state = {};

	render() {
		return (
			<div className="">
				<div className="calendar-icon clickable" onClick={this.props.callback}></div>
				{this.props.children}
			</div>
		);
	}
}
