import { Component, ReactElement } from "react";
import { BACKEND_HOST } from "../../Config/settings";
import { post_request_to_backend_with_csrf } from "../../Utils/utils";
import CustomButton from "../Buttons/CustomButton";
import SubmitButton from "../Buttons/SubmitButton/SubmitButton";
import { example } from "./TitleExample";

type Props = {};

type State = {
	output: undefined | ReactElement;
	example: string;
};

export default class ComputeKeywordsForm extends Component<Props, State> {
	state = { output: undefined, example: "" };
	text: string;

	constructor(props: Props) {
		super(props);
		this.text = "";
	}

	submit(e: any) {
		e.preventDefault();
		let url: string = BACKEND_HOST + "/search-engine/compute-keywords/";
		let data: any = { titles: this.text, update: true };
		post_request_to_backend_with_csrf(url, data, {}).then(
			(response) => {
				let x = (
					<div>
						<label>Result:</label>
						<textarea
							readOnly
							className="form-control form-input"
							rows={10}
							value={response.data["keywords"]}
						></textarea>
					</div>
				);
				this.setState({ output: x });
			},
			(error) => {
				console.log("Error");
			}
		);
	}

	generateExample() {
		document.getElementById("input")?.setAttribute("value", example);
		this.text = example;
		this.setState({ example: example });
	}

	render() {
		return (
			<div>
				<div key={"Form"} className="p-3 col-md-12 text-left">
					<div className="h2 ">Compute Keywords</div>
					<form action="#" onSubmit={(e) => this.submit.bind(this)(e)}>
						<div className="">
							<label htmlFor="input" className="">
								Enter a list of all the paper titles that have been presented at the conference you want
								to calculate keywords for. The more titles the better! <br /> Click on{" "}
								<em>generate example </em>
								to obtain a list of titles for the <em>Principles of Distributed Computing</em>{" "}
								conference. Then click on compute. This might help you understand how keywords are
								generated when adding a conference.
							</label>
							<textarea
								className="form-control form-input"
								rows={10}
								id="input"
								// name="acronym"
								placeholder="A Framework for Distributed Quantum Queries in the CONGEST Model Early Adapting to Trends Self-Stabilizing Information Spread using Passive Optimal Synchronous Approximate Agreement with Asynchronous Fallback "
								onChange={(e) => {
									this.text = e.target.value;
								}}
								defaultValue={this.state.example}
							/>
						</div>
						<SubmitButton value="Compute"></SubmitButton>
					</form>
					<CustomButton value="Generate Example" onClick={this.generateExample.bind(this)}></CustomButton>
					{this.state.output}
				</div>
			</div>
		);
	}
}
