import { Component } from "react";
import "./Icons.scss";

type Props = {};

type State = {};

export default class WebIcon extends Component<Props, State> {
	state = {};

	render() {
		return <div className="web-icon">{this.props.children}</div>;
	}
}
