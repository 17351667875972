import React, { Component } from "react";
import "./Tooltip.scss";

type Props = {
	content?: React.ReactElement | undefined;
	styleOuter?: string | undefined;
	styleInner?: string | undefined;
};

type State = {};

export default class Tooltip extends Component<Props, State> {
	state = {};

	render() {
		return (
			<div className={"tooltip-box " + this.props.styleOuter}>
				{this.props.children}
				<div className={"tooltiptext-hidden shadow " + this.props.styleInner}>{this.props.content}</div>
			</div>
		);
	}
}
