import { Component } from "react";
import "./EditIcon.scss";
import "../../Buttons/Button.scss";

type Props = {
	// callback: (() => void) | undefined;
};

type State = {};

export default class EditIcon extends Component<Props, State> {
	state = {};

	render() {
		return (
			<div className="">
				<div
					className="edit-icon clickable"
					// onClick={this.props.callback}
				></div>
				{this.props.children}
			</div>
		);
	}
}
