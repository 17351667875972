import { isEmptyObject } from "jquery";
import React, { ReactElement, useEffect, useState } from "react";
import { Params, useNavigate, useParams } from "react-router-dom";
import { BACKEND_HOST } from "../../Config/settings";
import { isNumeric, post_request_to_backend_with_csrf } from "../../Utils/utils";
import { retrieveInformation } from "../../Utils/utils_details";
import CustomButton from "../Buttons/CustomButton";
import SubmitButton from "../Buttons/SubmitButton/SubmitButton";
import Conference from "../Conference";
import InformationBox from "../InformationBox/InformationBox";
import Tooltip from "../Tooltip/Tooltip";
import "./EditForm.scss";

type Props = {};

type FormProps = {
	formData: Conference;
};

function Form(props: FormProps) {
	const [banner, setBanner]: [JSX.Element, React.Dispatch<React.SetStateAction<JSX.Element>>] = useState(<div></div>);
	//
	const [checkbox, setCheckbox]: any = useState(false);
	var init_c = new Conference();
	for (const key in props.formData) {
		init_c.set(key, props.formData.get(key as keyof Conference));
	}
	const [c, setC]: [Conference, React.Dispatch<React.SetStateAction<Conference>>] = useState(init_c);
	const navigate = useNavigate();

	$(function () {
		($('[data-toggle="tooltip"]') as any).tooltip();
	});
	let resolve = (key: string) => {
		return props.formData && props.formData.get(key) ? (props.formData.get(key) as string) : "";
	};

	var submit = (e: any) => {
		e.preventDefault(); // prevent action!
		window.scrollTo(0, 0);
		if (checkbox) {
			if (banner !== <div></div>) {
				setBanner(<div></div>);
			}
			let url: string = BACKEND_HOST + "/search-engine/edit/";
			let data: any = c;
			post_request_to_backend_with_csrf(url, data, {}).then(
				(response) => {
					const success_message: string = "You successfully filed a request for change.";
					const success: number = 1;

					navigate("/", {
						state: {
							banner_message: success_message,
							banner_type: success,
						},
					});
				},
				(error) => {
					setBanner(
						<InformationBox alertType="danger">
							<h5>Error</h5>
							<p>
								An error at the Backend server occurred, while handling your request:{" "}
								{error.response.data["message"]}
							</p>
						</InformationBox>
					);
					console.log(error);
				}
			);
		} else {
			setBanner(
				<InformationBox alertType="warning">
					<h5>Check Validity</h5>
					<p>Please confirm the correctness of your edits using the checkbox at the bottom of the form.</p>
				</InformationBox>
			);
		}
	};

	var deleteConferenceHandler1 = (e: any) => {
		e.preventDefault(); // prevent action!
		const msg1 =
			"Please only flag as discontinued if the conference has been permanently discontinued. If the conference is a duplicate and should be deleted, please contact us.";
		const msg2 = "Please only restore the conference if it has not been discontinued.";
		if (window.confirm(c.deleted ? msg2 : msg1)) {
			// Send request to delete.
			let url: string = BACKEND_HOST + "/search-engine/delete/";
			let data: any = {};

			data["id_pk"] = c.id_pk;
			data["deleted"] = !c.deleted;

			console.log(data);

			post_request_to_backend_with_csrf(url, data, {}).then(
				(response) => {
					const success_message: string = response.data["message"];
					const success: number = 1;

					navigate("/", {
						state: {
							banner_message: success_message,
							banner_type: success,
						},
					});
				},
				(error) => {
					setBanner(
						<InformationBox alertType="danger">
							<h5>Error</h5>
							<p>
								An error at the Backend server occurred, while handling your request:{" "}
								{error.response.data["message"]}
							</p>
						</InformationBox>
					);
				}
			);
		} else {
			return;
		}
	};

	var renderProtectionAndDeleted = () => {
		if (!(c.get("deleted") || c.get("protected"))) {
			return;
		}
		const el: ReactElement = (
			<div className="">
				{(c.get("deleted") as boolean) ? (
					<div className="p">This conference was flagged as discontinued.</div>
				) : (
					<div></div>
				)}
				{(c.get("protected") as boolean) ? (
					<div className="p">
						This conference is protected. You cannot change the acronym nor the conference name.
					</div>
				) : (
					<div></div>
				)}
			</div>
		);
		return <InformationBox alertType="info">{el}</InformationBox>;
	};

	return (
		<div key={"Form"}>
			{banner}
			{renderProtectionAndDeleted()}
			<div className="h2">Edit</div>
			<form action="#" onSubmit={submit}>
				<div className="form-row">
					<div className="form-group col-md-4">
						<label htmlFor="acronym">Acronym</label>
						<input
							type="text"
							className="form-control form-input"
							id="acronym"
							// name="acronym"
							placeholder="PODC"
							defaultValue={resolve("acronym")}
							onChange={(e) => {
								c.set("acronym", e.target.value);
							}}
							readOnly={c.get("protected") as boolean}
						/>
					</div>
					<div className="form-group col-md-8">
						<label htmlFor="conference-name">Conference Name</label>
						<input
							type="text"
							className="form-control form-input"
							id="conference-name"
							// name="name"
							placeholder="Symposium on Principles of Distributed Computing"
							defaultValue={resolve("name")}
							onChange={(e) => {
								c.set("name", e.target.value);
							}}
							readOnly={c.get("protected") as boolean}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-md-4">
						<label htmlFor="rank">
							<Tooltip
								styleOuter={"d-inline"}
								content={
									<div>
										<h6>Use the most recent CORE rank.</h6>
										<p>A*, A, B, C, etc. or leave empty.</p>
									</div>
								}
							>
								<abbr title="">Rank (CORE Ranking)</abbr>
							</Tooltip>
						</label>
						<input
							type="text"
							className="form-control form-input"
							id="rank"
							// name="rank"
							placeholder="A*"
							defaultValue={resolve("rank")}
							onChange={(e) => {
								c.set("rank", e.target.value);
							}}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-md-6">
						<label htmlFor="conference-website">Conference Website</label>
						<input
							type="text"
							className="form-control form-input"
							id="conference-website"
							// name="conference"
							placeholder="https://www.podc.org/"
							defaultValue={resolve("www")}
							onChange={(e) => {
								c.set("www", e.target.value);
							}}
						/>
					</div>
					<div className="form-group col-md-6">
						<label htmlFor="location">Location</label>
						<input
							type="text"
							className="form-control form-input"
							id="location"
							// name="location"
							placeholder="Salerno, Italy"
							defaultValue={resolve("location")}
							onChange={(e) => {
								c.set("location", e.target.value);
							}}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-md-6">
						<label htmlFor="deadline">
							<Tooltip
								styleOuter={"d-inline"}
								content={
									<div>
										<h6>Respect the following format</h6>{" "}
										<p>NameOfMonth[0:3] DayOfEvent, Year, e.g. Feb 13, 2022</p>
										<p>
											You can also add multiple deadline and notifications. Use the conventional
											format but use ';' to separate the dates.
										</p>
									</div>
								}
							>
								<abbr title="">Deadline</abbr>
							</Tooltip>
						</label>
						<input
							type="text"
							className="form-control form-input"
							id="deadline"
							// name="deadline"
							placeholder={"Feb 13, 2022"}
							defaultValue={resolve("deadline")}
							onChange={(e) => {
								c.set("deadline", e.target.value);
							}}
						/>
					</div>
					<div className="form-group col-md-6">
						<label htmlFor="notification">Notification</label>
						<input
							type="text"
							className="form-control form-input"
							id="notification"
							// name="notification"
							placeholder={"May 3, 2022"}
							defaultValue={resolve("notification")}
							onChange={(e) => {
								c.set("notification", e.target.value);
							}}
						/>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-md-6">
						<label htmlFor="start-conference">Start of Conference</label>
						<input
							type="text"
							className="form-control form-input"
							id="start-conference"
							defaultValue={resolve("start")}
							// name="start"
							placeholder={"Jul 25, 2022"}
							onChange={(e) => {
								c.set("start", e.target.value);
							}}
						/>
					</div>
					<div className="form-group col-md-6">
						<label htmlFor="end">End of Conference</label>
						<input
							type="text"
							className="form-control form-input"
							id="end"
							// name="end"
							placeholder={"Jul 29, 2022"}
							defaultValue={resolve("end")}
							onChange={(e) => {
								c.set("end", e.target.value);
							}}
						/>
					</div>
				</div>
				<div className="form-group">
					<div className="row">
						<div className="form-check col-md-6">
							<div className="ml-3">
								<input
									className="form-check-input"
									type="checkbox"
									id="gridCheck"
									// name="checkBox"
									onChange={(e) => {
										setCheckbox(e.target.checked);
									}}
								/>
								<label className="form-check-label" htmlFor="gridCheck">
									Confirm the validity of your changes.
								</label>
							</div>
						</div>
						<div className="col-md-6">
							<CustomButton
								value={
									props.formData.deleted
										? "Remove discontinued flag"
										: "Flag conference as discontinued"
								}
								flavor="info btn-sm"
								btnBlock="d-inline"
								onClick={(e) => deleteConferenceHandler1(e)}
							></CustomButton>
						</div>
					</div>
				</div>
				{/* Delete Button */}

				<SubmitButton value="Submit"></SubmitButton>
			</form>
			<CustomButton value="Home" flavor="semi-dark-composite" onClick={(e) => navigate("/")}></CustomButton>
		</div>
	);
}

function EditForm(props: Props) {
	const [data, setData] = React.useState<undefined | Conference>(undefined);

	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		if (params && params["id_pk"] && (!data || parseInt(params["id_pk"]) !== data.get("id_pk"))) {
			retrieveInformation(params["id_pk"]!).then(
				(response) => {
					const conference = new Conference();
					conference.initFromObject(response!);
					setData(isEmptyObject(response) ? undefined : conference);
				},
				(error) => {
					console.log(error);
					setData(undefined);
				}
			);
		}
	});

	// Retrieve id string from URL.
	const params: Params<string> = useParams();
	if (!params["id_pk"] || !isNumeric(params["id_pk"]) || isEmptyObject(data)) {
		return (
			<div className="Details  text-left p-3">
				<div className="h2">Edit</div>
				<InformationBox>Have you specified a valid id? If yes, please try again later.</InformationBox>
			</div>
		);
	} else {
		return (
			<div className="text-left p-3">
				<Form formData={data!}></Form>
			</div>
		);
	}
}

export default EditForm;
