import InformationBox from "../Components/InformationBox/InformationBox";
import Wrapper from "./Wrapper";

type Props = {};

export default function Page404(props: Props) {
	return (
		<Wrapper>
			<InformationBox>Error 404: The requested page doesn't exist.</InformationBox>
		</Wrapper>
	);
}
