import "./Search.scss";

import { Component } from "react";
import SubmitButton from "../Buttons/SubmitButton/SubmitButton";
import { JsxElement } from "typescript";
import axios from "axios";
import { BACKEND_HOST } from "../../Config/settings";
import { Row } from "../ConferenceTable/ConferenceRow/Types";
import ConferenceTable from "../ConferenceTable/ConferenceTable";
import { arrayIsEmpty, isNumeric, objectIsEmpty } from "../../Utils/utils";
import CustomButton from "../Buttons/CustomButton";
import { Navigate } from "react-router";
import TipOfTheDay from "../TipOfTheDay/TipOfTheDay";
import InformationBox from "../InformationBox/InformationBox";
import {
	pick_author_suggestion,
	pick_conference_suggestion,
	pick_keyword_suggestion,
	pick_list_suggestion,
} from "./suggestions";

type Props = {};

type State = {
	tableData: any; // Object | undefined;
	query: string | undefined;
	suggestions: any | undefined;
	hidden: any;
	infobox: any;
	sortby: any;
};

class Search extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			tableData: {},
			query: undefined,
			suggestions: [],
			hidden: undefined,
			infobox: undefined,
			sortby: undefined,
		};
	}

	hasQuery() {
		return this.state.query !== undefined && this.state.query! !== "";
	}

	componentDidMount() {
		// called 2x in development due to <React.StrictMode>
		const queryParams = new URLSearchParams(window.location.search);

		// handle query state
		let query = queryParams.get("query")!;
		let sortby = queryParams.get("sortby")!;

		if (query === null && this.state.query) {
			// Reset query if user searched for empty query.
			this.setState({
				tableData: {},
				hidden: undefined,
				suggestions: [],
				query: undefined,
				infobox: undefined,
				sortby: sortby,
			});
		} else if (query != null && query !== this.state.query) {
			// If the query changed with respect to the previous one, re-render results.
			this.setState({ query: query });
			this.searchQuery(query).then(
				(response) => {
					if (response !== undefined) {
						this.setState({ tableData: response, hidden: undefined, infobox: undefined, sortby: sortby });
						if ((response as any)["type"] === 1) {
							this.setState({
								suggestions: (
									<div className="spinner-border text-info" role="status">
										<span className="sr-only">Loading...</span>
									</div>
								),
							});
							this.fetchSuggestions(query);
						}
					}
				},
				(error) => {
					this.setState({ tableData: undefined, hidden: undefined, infobox: undefined, sortby: sortby });
				}
			);
		} else if (this.state.hidden !== undefined) {
			this.setState({ hidden: undefined });
		}
	}

	fetchSuggestions(query: string) {
		this.suggestionQuery(query).then(
			(response) => {
				const r: any = response as any;
				var elements = [];
				if (!arrayIsEmpty(r["similar_authors"])) {
					var author = [<h6 key="authors">Suggested authors</h6>];
					for (const element in r["similar_authors"]) {
						const el = r["similar_authors"][element]["name"];
						const x = (
							<div key={"author-div" + element}>
								<a key={"authors-" + element} href={"/?query=" + el.replaceAll(" ", "+")}>
									{el}
								</a>
							</div>
						);
						author.push(x);
					}

					if (
						r["similar_authors"] &&
						r["similar_authors"][0]["weight"] > 1.1 &&
						(!r["similar_authors"][1] ||
							r["similar_authors"][1]["weight"] < r["similar_authors"][0]["weight"] - 0.12)
					) {
						this.searchQuery(r["similar_authors"][0]["name"]).then(
							(response) => {
								if (response !== undefined) {
									const infobox = (
										<InformationBox alertType="success">
											Showing results for : {r["similar_authors"][0]["name"]}
										</InformationBox>
									);
									this.setState({
										tableData: response,
										hidden: undefined,
										infobox: infobox,
									});
								}
							},
							(error) => {
								this.setState({ tableData: undefined, hidden: undefined });
							}
						);
					}
					elements.push(author);
				}
				if (!arrayIsEmpty(r["similar_conferences"])) {
					var conference = [<h6 key="conferences">Suggested conferences</h6>];
					for (const element in r["similar_conferences"]) {
						const el = r["similar_conferences"][element];
						const x = (
							<div key={"conference-div" + element} className="">
								<a key={"conferences-" + element} href={"/?query=" + el.replaceAll(" ", "+")}>
									{el}
								</a>
							</div>
						);
						conference.push(x);
					}
					elements.push(conference);
				}
				// update if dissimilar length.
				if (this.state.suggestions.length !== elements.length) {
					this.setState({ suggestions: elements });
				}
			},
			(error) => {
				this.setState({ tableData: undefined, hidden: undefined });
			}
		);
	}

	componentDidUpdate() {
		// console.log("ComponentDidMount!"); // called 2x in development due to <React.StrictMode>
		this.componentDidMount();
	}

	async searchQuery(query: string): Promise<object | undefined> {
		query = query.replace(" ", "+");
		var request_url = BACKEND_HOST + "/search-engine/?query=" + query;
		//
		var responseDataPromise: Promise<object | undefined> = axios
			.get(request_url, {
				headers: { "Content-Type": "application/json" },
				timeout: 2500,
			})
			.then(
				(response) => {
					// Success
					var responseDataPromise: object = response.data;
					return responseDataPromise;
				},
				(error) => {
					// Failure
					return error(undefined);
				}
			);
		return responseDataPromise;
	}

	async suggestionQuery(query: string): Promise<object | undefined> {
		query = query.replace(" ", "+");
		var request_url = BACKEND_HOST + "/search-engine/suggestions/?query=" + query;
		var responseDataPromise: Promise<object | undefined> = axios
			.get(request_url, {
				headers: { "Content-Type": "application/json" },
				timeout: 25000,
			})
			.then(
				(response) => {
					// Success
					var responseDataPromise: object = response.data;
					return responseDataPromise;
				},
				(error) => {
					// Failure
					return error(undefined);
				}
			);
		return responseDataPromise;
	}

	renderTable(): JsxElement | any {
		if (objectIsEmpty(this.state.tableData)) {
			return;
		} else if (this.state.tableData === undefined) {
			return <p>There is maintenance work taking place right now. Please try again later.</p>;
		} else {
			// tableData contains an Object.
			// var rowKey : string
			var rows: Row[] = [];
			for (var rowKey in this.state.tableData) {
				if (!isNumeric(rowKey)) {
					continue;
				}
				var r: Row = {};
				r["id_pk"] = this.state.tableData[rowKey]["id_pk"];
				r["name"] = this.state.tableData[rowKey]["name"];
				r["acronym"] = this.state.tableData[rowKey]["acronym"];
				r["location"] = this.state.tableData[rowKey]["location"];
				r["deadline"] = this.state.tableData[rowKey]["deadline"];
				r["notification"] = this.state.tableData[rowKey]["notification"];
				r["start"] = this.state.tableData[rowKey]["start"];
				r["end"] = this.state.tableData[rowKey]["end"];
				r["rank"] = this.state.tableData[rowKey]["rank"];
				r["user_rating"] = this.state.tableData[rowKey]["user_rating"];
				r["www"] = this.state.tableData[rowKey]["www"];
				r["query_weight"] = this.state.tableData[rowKey]["query_weight"];
				r["selected"] = false;
				r["id_acro"] = this.state.tableData[rowKey]["id_acro"];
				r["deleted"] = this.state.tableData[rowKey]["deleted"];
				rows.push(r);
			}
		}
		return (
			<div className="w-100">
				<ConferenceTable sortby={this.state.sortby} rows={rows}></ConferenceTable>
			</div>
		);
	}

	renderSuggestions() {
		let keyword = pick_keyword_suggestion();
		let author = pick_author_suggestion();
		let conference = pick_conference_suggestion();
		let list = pick_list_suggestion();

		return (
			<div key="suggestions">
				<div className="offset-md-2 text-left p-2 row">
					<div className="col-lg-5">
						<h3 className="p-2">Search Suggestions</h3>
						{/* <Tooltip content={<h6>Keyword Search</h6>} styleOuter="relative-inline" styleInner="left-12"> */}
						<CustomButton
							value={keyword[1]}
							flavor="semi-dark"
							btnBlock=""
							onClick={(e) => this.setState({ hidden: <Navigate to={keyword[0]} /> })}
						></CustomButton>
						{/* </Tooltip> */}
						{/* <Tooltip content={<h6>Author Search</h6>} styleOuter="relative-inline" styleInner="left-12"> */}
						<CustomButton
							value={author[1]}
							flavor="semi-dark"
							btnBlock=""
							onClick={(e) =>
								this.setState({
									hidden: <Navigate to={author[0]} />,
								})
							}
						></CustomButton>
						{/* </Tooltip> */}
						{/* <Tooltip
							content={<h6>Conference Search</h6>}
							styleOuter="relative-inline"
							styleInner="left-25pct"
						> */}
						<CustomButton
							value={conference[1]}
							flavor="semi-dark"
							btnBlock=""
							onClick={(e) =>
								this.setState({
									hidden: <Navigate to={conference[0]} />,
								})
							}
						></CustomButton>
						{/* </Tooltip> */}
						{/* <Tooltip content={<h6>List Search</h6>} styleOuter="relative-inline" styleInner="left-12"> */}
						<CustomButton
							value={list[1]}
							flavor="semi-dark"
							btnBlock=""
							onClick={(e) =>
								this.setState({
									hidden: <Navigate to={list[0]} />,
								})
							}
						></CustomButton>
						{/* </Tooltip> */}
					</div>
					<div className="col-lg-5">
						<h3 className="p-2">Features</h3>
						<CustomButton
							value="Add conference"
							flavor="light-blue-composite"
							btnBlock=""
							onClick={(e) =>
								this.setState({
									hidden: <Navigate to={"/add"} />,
								})
							}
						></CustomButton>
					</div>
				</div>
				<TipOfTheDay></TipOfTheDay>
			</div>
		);
	}

	render() {
		return (
			<div>
				<div className="">
					<div className="col-md-8 offset-md-2">
						<form className="" action="/">
							<div className="form-group">
								<br />
								<input
									className="form-control"
									type="text"
									id="query"
									name="query"
									placeholder={
										this.hasQuery()
											? this.state.query
											: "Search for keywords, authors (dblp name), related conferences or create a list of conferences."
									}
									defaultValue={this.hasQuery() ? this.state.query : ""}
								/>{" "}
								<br />
								<SubmitButton value="Search"></SubmitButton>
							</div>
						</form>
					</div>
					{this.state.infobox}
					<div className="suggested-similar-searches">{this.state.suggestions}</div>
				</div>
				{/* render suggestions for author / conference */}
				{/* Render search examples / suggestions if no query entered so far. */}
				{!this.hasQuery() ? this.renderSuggestions() : this.renderTable()}
				{this.state.hidden}
			</div>
		);
	}
}

export default Search;
