import './SubmitButton.scss'
import React, { Component } from 'react'

type Props = {
  value : string;
}

type State = {}

export default class SubmitButton extends Component<Props, State> {
  state = {}

  render() {
    return (
      <input className="shadow btn search-button form-control" type="submit" value={this.props.value}/>
    )
  }
}