import { pick } from "../../Utils/utils";

export const suggestion_sets_list: string[][] = [
	["?query=SOSP+RTSS+SIGMETRICS+ASPLOS+OSDI+ICSE", "SOSP RTSS SIGMETRICS ASPLOS OSDI ICSE"],
	["?query=RTSS+PODC+ICDE+AAMAS+VLDB", "RTSS PODC ICDE AAMAS VLDB"],
	["?query=LICS+STOC+FOCS+STACS", "LICS STOC FOCS STACS"],
	["?query=IPDPS+SC+ICPP+CCGRID+HPDC", "IPDPS SC ICPP CCGRID HPDC"],
	["?query=ACL+ICML+AAAI+KDD+ICLR+NeurIPS", "ACL ICML AAAI KDD ICLR NeurIPS"],
];

export const suggestion_similar_conference: string[][] = [
	["?query=IPDPS", "International Conference on Parallel Processing"],
	["?query=RTSS", "IEEE Real-Time Systems Symposium"],
	["?query=STOC", "Symposium on the Theory of Computing"],
	["?query=ICML", "International Conference on Machine Learning"],
	["?query=ICCV", "IEEE International Conference on Computer Vision"],
];

export const keywords_suggestions: string[][] = [
	["?query=GANs", "GANs"],
	["?query=cryptocurrency+scalability", "smart contracts"],
	["?query=Byzantine+Node", "Byzantine Node"],
	["?query=visual+descriptors", "Visual Descriptors"],
	["?query=Boltzmann+Machine", "Boltzmann Machine"],
	["?query=decentralized+finance", "Decentralized Finance"],
	["?query=ridge+regression", "Ridge regression"],
	["?query=high-performance+computing+regression", "high-performance computing"],
];

export const authors_suggestions: string[][] = [
	["?query=roger+wattenhofer", "Roger Wattenhofer"],
	["?query=timothy+roscoe", "Timothy Roscoe"],
	["?query=onur+mutlu", "Onur Mutlu"],
	["?query=Torsten+Hoefler", "Torsten Hoefler"],
	["?query=Adi+Shamir", "Adi Shamir"],
	["?query=Volker+Strassen", "Volker Strassen"],
	["?query=Donald+E.+Knuth", "Donald E. Knuth"],
	["?query=Lex+Fridman", "Lex Fridman"],
	["?query=Tim+Berners-Lee", "Tim Berners-Lee"],
	["?query=Leslie+Lamport", "Leslie Lamport"],
];

export function pick_list_suggestion() {
	return pick(suggestion_sets_list);
}

export function pick_author_suggestion() {
	return pick(authors_suggestions);
}

export function pick_keyword_suggestion() {
	return pick(keywords_suggestions);
}

export function pick_conference_suggestion() {
	return pick(suggestion_similar_conference);
}
